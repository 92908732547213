import * as Style from './style';
import headerLogoWhite from './headerWhiteLogo.svg';
import headerLogoBlack from './headerLogoBlack.svg';
import { Solid } from '../../assets/newIcons';
import { useNavigate } from 'react-router-dom'
import Button from '../Forms/Button';

export default ({ white }) => {
  const navigate = useNavigate()
  return (
    <Style.Header white={white}>
      <Style.Container white={white}>
        <div className="ico-container">
          <img 
            src={Solid('leaf')}
            alt="leaf"
            className="leaf mobile"
          />
          <img
            src={white ? headerLogoBlack : headerLogoWhite}
            className="header-logo"
            alt="header-logo"
          />
        </div>
        <Button
        header
          label="Abrir conta"
          style={{
            width: 110,
            height: 24,
            fontWeight: '400',
            fontSize: 12,
            marginBottom: 0,
            boxSizing: 'border-box',
          }}
          onClick={() => { navigate('/register') }}
        />
      </Style.Container>
    </Style.Header>
  );
};
