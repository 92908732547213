import { useState } from 'react';
import banner from '../../assets/banner.png';
import { useNavigate } from 'react-router-dom';
import utils from '../../helper/utils';
import Desktop from './desktop';
import Mobile from './mobile';
const { responsive } = utils;

export default function Header({ fixed, onClickDesktop, data, backgroundColor }) {
  const navigate = useNavigate();
  const [scroll, setScroll] = useState(0);

  window.addEventListener('scroll', () => setScroll(window.scrollY));

  return responsive.choose(
    <Mobile data={data} scroll={scroll} fixed={fixed} backgroundColor={backgroundColor}/>
  )(<Desktop data={data} scroll={scroll} fixed={fixed} onClickDesktop={onClickDesktop}/>);
}
