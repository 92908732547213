import { useRef } from 'react'

import { Select, SelectContainer } from './style'

export default function SelectComponent(props) {
  const { placeholder, disabled, defaultValue, onChange = () => {} } = props
  const selectRef = useRef(null)

  return (
    <SelectContainer>
      <Select
        placeholder={placeholder || 'Selecione...'}
        defaultValue={defaultValue}
        ref={selectRef}
        classNamePrefix="Select"
        isDisabled={disabled}
        onChange={onChange}
        {...props}
      />
    </SelectContainer>
  )
}
