import React from "react";
import * as Style from './style';
import { Table } from "../../../components";
import treeForMobile from "../../../assets/treeForMobile.svg"
import treesForMobile from "../../../assets/treesForMobile.svg"
import utils from "../../../helper/utils"
import { PALLETE } from "../../../configs/configs";
import { Outlined } from "../../../assets/newIcons";

const Section = () => {
  return (
    <>
      <Style.WhiteCover color={PALLETE.base}>
        <div className="inline" style={{ color: PALLETE.white }}>
					<Style.SixthContent style={{ paddingTop: 48, paddingBottom: 48 }}>
						<p className="title-six">Para o Planeta, um plano,<br/>para seu Capital, um Propósito.</p>
						<Table style={{ color: PALLETE.white, width: window.innerWidth > 450 ? '100%' : 275}}>
							<div className="col align-center margin-big">
								<img
									src={Outlined('co2Cloud')}
									alt="co2"
									className="ico"
									style={{ height: window.innerWidth > 450 ? 40 : 20, marginBottom: 22 }}
								/>

								<strong>
									+500<span className="small">kg</span>
								</strong>
								{utils.responsive.choose(<hr />)()}
								{utils.responsive.choose(<small>Carbono<br/>neutralizado</small>)(<small>Carbono neutralizado</small>)}

							</div>
							<div className="col align-center margin-big">
								<img
									src={Outlined('bottles')}
									alt="co2"
									className="ico"
									style={{height: window.innerWidth > 450 ? 48 : 30}}
									/>
								<strong>
								+500<span className="small">kg</span>
								</strong>
								{utils.responsive.choose(<hr />)()}
								{utils.responsive.choose(<small>Plástico<br/>neutralizado</small>)(<small>Plástico neutralizado</small>)}
							</div>
							<div className="col align-center">
								<img src={treeForMobile} alt="co2" className="ico" style={{height: window.innerWidth > 450 ? 48 : 30}}/>
								<strong>+180</strong>
								{utils.responsive.choose(<hr />)()}
								{utils.responsive.choose(<small>Árvores<br/>plantadas</small>)(<small>Árvores plantadas</small>)}
							</div>
							<div className="col align-center">
								<img src={treesForMobile} alt="co2" className="ico" style={{height: window.innerWidth > 450 ? 48 : 30}}/>
								<strong>
									+2.800<span className="small">ha</span>
								</strong>
								{utils.responsive.choose(<hr />)()}
								{utils.responsive.choose(<small>Área<br/>protegida</small>)(<small>Área protegida</small>)}
							</div>
						</Table>
					</Style.SixthContent>
      	</div>
      </Style.WhiteCover>
    </>
  )
}

export default Section;