import React, { useState, useEffect } from 'react';
import { Table, SliderContainer } from './style';
import { Solid } from '../../../assets/newIcons';

const ColumnTable = ({ arr1, arr2, color }) => {
  const [extend, setExtend] = useState('');
  const [data, setArrays] = useState({ column1: [], column2: [] });

  // useEffect(() => {
  // 	const middleIndex = Math.ceil(arr.length / 2);
  // 	const firstHalf = arr.splice(0, middleIndex);
  // 	const secondHalf = arr.splice(-middleIndex);
  // 	setArrays({ column1: firstHalf, column2: secondHalf });
  // }, []);

  return (
    <Table color={color}>
      {/* {console.log(arrays.column1,arrays.column2, arr )} */}
      <div className="column">
        {arr1.map((item, i) => {
          return (
            <div className="small-column" key={`white_table_${i}`}>
              <div className="row">
                <p className="legend">{item.legend}</p>
                <div className="small-row">
                  <p className="value">{item.value}</p>
                  <img
                    className="arrow-down"
                    src={Solid('arrowDownBlack')}
                    style={{
                      transform: extend === item.legend ? 'rotate(180deg)' : 'rotate(0deg)',
                    }}
                    onClick={() => setExtend(extend === item.legend ? '' : item.legend)}
                  />
                </div>
              </div>
              <SliderContainer extended={extend === item.legend}>
                <p className="hidden-text">{item.content}</p>
              </SliderContainer>
            </div>
          );
        })}
      </div>
      <div className="column">
        {arr2.map((item, i) => {
          return (
            <div className="small-column" key={`white_table_${i}`}>
              <div className="row">
                <p className="legend">{item.legend}</p>
                <div className="small-row">
                  <p className="value">{item.value}</p>
                  <img
                    className="arrow-down"
                    src={Solid('arrowDownBlack')}
                    style={{
                      transform: extend === item.legend ? 'rotate(180deg)' : 'rotate(0deg)',
                    }}
                    onClick={() => setExtend(extend === item.legend ? '' : item.legend)}
                  />
                </div>
              </div>
              <SliderContainer extended={extend === item.legend}>
                <p className="hidden-text">{item.content}</p>
              </SliderContainer>
            </div>
          );
        })}
      </div>
    </Table>
  );
};

export default ColumnTable;
