import * as Style from './style';
import headerLogoWhite from './headerWhiteLogo.svg';
import headerLogoBlack from './headerLogoBlack.svg';
import { Solid } from '../../assets/newIcons';

import Button from '../Forms/Button';
import utils from '../../helper/utils';

const { scrollToContent } = utils.responsive

const Desktop = (props) => {
 const { white, investRef, creditRef, avRef, onClickDesktop } = props;
  return (
    <Style.Header white={white}>
      <div style={{ display: 'inline-block' }}>
        <Style.Container white={white}>
          <div className="header-row">
            <div className="ico-container">
              <img src={Solid('leaf')} alt="leaf" className="leaf" />
              <img
                src={white ? headerLogoBlack : headerLogoWhite}
                className="header-logo"
                alt="header-logo"
              />
            </div>
            <p className="header-text-item" onClick={() => scrollToContent(investRef)}>
              Renda Fixa
            </p>
            <p className="header-text-item" onClick={() => scrollToContent(creditRef)}>
              Crédito privado
            </p>
            <p className="header-text-item" onClick={() => scrollToContent(avRef)}>
              Aluguel Verde
            </p>
          </div>
          <Button
          header
            label="Abrir conta"
            border
            style={{ width: 227, fontWeight: '700', fontSize: 14 }}
            onClick={onClickDesktop}
          />
        </Style.Container>
      </div>
    </Style.Header>
  );
};

export default Desktop;
