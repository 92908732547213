/* eslint-disable no-throw-literal */
import React, { useState, useContext } from 'react'
import Context from '../../../context'
import RegisterTemplate from '../RegisterTemplate'
import { Input, Button } from '../../../components'
import { PageTitle, Margin, Error } from '../style'
import validator from '../../../helper/validator'
import { useNavigate } from 'react-router-dom'
import { useFetch, useStorage } from '../../../hooks'

export default function CPF() {
  const context = useContext(Context)
  const fetch = useFetch('user/getuserbycpf');
  const navigate = useNavigate()

  const [text, setText] = useState('')
  const [error, setError] = useState(false)
  //const [loading, setLoading] = useState(false);
  const storage = useStorage();

  const handleSubmit = async () => {
    //setLoading(true);
    storage.clear();

    fetch.fetch('post', { cpf: text })
      .then((res) => {
        if (!Array.isArray(res.errors)) throw 'user already exists';
        const [_, status] = res.errors;
        if (status !== 404) throw 'invalid status';
        if (!validator.document(text)) throw 'invalid format';
        return;
      })
      .then(() => {
        context.data.cpf = text;
        navigate('/register/name')
        return;
      })
      .catch((err) => {
        console.log(err);
        setError(true)
        //setLoading(false);
      })
  }
  
  return (
    <RegisterTemplate backTo="/">
      <PageTitle>Digite seu CPF ou CNPJ</PageTitle>
      <Margin />
      <Input type="cpf" autofocus onChange={(value) => { setText(value) }} />
      <Margin />
      <Button
        // loading
        disabled={text?.length < 11}
        label="Continuar"
        rounded
        style={{ fontSize: 14, fontWeight: 700, width: '100%', boxSizing: 'border-box' }}
        onClick={handleSubmit}
      />
      {error && <Error>Digite um cpf ou cnpj válido</Error>}
    </RegisterTemplate>
  )
}