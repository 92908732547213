import styled from "styled-components";
import utils from "../../../helper/utils";

const { responsive } = utils;

const size = responsive.ajustSize(150, 125);

export const Card = styled.div`
	width: 208px;
	height: 172.8px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
	border-radius: 5px;
	background: #fdfdfd;
	padding: 24px;
	box-sizing: border-box;
	border: none;
	/* margin-right: 0 !important; */

	.card-icon {
		height: 34px;
		width: auto !important;
		align-self: flex-start;
		@media (max-width: 450px) {
			min-height: 24px;
			height: ${responsive.ajustSize(null, 22).height}px;
		}
	}

	.card-text {
		font-weight: 400;
		font-size: 20px;
		color: #070707;
		margin: 0;
		text-align: left;
		@media (max-width: 450px) {
			font-size: 16px;
			line-height: 20px;
		}
	}

	@media (max-width: 450px) {
		min-height: 125px;
		height: ${size.width * 0.84}px;
		width: ${size.width}px;
		padding: 16px;
		margin: 0 9px;
	}

	&:hover {
		cursor: pointer;
	}
`;
