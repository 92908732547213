import { useState, useEffect, useRef } from "react";
import { useFetch } from "../../../hooks";
import icons from "../../../assets/icons";
import utils from "../../../helper/utils";
import {
	FormContainer,
	Form,
	CloseIcon,
	Divisor,
	Title,
	Row,
	Subtext,
	CodeInput,
	Reenviar,
	BackIcon,
	Error,
} from "./style";
import { Button } from "../../../components";
import confirmGif from "../../../assets/confirm.gif";

const ValidarCell = ({ data, setData, onClickOut, onBack, setStatus }) => {
	const [done, setDone] = useState(false);
	const [error, setError] = useState();
	const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false)
	const api = useFetch("web/confirm-device");
	const apiSendCode = useFetch("web/send-code");
  const [code, setCode] = useState()

	const timer = useRef();

	useEffect(() => {
		if (done) return;
		document.getElementById("code-input").focus();
    // document.getElementById("code-input").attr('autocomplete', 'off')
		return () => {
			clearTimeout(timer.current);
		};
	}, []);

	const onSubmit = () => {
		setLoading(true);
		api
			.fetch("put", { code: code })
			.then((res) => {
				if (res.errors) {
					const [err] = res.errors;
					const [_, message] = err.message.split(";");
					setError(message);
					setLoading(false);
					return;
				}
				setDone(true);
				setLoading(false);
				timer.current = setTimeout(() => setStatus("confirm"), 2000);
			})
			.catch((err) => {
				console.log("err", err);
			});
	};

	const onSendCode = () => {
  
		apiSendCode
			.fetch("get")
			.then(() => {
        setSent(true)
				setError();
        timer.current = setTimeout(() => setSent(false), 3000);
			})
			.catch((err) => {
				setError(err.message);
			});
	};

	return (
		<FormContainer>
			<Form>
				<BackIcon
					src={icons.arrowLeftSolid}
					alt="seta voltar"
					onClick={onBack}
				/>

				<CloseIcon src={icons.xSolid} alt="close icon" onClick={onClickOut} />
				<Title style={{ alignSelf: "center" }}>
					Finalize seu cadastro
					<br />
					verificando seu número!
				</Title>
				<Divisor />
				{!done ? (
					<>
						<Row center style={{ marginBottom: 0 }}>
							<Subtext>
								Digite aqui o código de 4 dígitos que
								<br />
								te enviaremos por SMS no número
								<br />
								<span>{utils.dataTypes.number.toPhone(data?.cellphone)}</span>.
							</Subtext>
						</Row>
						<CodeInput
							filled={code && code.length === 4}
							error={error}
							id="code-input"
							onChange={({ target }) => {
                setError()
								setCode(target.value)
							}}
						/>
						{error && (
							<Row
								style={{ marginTop: 7, marginBottom: 0, alignSelf: "center" }}
							>
								<Error style={{ fontSize: 12, fontWeight: "400" }}>
									{error}
								</Error>
							</Row>
						)}
						<Row
							center
							style={{ alignSelf: "center", marginTop: error ? 34 : 60 }}
							onClick={onSendCode}
						>
							<img src={sent ? icons.sendGreen : icons.messageGreen} alt="baloon" />
							<Reenviar>{sent ? 'Código enviado' : 'Reenviar código'}</Reenviar>
						</Row>
					</>
				) : (
					<>
						<Title style={{ alignSelf: "center", marginTop: 50 }}>
							Número confirmado!
						</Title>
						<img
							src={confirmGif}
							alt="confirm"
							style={{ height: 180, marginTop: 50, marginBottom: 50 }}
						/>
					</>
				)}
				<div style={{ width: "75%" }}>
					<Button
						black
						loading={loading}
						label="Continuar"
						style={{ fontSize: 12, fontWeight: "700" }}
						disaled={!data?.code}
						onClick={() => onSubmit()}
					/>
				</div>
			</Form>
		</FormContainer>
	);
};

export default ValidarCell;
