import React, { useState } from "react";
import {
	BigContainer,
	BigTitle,
	Divisor,
	FullWrapper,
} from "./style";
import {
	SmallSquareButton,
	ValuesTable,
} from "../../../components";
import { PALLETE } from "../../../configs/configs";

const BlackTable = ({ data }) => {
  const [button, setButton] = useState(data.table1[0].title)

  return (
    <FullWrapper color={"#1C2025"}>
				<div style={{ display: "inline-block" }}>
					<BigContainer>
						<BigTitle
							color={data.color1}
							style={{ marginTop: 120, marginBottom: 6 }}
						>
							Cenários projetados
						</BigTitle>
						<div className="row">
							<img src={data.icon} style={{ marginRight: 12 }} />
							<BigTitle color={PALLETE.pureWhite}>
								{data.name} - {new Date(data.createdAt).getFullYear()}
							</BigTitle>
						</div>
						<div
							className="row"
							style={{
								marginTop: 20,
								marginBottom: 155,
							}}
						>
						 {data.table1.map(table => {
                return (
                  <SmallSquareButton
                  // dot
                    color1={data.color1}
                    color2={data.color2}
                    label={table.title}
                    marginRight
                    medium                    
                    selected={table.title === button}
                    onClick={() => setButton(table.title)}
                  />
                )
              })}
						</div>
						<ValuesTable arr={data.table1.filter((table) => table.title === button)[0].row} color={data.color1}/>
						<Divisor
							style={{ marginTop: 120, marginBottom: 120, opacity: "0.1" }}
						/>
						<BigTitle color={data.color1} style={{ marginBottom: 6 }}>
							Garantia e mitigante
						</BigTitle>
						<div className="row" style={{ marginBottom: 150}}>
							<img src={data.icon} style={{ marginRight: 12 }} />
							<BigTitle color={PALLETE.pureWhite}>
								{data.name} - {new Date(data.createdAt).getFullYear()}
							</BigTitle>
						</div>
						<ValuesTable arr={data.table2} color={data.color1} />
						<div style={{ height: 120}} />
					</BigContainer>
				</div>
			</FullWrapper>
  )
}

export default BlackTable;