import React, { useEffect, useRef, useCallback, useState } from 'react';
import { Select } from '../..';
import { MoneyInput, Container, FakeInput } from './style';
import utils from '../../../helper/utils';
const { clearString } = utils.dataTypes.string;
const { choose } = utils.responsive;

export default function M({
  onSelect = () => {},
  selectedToken,
  amount,
  setAmount,
  tokens,
  loading,
}) {
  const amountRef = useRef();
  const [realInput, setRealInput] = useState(false);

  const getProf = useCallback((selectedToken) => {
    const index = selectedToken?.index ? selectedToken?.index.value : 0;
    return (selectedToken?.profitability / 100) + index;
  }, []);

  useEffect(() => {
    if (tokens.length === 0) return;
    const [selected] = tokens;
    onSelect(selected);
  }, [tokens]);

  return (
    <>
      {loading ? (
        <></>
      ) : (
        <Container>
          {choose(<></>)(
            <>
              <div className="inputs-container">
                <p>Ativo</p>
                <Select
                  options={tokens.map((i) => {
                    return { value: i, label: `${i.tick} - ${i.name}` };
                  })}
                  defaultValue={{
                    value: tokens[0],
                    label: `${tokens[0]?.tick} - ${tokens[0]?.name}`,
                  }}
                  placeholder="Captação"
                  isSearchable={false}
                  onChange={(e) => onSelect(e.value)}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
              <div className="inputs-container" style={{ marginBottom: 20 }} >
                <p>Qual valor você quer investir?</p>
                {realInput ? (
                  <MoneyInput
                    id="money-input-simule"
                    ref={amountRef}
                    mask="R$ num"
                    autoFocus={true}
                    onBlur={() => {if (amount === '') return setRealInput(false) }}
                    blocks={{
                      num: {
                        mask: Number,
                        thousandsSeparator: '.',
                        scale: 2,
                        padFractionalZeros: true,
                        radix: ',',
                      },
                    }}
                    onAccept={(e) => {
                      setAmount(e);
                    }}
                  />
                ) : (
                  <FakeInput onClick={() => setRealInput(true)}>
                    <div className="blinking-caret" />
                  </FakeInput>
                )}
              </div>
            </>
          )}

          {choose(
            <>
              <div className="row-for-tags" style={{ marginBottom: 21 }}>
                <p className="mobile-menu-chart-title" style={{ fontSize: 14 }}>
                  Rentabilidade do ativo
                </p>
                <div className="menu-row">
                  <div className="dot" />
                  <p className="selected-title">{`${selectedToken?.tick}`}</p>
                </div>
                <div />
              </div>
              <div className="row-for-tags bottom">
                <div className="menu-row">
                  <p className="tiny-label ">Valor aplicado:</p>
                  <p className="green">{amount}</p>
                </div>
                <div className="menu-row">
                  <p className="tiny-label ">Invest. min.:</p>
                  <p className="green">{`R$ ${utils.dataTypes.number.toMoney(
                    selectedToken?.min
                  )}`}</p>
                </div>
              </div>
            </>
          )(
            <>
              <div className="menu-row bottom">
                <div className="dot" />
                <p className="selected-title">{`${selectedToken.tick} - ${selectedToken.name}`}</p>
              </div>
              <div className="menu-row bottom">
                <p className="tiny-label right">Invest. min.:</p>
                <p className="green">{`R$ ${selectedToken?.min}`}</p>
              </div>
            </>
          )}
          <div className="row-for-tags bottom">
            <div className="menu-row right">
              <p className="tiny-label">Garantia:</p>
              <div className="menu-tag">
                <p className="tag-content">CDB - FGC</p>
              </div>
            </div>
            <div className="menu-row">
              <p className="tiny-label right">Modelo:</p>
              <div className="menu-tag">
                <p className="tag-content">{selectedToken?.paymentType || 'Juros mensais'}</p>
              </div>
            </div>
          </div>
          <div className="row-for-tags bottom">
            <div className="menu-row right">
              <p className="tiny-label ">Captado:</p>
              <div className="menu-tag">
                <p className="tag-content">{Math.round(selectedToken?.rate * 100)}%</p>
              </div>
            </div>
            <div className="menu-row right">
              <p className="tiny-label ">Prazo:</p>
              <div className="menu-tag">
                <p className="tag-content">{selectedToken?.deadline} {{
                  d: 'Dias',
                  s: 'Semanas',
                  m: 'Meses',
                  a: 'Anos',
                }[selectedToken.recurrence || 'm']}</p>
              </div>
            </div>
            <div className="menu-row">
              <div className="menu-tag" style={{ marginLeft: 0 }}>
                <p className="tag-content">
                  {`${selectedToken?.index ? `${selectedToken?.index.name} + ` : ''}${
                    selectedToken?.profitability
                  }`}
                  %
                </p>
              </div>
              <p className="tiny-label left">ao ano</p>
            </div>
          </div>
          <div className="line first" />
          <div className="menu-row space" style={{ width: '100%' }}>
            <div className="menu-cluster margin-cluster start">
              <p
                className="green no-margin"
                style={{ marginBottom: 5 }}
              >{`R$ ${utils.dataTypes.number.toMoney(
                (getProf(selectedToken) / 12) * clearString(amount)
              )}`}</p>
              <p className="tiny-label ">Rent. no mês</p>
            </div>
            <div className="menu-cluster margin-cluster end">
              <p
                className="green no-margin"
                style={{ marginBottom: 5 }}
              >{`R$ ${utils.dataTypes.number.toMoney(
                getProf(selectedToken) * clearString(amount)
              )}`}</p>
              <p className="tiny-label ">Rent. no ano</p>
            </div>
          </div>
          <div className="menu-row space" style={{ width: '100%' }}>
            <div className="menu-cluster start">
              <p
                className="green no-margin"
                style={{ marginBottom: 5 }}
              >{`R$ ${utils.dataTypes.number.toMoney(
                (getProf(selectedToken) / 12) * clearString(amount) * selectedToken?.deadline
              )}`}</p>
              <p className="tiny-label ">Rent. total</p>
            </div>
            <div className="menu-cluster end">
              <p
                className="green no-margin"
                style={{ marginBottom: 5 }}
              >{`R$ ${utils.dataTypes.number.toMoney(
                (getProf(selectedToken) / 12) * clearString(amount) * selectedToken?.deadline +
                  clearString(amount)
              )}`}</p>
              <p className="tiny-label ">Rendimento + Principal</p>
            </div>
          </div>
          <div className="line second" />
        </Container>
      )}
    </>
  );
}
