import React from "react";
import { BigContainer, BigTitle, Divisor, FullWrapper } from "./style";
import { ColumnTable, SmallSquareButton, ValuesTable } from "../../../components";
import { Solid } from "../../../assets/newIcons";
import { PALLETE } from "../../../configs/configs";

const WhiteTable = ({ data, creactAccount }) => {
	return (
			// <FullWrapper color={"transparent"}>
			// 	<div style={{ display: "inline-block" }}>
					<BigContainer style={{paddingTop: 0}}>
						<BigTitle
							style={{ marginTop: 70, marginBottom: 6, width: '100%' }}
						>
							{data.fullName} - {new Date(data.createdAt).getFullYear()}
						</BigTitle>
						<div className="row">
							<BigTitle >
								{data.tick} -
							</BigTitle>
							<img src={data.icon} style={{ marginLeft: 12 }} height={24}/>
						</div>
						<div
							className="row"
							style={{
								marginTop: 20,
								marginBottom: 46,
							}}
						>
						<SmallSquareButton
            color1={data.color1}
            color2={data.color2}
            label={'Lâmina técnica'}
            marginRight
          />
          <SmallSquareButton
            color1={data.color1}
            color2={data.color2}
            label={'Contrato'}
            smallerLabel={'(leitura)'}
            marginRight
            // selected
          />
						</div>
            {/* {console.log(data)} */}
            <ValuesTable arr={[...data.table3Half1, ...data.table3Half2]} isWhiteTable color={data.color1}/>
            <div
						className="row"
						style={{
							marginTop: 60,
							marginBottom: 60,
							justifyContent: "center",
						}}
					>
						<SmallSquareButton
							color1={data.color1}
							color2={data.color2}
							label={"Investir"}
							marginRight
              selected
              onClick={creactAccount}
						/>
            </div>
					</BigContainer>
			// 	</div>
			// </FullWrapper>
	);
};

export default WhiteTable;
