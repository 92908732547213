import React, { useEffect, useRef, useState } from "react";
import {
	DotIndicator,
	CarouselContainer,
	ArrowRight,
	ArrowLeft,
} from "./style";
import arrowIco from "../../assets/select-arrow.svg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import icons from "../../assets/icons";
import utils from "../../helper/utils";
const { responsive } = utils;

const CustomRightArrow = ({ onClick }) => {
	return <ArrowRight id="arrow-right" src={arrowIco} onClick={onClick} />;
};

const CustomLeftArrow = ({ onClick }) => {
	return <ArrowLeft id="arrow-left" src={arrowIco} onClick={onClick} />;
};

const CustomDot = ({ onClick, ...rest }) => {
	const { index, active } = rest;
	// const dots =
	const carouselItems = [<div />];
	// onMove means if dragging or swiping in progress.
	// active is provided by this lib for checking if the item is active or not.
	return (
		<DotIndicator
			// id="dotButton"
			type="button"
			className={active ? "selected" : "not-selected"}
			onClick={() => onClick()}
		>
			{/* {console.log(React.Children.toArray(carouselItems))}
			{React.Children.toArray(carouselItems)[index]} */}
			{/* {index + 1} */}
		</DotIndicator>
	);
};

export default function Carrousel({
	children,
	desktopFrames = 4,
	mobFrames = 2,
	removeOuterPadding = true,
	showDots = true,
	centerMode = false,
	infinite = true,
	noPaddingLeft = false,
	arrows = false,
	isCalculator = false,
	removeHoriPadd = false,
	slidesToSlide = 1,
	isMob = false,
	autoPlay = false,
	setTouchStart,
	setTouchEnd
}) {
	const carouselRef = useRef();
	const [carouselIndex, setCarouselIndex] = useState(0);

	const responsives = {
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: desktopFrames,
			// slidesToSlide: 1, // optional, default to 1.
			partialVisibilityGutter: 10,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: mobFrames,
			// slidesToSlide: 1, // optional, default to 1.
			partialVisibilityGutter: 10,
		},
	};

	return (
		<CarouselContainer
			isMob={isMob}
			itemsLength={children.length}
			isCalculator={isCalculator}
			noPaddingLeft={noPaddingLeft}
			removeOuterPadding={removeOuterPadding}
			arrows={arrows}
			removeHoriPadd={removeHoriPadd}
			onTouchStart={setTouchStart}
			onTouchEnd={setTouchEnd}
		>
			<Carousel
				autoPlay={autoPlay}
				autoPlaySpeed={3000}
				transitionDuration={100}
				pauseOnHover
				// customTransition="all 3s linear"
				rewind={false}
				rewindWithAnimation={false}
				slidesToSlide={slidesToSlide}
				ref={carouselRef}
				draggable={!arrows}
				swipeable={true}
				centerMode={centerMode}
				infinite={infinite}
				arrows={false}
				responsive={responsives}
				showDots={showDots}
				containerClass={"slider-container"}
				customDot={<CustomDot />}
				sliderClass={"carousel-slider-class"}
				itemClass="carousel-item-class"
				afterChange={(previousSlide, { currentSlide, onMove }) =>
					setCarouselIndex(currentSlide)
				}
			>
				{children}
			</Carousel>
			{responsive.choose(
				<>
					{arrows && carouselIndex !== 0 && (
						<CustomLeftArrow onClick={() => carouselRef.current.previous()} />
					)}
					{arrows && carouselIndex !== children.length - mobFrames && (
						<CustomRightArrow onClick={() => carouselRef.current.next()} />
					)}
				</>,
			)(
				<>
					{arrows && carouselIndex !== 0 && (
						<CustomLeftArrow onClick={() => carouselRef.current.previous()} />
					)}
					{arrows && carouselIndex !== children.length - desktopFrames && (
						<CustomRightArrow onClick={() => carouselRef.current.next()} />
					)}
				</>,
			)}
		</CarouselContainer>
	);
}
