import styled from 'styled-components';
import { PALLETE } from '../../configs/configs';
import utils from '../../helper/utils';

export const BigChartContainer = styled.div`
  width: 850px;
  background-color: #f5f5f5;
  padding: 33px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  @media (max-width: 450px) {
    width: 100%;
    padding: 0;
    background-color: transparent;
  }

  .line {
    width: 100%;
    border: 1px solid #eeeeee;
    margin-top: 10px;
    @media (max-width: 450px) {
      margin-bottom: 24px;
    }
  }

  .chart-resume-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: ${PALLETE.black};
    padding: 0 8px;
    border-left: 1px solid ${PALLETE.base};
    margin: 20px 0 20px 0;
  }

  .chart-row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .big-chart-row {
    width: 100%;
    justify-content: space-between;
  }

  .resume-title-chart {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: ${PALLETE.black};
    margin: 0;
  }

  .resume-value-chart {
    font-weight: 700;
    font-size: 12px;
    line-height: 21px;
    color: ${PALLETE.main};
    margin: 0 0 0 8px;
  }

`;


export const SelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;

  .chart-subtitle {
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    color: ${PALLETE.black};
    margin: 22px 0 12px 0;
  }
  .chart-button-row {
    display: flex;
    align-items: center;
    background: #f5f5f5;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
  `;

export const ButtonSelectorChart = styled.button`
  background: ${(props) => {
    if (props.color) return props.color;
    return 'tranparent';
  }};
  border-radius: 5px;
  border: none;
  box-shadow: ${(props) => {
    if (props.isSelected) return `0px 4px 10px ${utils.dataTypes.color.hexToRgbAOpacity(PALLETE.base, 0.3)}`;
    return 'none';
  }};
  box-sizing: border-box;
  color: ${(props) => (props.color ? 'white' : '#757575')};
  height: 35px;
  margin-right: 2px;
  padding: 8px 16px;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;

  @media (max-width: 450px) {
    font-size: ${utils.responsive.ajustFont(8)}px;
    line-height: ${utils.responsive.ajustFont(10)}px;
  }
`;
