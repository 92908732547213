import React from 'react';
import { Card } from './style';
import { Outlined } from '../../../assets/newIcons';

const TCard = ({ item, i }) => {
  return (
    <Card
      marginCircle={'circleOpacity' in item}
      circleOpacity={item.circleOpacity}
    >
      {('circleOpacity' in item) && (
        <div className="circle">
          <p className="card-title white" style={{ marginTop: 0}}>{i + 1}</p>
        </div>
      )}
      <div className="card">
        <div className='min-column'>
          <img
            src={Outlined(item.icon)}
            alt="content-card-icon"
            height={item.iconHeight}
          />
          <p className="card-title" style={item?.titleStyle}>{item.title}</p>
        </div>
        <p className="card-text">{item.text}</p>
      </div>
    </Card>
  );
};

export default TCard;
