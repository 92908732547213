import styled from 'styled-components';
import { PALLETE, BREAKPOINTS } from '../../configs/configs';
import slider3 from './slider3.svg';
import handleVazado from './handleVazado.svg';
import utils from '../../helper/utils';


import { Solid } from '../../assets/newIcons';

const { responsive, dataTypes } = utils;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .title {
    font-weight: 400;
    font-size: 32px;
    margin-bottom: 50px;
    color: ${PALLETE.inputGrey};
    @media (max-width: 450px) {
      font-size: ${responsive.ajustFont(14)}px;
      margin-bottom: 30px;
      color: ${PALLETE.black};
    }
  }

  .row {
    display: flex;
    align-items: center;
  }

  .invest-name {
    font-weight: 400;
    font-size: 40px;
    margin: 0 0 0 24px;
    color: ${PALLETE.black};
    @media (max-width: 450px) {
      font-size: ${responsive.ajustFont(20)}px;
    }
  }

  .desktop-grid {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    @media (max-width: 450px) {
      flex-wrap: wrap;
      /* justify-content: space-evenly; */

    }
  }
`;

export const InfoNot = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .value {
    display: flex;
    font-weight: 400;
    font-size: 32px;
    margin-bottom: 12px;
    color: ${PALLETE.black};
    @media (max-width: 450px) {
      font-size: ${responsive.ajustFont(22)}px;
    }
  }

  .legend {
    font-weight: 400;
    font-size: 32px;
    margin: 0;
    color: ${(props) => {
      if (props.color) return props.color;
      return PALLETE.inputGrey;
    }};
    @media (max-width: 450px) {
      font-size: ${responsive.ajustFont(18)}px;
    }
  }
`;

export const SliderContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  margin-bottom: 30px;

  @media (min-width: ${BREAKPOINTS.DEFAULT}px) {
    padding: 0 40px;
    margin-bottom: 68px;
    /* max-width: 592px; */
  }

  strong {
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    color: #070707;

    small {
      font-size: 12px;
    }
  }

  input[type='range'] {
    -webkit-appearance: none; /* Override default CSS styles */
    -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
    appearance: none;
    /* background: #d3d3d3; /* Grey background */
    background: ${(props) =>
      `linear-gradient(to right, ${props.color}, ${props.color} ${props.rate}%, #d3d3d3 ${props.rate}%, #d3d3d3 100%)`};
    border-radius: 4px;
    height: 2px; /* Specified height */
    outline: none; /* Remove outline */
    transition: opacity 0.2s;
    width: 100%; /* Full-width */
    padding: 0;

    @media (min-width: ${BREAKPOINTS.DEFAULT}px) {
      margin-top: 46px;
    }
  }

  input::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    /* background:${(props) => `url(${Solid(props.slider)})`};  */
    background: url(${handleVazado});
    /* background-color: ${PALLETE.pureWhite}; */
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
    ${(props) => {
      if (props.color) return dataTypes.color.filterGenerator(props.color);
      return 'filter: none';
    }};
    cursor: pointer;
    height: 24px;
    width: 24px;
    background-color: transparent;
    border-radius: 15px;
    padding: 0;
    margin: 0;
    /* border: 1px solid red;  */
  }
`;

export const MobileInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

`