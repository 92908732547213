import styled from "styled-components";
import { IMaskInput } from "react-imask";
import { PALLETE } from "../../../configs/configs";
import utils from "../../../helper/utils";

export const Column = styled.div`
	display: flex;
	flex-direction: column-reverse;
	width: 100%;
	align-items: flex-start;
`;

export const Label = styled.label`
	font-weight: 500;
	font-size: 14px;
	color: #757575;
	margin-top: 24px;
	margin-bottom: 8px;

	input:hover ~ & {
		color: #070707;
	}

	input:focus ~ & {
		color: #424242;
	}
`;
export const Text = styled.input`
	caret-color: ${PALLETE.main};
	font-weight: 400;
	color: #757575;
	height: 48px;
	box-sizing: border-box;
	width: 100%;
	font-size: 14px;
	padding: 16px;
	border: ${(props) => {
		if (props.error) return "1px solid #D61212";
		return "1px solid #757575";
	}};
	border-radius: 5px;
	outline: none;
	&:hover {
		border: ${(props) => {
			if (props.error) return "1px solid #D61212";
			return "1px solid #070707";
		}};
	}
	&:focus {
		color: #424242;
		border: ${(props) => {
			if (props.error) return "1px solid #D61212";
			return "1px solid #424242";
		}};
	}
`;

export const MaskedInput = styled(IMaskInput)`
	caret-color: ${PALLETE.main};
	font-weight: 400;
	color: #757575;
	height: 48px;
	box-sizing: border-box;
	width: 100%;
	font-size: 14px;
	padding: 16px;
	border: ${(props) => {
		if (props.error) return "1px solid #D61212";
		return "1px solid #757575";
	}};
	border-radius: 5px;
	outline: none;
	&:hover {
		border: ${(props) => {
			if (props.error) return "1px solid #D61212";
			return "1px solid #070707";
		}};
	}

	&:focus {
		border: ${(props) => {
			if (props.error) return "1px solid #D61212";
			return "1px solid #424242";
		}};
		color: #424242;
	}
`;

export const FormContainer = styled.div`
	padding: 50px;
	display: flex;
	flex-direction: column;
	width: 100%;
	/* height: 100%; */
	position: relative;
	justify-content: center;
	box-sizing: border-box;
`;

export const Form = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	/* height: 100%; */

	a:link {
		color: #757575 !important;
	}

	a:visited {
		color: #757575 !important;
	}

	a:hover {
		color: #757575 !important;
	}

	a:active {
		color: #757575 !important;
	}
`;

export const Title = styled.h2`
	font-weight: 500;
	font-size: 30px;
	color: #070707;
	margin: 0;
	white-space: nowrap;
	align-self: flex-start;
`;

export const Divisor = styled.hr`
	width: 100%;
	margin-top: 24px;
	border: 1px solid #f5f5f5;
	opacity: 0.6;
`;

export const CloseIcon = styled.img`
	position: absolute;
	top: 0;
	right: 0;
	padding: 30px;
	&:hover {
		cursor: pointer;
	}
`;

export const CheckboxLabel = styled.small`
	font-weight: 400;
	font-size: 14px;
	color: #757575;
	margin: 0 0 0 20px;
	white-space: nowrap;
`;

export const Row = styled.div`
	margin-top: 30px;
	margin-bottom: 36px;
	display: flex;
	flex-direction: row;
	justify-content: ${(props) => {
		if (props.center) return "center";
		return "flex-start";
	}};
	text-align: left;
	align-items: center;
	align-self: flex-start;
`;

export const Error = styled.small`
	font-weight: 300;
	font-size: 10px;
	color: #d61212;
	margin-left: 5px;
`;

export const Link = styled.a`
	font-weight: 300;
	font-size: 10px;
	color: #d61212 !important;
	margin-left: 5px;
	text-decoration: underline;
`;

export const Subtext = styled.p`
	font-style: normal;
	font-weight: 400;
	font-size: 22px;
	color: #070707;
	text-align: center;
	mix-blend-mode: normal;
	opacity: 0.8;

	span {
		color: ${PALLETE.main};
	}
`;

export const CodeInput = styled(IMaskInput)`
	border: none;
	outline: none;
	background-color: transparent;
	border-radius: 0;
	font-weight: 700;
	font-size: 35px;
	color: ${((props) => {
		if(props.error) return '#F60665';
		return PALLETE.inputGrey
	})};
	caret-color: ${PALLETE.main};
	min-height: 40px;
	padding: 10px 0;
	text-align: center;
	margin-right: ${(props) => (props.filled ? `-50px` : 0)};
	letter-spacing: 50px;
	width: 100%;
	margin-top: 50px;
	/* margin-bottom: 55px; */
`;

export const Reenviar = styled.small`
	font-weight: 400;
	font-size: 14px;
	color: #06e45f;
	/* margin-bottom: 50px; */
	margin-top: 0;
	margin-left: 12px;
	&:hover {
		cursor: pointer;
	}
`;

export const Bold = styled.p`
	text-align: center;
	font-weight: 700;
	font-size: 30px;
	align-self: center;
	margin: 0;
`;

export const BackIcon = styled.img`
	position: absolute;
	top: 0;
	left: 0;
	padding: 30px;
	height: 15px;
	&:hover {
		cursor: pointer;
	}
`;

export const EyeIcon = styled.img`
	position: absolute;
	top: 46px;
	right: 0;
	padding: 17px;
	input:hover ~ & {
		filter: brightness(0%);
	}
	&:hover {
		filter: brightness(0%);
		cursor: pointer;
	}
	/* height: 15px; */
`