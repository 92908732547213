
import Context from './index';

export default function RegisterProvider ({ children }) {
  const store = new Proxy({
    data: {},
    token: null,
  }, {});

  return (
    <Context.Provider value={store}>
      {children}
    </Context.Provider>
  );
};
