import React from "react";
import { Button } from "./style";
import { Outlined, Solid } from "../../assets/newIcons";
import utils from '../../helper/utils'

export default ({ label, onClick, width=222, style }) => {
  return (
    <Button type="button" width={width} style={style} onClick={onClick}>
      <p className="button-label">{label}</p>
      <img
        src={Solid("arrowRight")}
        alt="button-icon"
        className="arrow"
        height={utils.responsive.choose(10)(12)}
      />
    </Button>
  )
}