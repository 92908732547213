import { useEffect, useState, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'
import utils from '../../../helper/utils'

import * as Style from './style'

export default function Input({
  autofocus,
  id = uuidv4(),
  onChange = () => {},
  onAccept = () => {},
  onFocus = () => {},
  type = 'text',
  maxLength,
}) {
  const input = useRef()
  const [filled, setFilled] = useState(false)
  
  useEffect(() => {
    if (autofocus && input.current) input.current.element.focus();
    else if (autofocus) {
      const el = document.getElementById(id);
      el.focus();
    }
  }, []);

  return {
    text: <Style.Text
            type="text"
            onChange={(ev) => onChange(ev.target.value)}
            onBlur={onAccept}
            id={id}
            maxLength={maxLength}
            autoFocus={autofocus}
            className="input-text-register"
          />,
    code: <Style.Code
            mask="0000"
            onAccept={(v) => onChange(utils.dataTypes.string.clear(v))}
            onChange={(el) => setFilled(el.target.value !== '')}
            onBlur={onAccept}
            id={id}
            maxLength={maxLength}
            filled={filled}
            className="input-text-register"
          />,
    cpf: <Style.MaskedInput
            mask={[{ mask: "000.000.000-00" }, { mask: "00.000.000/0000-00" }]}
            onAccept={(v) => onChange(utils.dataTypes.string.clear(v))}
            id={id}
            ref={input}
            maxLength={maxLength}
            className="input-text-register"
          />,
    phone: <Style.MaskedInput
            mask="(00) 00000-0000"
            onAccept={(v) => onChange(utils.dataTypes.string.clear(v))}
            id={id}
            ref={input}
            maxLength={maxLength}
            className="input-text-register"
          />,
  }[type.toLocaleLowerCase()]
};