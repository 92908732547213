import React, { useEffect, useState } from 'react';
import {
  BigContainer,
  BigTitle,
  Divisor,
  InfoNot,
  InfoCard,
  Responsibility,
  FullWrapper,
  CardsContainer,
} from './style';
import {
  InvestHeader,
  SmallSquareButton,
  ProgressCircle,
  InvestCalculator,
  TokenCard,
  Footer,
  ModalLateral,
} from '../../../components';
import { mock } from '../mockDetalhesSite';
import { Solid } from '../../../assets/newIcons';
import utils from '../../../helper/utils';
import BlackTable from './blackTable';
import WhiteTable from './whiteTable';
import ColouredTable from './colouredTable';
import DesktopForm from '../../register/DeskTopForm';
import { useNavigate } from 'react-router-dom';

const DetalhesDesktop = ({ data = mock }) => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate()

  useEffect(() => {
    document.getElementById('root').style.textAlign = 'center';
  }, []);

  if (!data.tick) return <></>

  return (
    <>
      <InvestHeader
        fixed={true}
        data={data}
        onClickDesktop={() => {
          setShowModal(true);
        }}
      />
      <div style={{ display: 'inline-block' }}>
        <BigContainer color1={data.color1}>
          <div className="desktop-grid" style={{ marginTop: 180 }}>
            <div style={{ marginTop: 17 }}>
              <BigTitle>
                {data.fullName} - {new Date(data.createdAt).getFullYear()}
              </BigTitle>
              <div className="row" style={{ marginTop: 6 }}>
                <BigTitle>{data.tick} - </BigTitle>
                <img src={data.icon} style={{ marginLeft: 12, height: 48 }} />
              </div>
              <div className="row" style={{ marginTop: 27 }}>
                <SmallSquareButton
                  color1={data.color1}
                  color2={data.color2}
                  label="Lâmina técnica"
                  marginRight
                />
                <SmallSquareButton
                  color1={data.color1}
                  color2={data.color2}
                  label={'Investir'}
                  marginRight
                  selected
                  onClick={() => {
                    setShowModal(true);
                  }}
                />
              </div>
            </div>
            <div className="graph-container">
              <ProgressCircle valueArray={data.chart} squareEnd />
              <div className="rate-position">
                <p className="span-rate">{Number(data.rate / 100)?.toFixed(2)?.replace('.', ',')} %</p>
                <span className="rate">Captado</span>
              </div>
            </div>
          </div>
          <Divisor />
          <div className="desktop-grid">
            <InfoNot>
              <p className="value">{data.profitability}% a.a.</p>
              <p className="legend">Rentabilidade</p>
            </InfoNot>
            <InfoNot>
              <p className="value">{data.min}</p>
              <p className="legend">Invest. mínimo</p>
            </InfoNot>
            <InfoNot>
              <p className="value">{data.deadline}</p>
              <p className="legend">Prazo</p>
            </InfoNot>
            <InfoNot color={data.color1}>
              <p className="value">
                {data.category}
                {data.fgc && <img className="colored-check" src={Solid('bigCheckBlack')} alt="icon black" />}{' '}
                {data.fgc ? 'FGC' : ''}
              </p>
              <p className="legend">Garantia</p>
            </InfoNot>
          </div>
          <Divisor />
          <div className="desktop-grid">
            <InfoCard color={data.color1}>
              <p className="value">
                {new Date(data.startAt).getDate() > 9
                  ? new Date(data.startAt).getDate()
                  : '0' + new Date(data.startAt).getDate()}{' '}
                {utils.dataTypes.date.numberToMonth(new Date(data.startAt).getMonth() + 1)[1]}{' '}
                {new Date(data.startAt).getFullYear()}
              </p>
              <p className="legend">
                Data de início
                <br />
                de rentabilidade
              </p>
            </InfoCard>
            <InfoCard color={data.color1}>
              <p className="value">
                {new Date(data.endAt).getDate() > 9
                  ? new Date(data.endAt).getDate()
                  : '0' + new Date(data.endAt).getDate()}{' '}
                {utils.dataTypes.date.numberToMonth(new Date(data.endAt).getMonth() + 1)[1]}{' '}
                {new Date(data.endAt).getFullYear()}
              </p>
              <p className="legend">
                Encerramento
                <br />
                de rentabilidade
              </p>
            </InfoCard>
            <InfoCard color={data.color1}>
              <p className="value">{data.recurrence}</p>
              <p className="legend">
                Modelo de
                <br />
                Investimento
              </p>
            </InfoCard>
            <InfoCard color={data.color1}>
              <p className="value">
                {data.risk}
                <img
                  className="colored-check"
                  src={Solid('bigCheckBlack')}
                  style={{ height: 17 }}
                />
              </p>
              <p className="legend">
                Classificação
                <br />
                de risco
              </p>
            </InfoCard>
          </div>
          <Divisor />
          <InvestCalculator data={data} />
          <div
            className="row"
            style={{
              marginTop: 60,
              marginBottom: 80,
              justifyContent: 'center',
            }}
          >
            <SmallSquareButton
              color1={data.color1}
              color2={data.color2}
              label={'Lâmina técnica'}
              marginRight
            />
            <SmallSquareButton
              color1={data.color1}
              color2={data.color2}
              label={'Investir'}
              onClick={() => {
                setShowModal(true);
              }}
              marginRight
            />
          </div>
        </BigContainer>
      </div>
      {data.table1 && data.table2 && <BlackTable data={data} createAccount={() => setShowModal(true)}/>}
      {data.table3Half1 && data.table3Half2 &&<WhiteTable data={data} createAccount={() => setShowModal(true)}/>}
      {data.table3 &&<ColouredTable data={data} createAccount={() => setShowModal(true)}/>}
      <FullWrapper color={'#F2F2F2'}>
        <div style={{ display: 'inline-block' }}>
          <Responsibility>
            <h2 className="big-title">
              Exclusão de
              <br />
              responsabilidade
            </h2>
            <p className="term-text">{data.freeResponsibility}</p>
          </Responsibility>
        </div>
      </FullWrapper>
      {data.similarCards.length > 0 &&  <FullWrapper color={'#ffffff'}>
        <div style={{ display: 'inline-block' }}>
          <CardsContainer>
            <h2 className="big-title">Investimentos semelhantes</h2>
            <h3 className="subtitle">
              Selecionamos alguns investimentos alinhados ao seu perfil de investidor
            </h3>
            <div className="cards-wrapper">
              {data.similarCards.map((item) => {
                return (
                  <div style={{ marginBottom: 50}}>
                  <TokenCard
                    key={item.tick}
                    icon={item.icon}
                    tokenName={item.tick}
                    min={item.min}
                    color={item.color}
                    value={item.lastPrice * item.circulating || 0}
                    variation={item.variation}
                    down={String(item.variation).includes('-')}
                    profitability={item.profitability}
                    index={item.index}
                    name={item.name}
                    rate={item.rate}
                    recurrence={item.recurrence}
                    deadline={item.deadline}
                    createdAt={item.createdAt}
                    fgc={item.fgc}
                    category={item?.category}
                    onClick={() => {navigate(`/investment/${item.id}`)}}
                    />
                    </div>
                );
              })}
            </div>
          </CardsContainer>
        </div>
      </FullWrapper>}
      <Footer />
      <ModalLateral showForm={showModal} onClickOut={() => setShowModal(false)}>
        <DesktopForm onClickOut={() => setShowModal(false)} />
      </ModalLateral>
    </>
  );
};

export default DetalhesDesktop;
