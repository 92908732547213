import styled from 'styled-components';
import { PALLETE } from '../../configs/configs';


export const Background = styled.div`
  background-color: ${(props) => (props.bgZIndex ? PALLETE.modal : PALLETE.modal2)};
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 12;
  `;

export const Modal = styled.div`
  box-sizing: border-box;
  align-items: flex-start;
  background-color: ${PALLETE.white};
  border-top-left-radius: ${(props) => (props.full ? 0 : 20)}px;
  border-top-right-radius: ${(props) => (props.full ? 0 : 20)}px;
  display: flex;
  position: absolute;
  bottom: 0;
  z-index: ${12};
  overflow: hidden;
  box-shadow: 5px 2px 20px 1px #07070780;
  height: ${(props) => { return `${props.height}px` }};
  width: 100%;
  transform-origin: 0 100%;
  transition: transform .5s;
  transform: ${(props) => {
    if (props.extended) return `scaleY(1)`
    return `scaleY(0)`
  }};
`;

export const ScrollModal = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.backgroundColor};
  padding: ${(props) => {
        if (props.image || props.noPadding) return '0px';
        return `0 30px`;
    }};
  overflow: scroll;
`;


export const CloseIcon = styled.div`
  padding: ${(props) => {
        if (props.modalTitle) return '30px 30px 30px 30px';
        return '30px 30px 0 30px';
    }};
  position: absolute;
  right: 0;
  top: 0;
`;

export const IconContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  height: ${(props) => {
        if (props.modalTitle) return '80px'
        return '50px'
    }};
`;

export const ChildrenContainer = styled.div`
   z-index: 12;
  height: 100%;
  width: 100%;
//   overflow-y: scroll;
  padding: ${(props) => {
        if (props.noPadding && props.noCloseIcon) return '0 0 0 0';
        if (props.noPadding) return '0 0 0 0';
        if (props.setPadding) return props.setPadding;
        return '0'
    }};
`;

export const ChildContainer = styled.div`
  flex-direction: column;
  justify-content: ${(props) => props.justifyContent};
  height: 100%;
  width: 100%;
`;

// export const ShadowView = styled.div`
//   border: 1px solid ${PALLETE.white};
//   position: absolute;
//   z-index: 20;
//   align-self: center;
//   width: ${Dimensions.get('screen').width - 6}px;
// `