import React from 'react';
import { BigContainer, BigTitle, Divisor, FullWrapper } from './style';
import { IconTable, SmallSquareButton, ValuesTable } from '../../../components';
import { Solid } from '../../../assets/newIcons';
import { PALLETE } from '../../../configs/configs';

const ColouredTable = ({ data, createAccount }) => {
  return (
    <FullWrapper color={data.color1}>
    <div style={{ display: "inline-block" }}>
      <BigContainer>
        <BigTitle
          color={PALLETE.pureWhite}
          style={{ marginTop: 120, marginBottom: 6, width: '70%' }}
        >
          {data.fullName} - {new Date(data.createdAt).getFullYear()}
        </BigTitle>
        <div className="row" style={{ marginBottom: 140}}>
          <BigTitle color={PALLETE.pureWhite}>
            {data.tick} -
          </BigTitle>
          <img src={Solid(data.iconTickInverted)} style={{ marginLeft: 12 }} />
        </div>
        <IconTable arr={data.table3} />
        <div
						className="row"
						style={{
							marginTop: 120,
							marginBottom: 120,
							justifyContent: "center",
						}}
					>
						<SmallSquareButton
							color1={data.color1}
							color2={data.color2}
							label={"Lâmina técnica"}
							marginRight
						/>
						<SmallSquareButton
							color1={data.color1}
							color2={data.color2}
							label={"Investir"}
							marginRight
              onClick={createAccount}
						/>
					</div>

      </BigContainer>
    </div>
  </FullWrapper>
  );
};

export default ColouredTable;
