import styled from 'styled-components';
import utils from '../../../helper/utils';
import { BREAKPOINTS, PALLETE } from '../../../configs/configs';
const { dataTypes } = utils;

export const BigContainer = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Inter&family=Roboto:wght@200;300;500;700&display=swap');
  text-align: center;
  font-family: 'Inter', sans-serif;
  padding: 114px ${utils.responsive.ajustFont(30)}px 0 ${utils.responsive.ajustFont(30)}px;
  max-width: 100%;
  scroll-snap-type: proximity;

  .desktop-grid {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .align-left {
      width: 100%;
      margin-left: 94px;
    }
  }

  .column {
    display: flex;
    flex-direction: column;
  }

  .row {
    display: flex;
    flex-direction: row;
  }

  .graph-container {
    position: relative;
  }

  .rate-position {
    position: absolute;
    top: 90px;
    left: 75px;
  }

  .rate {
    font-weight: 300;
    font-size: 16px;
    text-align: center;
    color: ${PALLETE.black};
    margin: 0;
  }

  .span-rate {
    font-weight: 700;
    font-size: 24px;
    text-align: center;
    margin: 0;
    color: ${(props) => props.color1};
  }

  @media (min-width: ${BREAKPOINTS.DEFAULT}px) {
    padding: 0 220px;
    width: 1440px;
    box-sizing: border-box;
  }
`;

export const BigTitle = styled.h1`
  text-align: left;
  font-weight: ${(props) => {
    if (props.regular) return '400';
    return '500';
  }};
  font-size: 40px;
  color: ${(props) => {
    if (props.color) return props.color;
    return 'black';
  }};
  margin: 0;
`;

export const Divisor = styled.hr`
  width: 100%;
  margin: ${(props) => (!props.small ? '60px 0' : 0)};
  /* TODO - Suavizar a expessura pra 0.6px #1298 */
  border: 1px solid #f5f5f5;
  opacity: 0.6;

  @media (min-width: ${BREAKPOINTS.DEFAULT}px) {
    margin-top: 60px ${(props) => (props.small ? '60px' : 0)};
  }
`;

export const InfoNot = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .colored-check {
    ${(props) => {
      if (props.color) return dataTypes.color.filterGenerator(props.color);
      return 'filter: none';
    }};
  }

  .value {
    display: flex;
    font-weight: 400;
    font-size: 32px;
    margin-bottom: 12px;
    color: ${PALLETE.black};
  }

  .legend {
    font-weight: 400;
    font-size: 32px;
    margin: 0;
    color: ${PALLETE.inputGrey};
  }
`;

export const InfoCard = styled.div`
  box-sizing: border-box;
  width: 208px;
  height: 173px;
  background-color: ${(props) => utils.dataTypes.color.hexToRgbAOpacity(props.color, 0.1)};
  padding: 24px 20px 24px 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  .colored-check {
    ${(props) => {
      if (props.color) return dataTypes.color.filterGenerator(props.color);
      return 'filter: none';
    }};
  }

  .value {
    color: ${(props) => props.color};
    font-weight: 700;
    font-size: 16px;
    margin: 0;
  }

  .legend {
    color: ${PALLETE.black};
    font-weight: 400;
    font-size: 20px;
    margin: 0;
    text-align: left;
  }
`;

export const FullWrapper = styled.div`
  width: 100%;
  text-align: center;
  background-color: ${(props) => {
    if (props.color) return props.color;
    return 'transparent';
  }};
`;

export const Responsibility = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 120px 220px;
  width: 1440px;
  box-sizing: border-box;

  .big-title {
    font-weight: 500;
    font-size: 40px;
    line-height: 140%;
    text-align: left;
    color: ${PALLETE.black};
  }

  .term-text {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: justify;
    color: ${PALLETE.inputGrey};
    max-width: 564px;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  padding: 120px 220px;
  box-sizing: border-box;
  width: 1440px;
  flex-direction: column;
  align-items: center;

  .big-title {
    font-weight: 500;
    font-size: 40px;
    color: ${PALLETE.black};
    margin-bottom: 20px;
  }

  .subtitle {
    font-weight: 400;
    font-size: 20px;
    color: ${PALLETE.black};
    margin-bottom: 60px;
  }

  .cards-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }
`;
