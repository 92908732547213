import styled from "styled-components";
import { BREAKPOINTS } from "../../configs/configs";

export const Banner = styled.img`
	width: 153px;
	@media (min-width: ${BREAKPOINTS.DEFAULT}px) {
		width: 190px;
	}
`;
export const Header = styled.div`
	position: ${(props) => (props.fixed ? "fixed" : "relative")};
	background: #fdfdfd;
	z-index:11;
	@media (min-width: ${BREAKPOINTS.DEFAULT}px) {
		text-align: center;
		width: 100vw;
	}
`;

export const Container = styled.div`
	display: flex;
	justify-content: ${(props) => {
		if (props.isScrolling) return 'flex-start'
		return 'space-between'
	}};
	align-items: center;
	background: #fdfdfd;
	z-index: 11;
	height: ${(props) => {
		if (!props.fixed || !props.scroll) return 90;
		const MIN_HEIGHT = 60;
		return Math.max(90 - props.scroll, MIN_HEIGHT);
	}}px;
	@media (min-width: ${BREAKPOINTS.DEFAULT}px) {
		box-sizing: border-box;
		width: 1444px;
		padding: 0 220px 0 220px;
	}
`;

export const Button = styled.a`
	@import url("https://fonts.googleapis.com/css2?family=Inter&family=Roboto:wght@300;500;700&display=swap");
	background: ${(props) => {
		if (props.color1 && props.color2) return `linear-gradient(180deg, ${props.color1} 0%,  ${props.color2} 100%);` 
		return '#070707'
	}};
	border-radius: 5px;
	color: #ffffff;
	/* filter: drop-shadow(1px 1px 1px #0ad65c); */
	font-family: "Inter", sans-serif;
	font-style: normal;
	font-size: 12px;
	font-weight: 700;
	height: 14px;
	letter-spacing: 0.011em;
	text-align: center;
	width: 88px;
	padding: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	&:hover {
		cursor: pointer;
	}

	@media (min-width: 450px) {
		width: 226px;
		height: 36px;
		padding: 0;
	}
`;

export const mobile = {
	Header: styled.div`
		position: fixed;
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		height: 59px;
		background-color: ${(props) => {
      if (props.backgroundColor) return props.backgroundColor
      return '#fdfdfd'
    }};
		padding: 0px 30px 15px 31px;
		box-sizing: border-box;
		width: 100%;
		z-index: 11;
	`,
	Button: styled.a`
		@import url("https://fonts.googleapis.com/css2?family=Inter&family=Roboto:wght@300;500;700&display=swap");
		background: #070707;
    background: ${(props) => {
      if (props.backgroundColor !== '#fdfdfd') return '#fdfdfd'
      return `linear-gradient(180deg, ${props.color1} 0%, ${props.color2} 100%);`
    }};
		border-radius: 5px;
    color: ${(props) => {
      if (props.backgroundColor !== '#fdfdfd') return props.backgroundColor
      return `#ffffff`
    }};
		/* color: #ffffff; */
    filter: ${(props) => {
      if (props.backgroundColor !== '#fdfdfd') return 'none'
      return 'drop-shadow(1px 1px 1px #0ad65c);'
    }};
		/* filter: drop-shadow(1px 1px 1px #0ad65c); */
		font-family: "Inter", sans-serif;
		font-style: normal;
		font-size: 12px;
		font-weight: 400;
		height: 14px;
		letter-spacing: 0.011em;
		text-align: center;
		width: 88px;
		padding: 5px;
		display: flex;
		align-items: center;
		justify-content: center;
	`,
	Banner: styled.img`
		width: 153px;
	`,
};

export const Title = styled.h2`
	font-weight: 400;
	font-size: 25px;
	margin-left: 12px;
	span {
		font-weight: 600;
	}
`