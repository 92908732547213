import styled from "styled-components";
import { PALLETE } from "../../../configs/configs";

export const Row = styled.div`
  margin-top: 20px;
  text-align: center;
  width: 270px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #070707;
  mix-blend-mode: normal;
  opacity: 0.8;

  span {
    color: ${PALLETE.main};
  }
`

export const Loading = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${PALLETE.main};
  width: 100vw;
  height: 100vh;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 130px;
    height: 130px;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #070707;
    margin-bottom: 5px;
  }
`