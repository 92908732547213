import React, { useEffect, useState } from 'react'
import { Header, IconContainer, HeaderTitle, Wrapper, Container } from '../style'
import icons from '../../../assets/icons'
import { useNavigate } from 'react-router-dom'

export default function RegisterTemplate({ backTo = "/", title = 'Criar conta', children }) {
    const [down, setDown] = useState(false)
    useEffect(() => {
        document.getElementsByClassName('input-text-register')[0].addEventListener('focusin', () => setDown(true))
        document.getElementsByClassName('input-text-register')[0].addEventListener('focusout', () => setDown(false))
    }, [])
    const navigate = useNavigate()
    return (
        <Wrapper>
            <Header down={down}>
                <IconContainer onClick={() =>  (navigate(-1))}>
                    <img src={icons.arrowLeftSolid} alt="seta voltar" style={{ height: 15 }} />
                    <HeaderTitle>{title}</HeaderTitle>
                    <div />
                </IconContainer>
            </Header>
            <Container>
                {children}
            </Container>
        </Wrapper>
    )
}