import React, { useCallback, useRef, useEffect } from 'react';
import Chart from 'chart.js/auto';
import Selector from './selector';
import Resume from './resume';
import utils from '../../helper/utils';
const { choose } = utils.responsive;

export default function C({ yFormat, datasets = [], value = 100, delimiter }) {
  const canvas = useRef();
  const chart = useRef();

  const stylesheet = {
    bigChartContainer: {
      width: window.innerWidth > 450 ? 850 : '100%',
      backgroundColor: window.innerWidth > 450 ? '#f5f5f5' : 'transparent',
      padding: window.innerWidth > 450 ? 33 : 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      boxSizing: 'border-box',
    },
    inputsRow: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    inputsColumn: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    inputTitle: {
      fontSize: utils.responsive.ajustFont(10),
      fontWeight: '400',
      marginTop: 0,
      marginBottom: 12,
    }
  }

  useEffect(() => {
    if (yFormat && typeof yFormat !== 'string') throw new Error('Invalid yFormat format');
 
    const config = {
      type: 'line',
      data: { datasets: recalculate().filter((_, i) => i <= 1) },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            grid: {
              padding: 20,
              display: false,
            },
            ticks: {
              padding: 0,
            }
          },
          y: {
            min: 0,
            suggestedMax: 30,
            ticks: {
              padding: 0,
              callback(value) {
                if (yFormat) return yFormat.replace(/{value}/gi, value);
                return value;
              },
            },
          },
        },
        elements: {
          point: {
            pointStyle: false,
          },
        },
      },
    };

    chart.current = new Chart(canvas.current, config);
    canvas.width = 5000;
    return () => {
      chart.current.destroy();
    };
  }, [yFormat, datasets, delimiter]);

  const updateChart = useCallback((list) => {
    if (!chart.current) return;
    chart.current.config.data.datasets = list;
    setTimeout(() => {
      chart.current.update();
    }, 100);
  }, []);

  const recalculate = useCallback(() => {
    if (!delimiter) return datasets;
    return datasets.map((dataset) => {
      let { data } = dataset;
      const fdata = Object.entries(data);
      const rate = Math.ceil(fdata.length / delimiter);
      data = fdata.filter((_, i) => i%rate === 0);
      data.pop();
      data.push(fdata.pop());
      return {
        ...dataset,
        data,
      }
    });
  }, [datasets, delimiter]);

  return datasets.length > 0 ? (
    <div style={stylesheet.bigChartContainer}>
      <div style={{ width: '100%', height: 300 }}>
        <canvas ref={canvas}></canvas>
      </div>
      <Selector items={recalculate()} onUpdate={updateChart} />
      {choose(<></>)(<Resume datasets={datasets} value={value} />)}
    </div>
  ) : (
    <></>
  );
}
