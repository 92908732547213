import icons from "../../assets/icons";
import React, { useState } from "react";
import {
	FooterContainer,
	FooterSection,
	FooterTitle,
	FooterTxt,
	GreyLine,
	GreyText,
	IconsButton,
	SmallFooterText,
	Row,
	FooterWrapper,
	PartnerIcon,
} from "./style";
import utils from "../../helper/utils";
import AluguelVerde from "../../assets/AluguelVerde.svg";
import banner from "../../assets/banner.svg";

const { responsive } = utils;

export default function Footer() {
	const [green, setGreen] = useState('')
	return (
		<FooterWrapper>
			<div style={{ display: "inline-block" }}>
				<FooterContainer>
					<FooterSection>
						<div style={{ flexDirection: "column", alignSelf: "flex-start" }}>
							<FooterTitle>Atendimento</FooterTitle>
							<FooterTxt marginBottom>
								Rua Visconde do rio branco, 1630
								<br />
								Centro • Curitiba • PR
							</FooterTxt>
							<FooterTxt style={{ marginBottom: 30 }}>
								9h às 21hs - segunda à sexta-feira -<br />
								exceto feriado.
							</FooterTxt>
							<FooterTitle>E-mail</FooterTitle>
							<FooterTxt>{`contato@sapiensbank.com.br`}</FooterTxt>
							<FooterTitle marginTopBaixe>Baixe o app</FooterTitle>
							<Row>
								<IconsButton
									type="button"
									onClick={() => {
										window.open(
											"https://apps.apple.com/br/app/sapiensbank/id1572548699",
										);
									}}
								>
									<img
										className="store"
										src={icons.downloadAppStore}
										alt="card icon"
									/>
								</IconsButton>
								<IconsButton
									type="button"
									onClick={() => {
										window.open(
											"https://play.google.com/store/apps/details?id=com.sapiensbank.sapiensbank",
										);
									}}
								>
									<img
										className="store"
										src={icons.downloadGoogleStore}
										alt="card icon"
									/>
								</IconsButton>
							</Row>
						</div>
						{responsive.choose(<GreyLine />)()}
						<div style={{ flexDirection: "column", alignSelf: "flex-start" }}>
							<FooterTitle>Institucional</FooterTitle>
							<FooterTxt>Quem somos</FooterTxt>
							<FooterTxt>
								<a
									href="https://sapiensbank.com.br/assets/docs/terms.pdf"
									target="_blank"
									rel="noreferrer"
								>
									Termos de uso
								</a>
							</FooterTxt>
							<FooterTxt>
								<a
									href="https://sapiensbank.com.br/assets/docs/policy.pdf"
									target="_blank"
									rel="noreferrer"
								>
									Política de privacidade
								</a>
							</FooterTxt>
						</div>
						<div style={{ flexDirection: "column", alignSelf: "flex-start" }}>
							<FooterTitle marginTopMenos>Produtos</FooterTitle>
							<FooterTxt>
								Investimentos
							</FooterTxt>
							<FooterTxt>
								Tokenização de ativos
							</FooterTxt>
							<FooterTxt>
								Empréstimos
							</FooterTxt>
							<FooterTxt>
								Garantia locatícia
							</FooterTxt>
							<FooterTxt>
								Neutralização ecológica
							</FooterTxt>
						</div>
						<div
							style={{
								flexDirection: "column",
								alignSelf: "flex-start",
								width: window.innerWidth > 450 ? "auto" : "100%",
							}}
						>
							{responsive.choose(
								<FooterTitle
									marginTopMenos
									style={{
										textAlign: window.innerWidth > 450 ? "right" : "left",
									}}
								>
									Quer construir um futuro
									<br />
									próspero com a gente?
								</FooterTitle>,
							)(
								<FooterTitle
									style={{
										textAlign: window.innerWidth > 450 ? "right" : "left",
									}}
								>{`Quer construir um futuro próspero\ncom a gente?`}</FooterTitle>,
							)}
							<FooterTxt
								style={{
									textAlign: window.innerWidth > 450 ? "right" : "left",
								}}
							>{`Envie seu currículo`}</FooterTxt>
							<FooterTxt
								style={{
									textAlign: window.innerWidth > 450 ? "right" : "left",
								}}
							>{`time@sapiensbank.com.br`}</FooterTxt>
							<Row
								style={{
									marginLeft: 0,
									marginTop: window.innerWidth > 450 ? 30 : 60,
									marginBottom: window.innerWidth > 450 ? 0 : 30,
									justifyContent: "center",
								}}
							>
								<IconsButton
									type="button"
									onClick={() => {
										window.open("https://www.instagram.com/sapiensbank/");
									}}
								>
									<img
										onMouseEnter={() => setGreen('insta')}
										onMouseLeave={() => setGreen('')}
										className="socialMedia"
										src={green !== 'insta' ? icons.instagram : icons.instaGreen}
										alt="card icon"
									/>
								</IconsButton>
								<IconsButton type="button" onClick={() => {}}>
									<img
										onMouseEnter={() => setGreen('tiktok')}
										onMouseLeave={() => setGreen('')}
										className="socialMedia"
										src={green !== 'tiktok' ? icons.tiktok : icons.tiktokGreen}
										alt="card icon"
									/>
								</IconsButton>
								<IconsButton type="button" onClick={() => {}}>
									<img
										onMouseEnter={() => setGreen('youtube')}
										onMouseLeave={() => setGreen('')}
										className="socialMedia"
										src={green !== 'youtube'? icons.youtube : icons.youtubeGreen}
										alt="card icon"
									/>
								</IconsButton>
								<IconsButton
									style={{ marginRight: 0 }}
									onMouseEnter={() =>setGreen('linkedin')}
									onMouseLeave={() => setGreen('')}
									type="button"
									onClick={() => {
										window.open("https://br.linkedin.com/company/sapiensbank");
									}}
								>
									<img
										className="socialMedia"
										src={green !== 'linkedin' ? icons.linkedin : icons.linkedGreen}
										alt="card icon"
									/>
								</IconsButton>
							</Row>
						</div>
					</FooterSection>
					<GreyLine />
					<FooterSection style={{ justifyContent: "space-around" }}>
						<IconsButton type="button" onClick={() => {}}>
							<img
								className="bigLogos"
								src={banner}
								alt="card icon"
								width={160}
							/>
						</IconsButton>
						<IconsButton type="button" onClick={() => {}}>
							<img
								className="bigLogos"
								src={AluguelVerde}
								alt="card icon"
								width={160}
							/>
						</IconsButton>
						{/* <IconsButton type="button" onClick={() => {}}>
							<img
								className="bigLogos"
								src={theEconomy2}
								alt="card icon"
								width={160}
							/>
						</IconsButton> */}
					</FooterSection>
					<GreyLine />
					<GreyText style={{ fontWeight: "700", alignSelf: "flex-start" }}>
						Parceiros:
					</GreyText>
					<FooterSection
						style={{
							alignItems: window.innerWidth > 450 ? "flex-end" : "center",
						}}
					>
						<PartnerIcon type="button" onClick={() => {}}>
							<img
								// className="mediumLogos"
								src={icons.stoneLogo}
								alt="card icon"
							/>
						</PartnerIcon>
						<PartnerIcon type="button" onClick={() => {}}>
							<img
								// className="mediumLogos"
								src={icons.docusignLogo}
								alt="card icon"
							/>
						</PartnerIcon>
{/* 						<PartnerIcon
							type="button"
							onClick={() => {}}
							style={{ marginBottom: 25 }}
						>
							<img
								// className="mediumLogos"
								src={icons.fiduciaLogo}
								alt="card icon"
							/>
						</PartnerIcon> */}
						<PartnerIcon type="button" onClick={() => {}}>
							<img
								// className="mediumLogos"
								src={icons.asaasLogo}
								alt="card icon"
							/>
						</PartnerIcon>
						<PartnerIcon
							type="button"
							onClick={() => {}}
							style={{ marginBottom: 25 }}
						>
							<img
								// className="mediumLogos"
								src={icons.serasaLogo}
								alt="card icon"
							/>
						</PartnerIcon>
					</FooterSection>
					<GreyLine />
					<FooterSection>
						<SmallFooterText justify>
							Somos especialistas em tokenização de ativos alternativos. Por
							meio de tecnologia proprietária, originamos, estruturamos e
							distribuímos ativos tokenizados de forma direta. Não somos e não
							atuamos como instituição financeira ou administrador de carteira
							de valores mobiliários. Não atuamos como advogado de nossos
							clientes. Os websites (“Site”),{" "}
							<a href="https://www.sapiensbank.com.br/">
								www.sapiensbank.com.br
							</a>{" "}
							e <a href="https://www.aluguelverde.com/">www.aluguelverde.com</a>
							, é operado por Sapiensbank Pagamentos S.A (“Sapiensbank”),
							empresa especializada na originação, estruturação e distribuição
							de ativos tokenizados que não se enquadrem como valores
							mobiliários, nos termos da Lei nº 6.385/76. Leia sempre com
							atenção as informações essenciais sobre as operações, em especial
							a seção de alertas sobre risco.
						</SmallFooterText>
					</FooterSection>
					<GreyLine />
					<FooterSection style={{ justifyContent: "center" }}>
						{responsive.choose(
							<SmallFooterText style={{ marginBottom: 0 }}>
								Sapiensbank Pagamentos S/A © 2023.
								<br />
								Todos os direitos reservados.
								<br />
								CNPJ: nº 40.357.018/0001-60
							</SmallFooterText>,
						)(
							<SmallFooterText>
								Sapiensbank Pagamentos S/A © 2021. Todos os direitos reservados.
								CNPJ: nº 40.357.018/0001-60
								<br />
								Rua Visconde do rio branco, 1630 • Centro • Curitiba • PR
							</SmallFooterText>,
						)}
					</FooterSection>
				</FooterContainer>
			</div>
		</FooterWrapper>
	);
}
