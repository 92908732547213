import styled from 'styled-components';
import utils from '../../../helper/utils';
import { PALLETE } from '../../../configs/configs';

const { responsive, dataTypes } = utils;

const size = responsive.ajustSize(150, 125);

const gap = responsive.ajustSize(10, 0);

export const BigContainer = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Inter&family=Roboto:wght@200;300;500;700&display=swap');
  text-align: center;
  font-family: 'Inter', sans-serif;
  scroll-snap-type: proximity;
  padding: 114px ${responsive.ajustFont(30)}px 0 ${responsive.ajustFont(30)}px;
  max-width: 100%;
  background-color: ${(props) => {
    if (props.color) return props.color
    return 'transparent'
  }};
  .desktop-grid {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .align-left {
      width: 100%;
      margin-left: 94px;
    }
  }

  .column {
    display: flex;
    flex-direction: column;
  }

  .row {
    display: flex;
    flex-direction: row;
  }

  .graph-container {
    position: relative;
  }

  .rate-position {
    position: absolute;
    top: 100px;
    left: 130px;
  }

  .rate {
    font-weight: 300;
    font-size: ${responsive.ajustFont(10)}px;
    text-align: center;
    color: ${PALLETE.black};
    margin: 0;
  }

  .span-rate {
    font-weight: 700;
    font-size: ${responsive.ajustFont(18)}px;
    text-align: center;
    margin: 0;
    color: ${(props) => props.color1};
  }

  .black-title {
    font-weight: 700;
    font-size: ${responsive.ajustFont(20)}px;
    color: ${PALLETE.black};
    margin: 60px 0 24px 0;
    text-align: center;
  }

  .bigger-black-title {
    font-weight: 500;
    font-size: ${responsive.ajustFont(25)}px;
    color: ${PALLETE.black};
    margin: 70px 0 40px 0;
    text-align: left;
    line-height: 150%;
  }

  .condition-text {
    font-weight: 400;
    font-size: 12px;
    color: ${PALLETE.inputGrey};
    text-align: justify;
    margin: 0 0 60px 0;
  }

  .normal-black-text {
    font-weight: 400;
    font-size: ${responsive.ajustFont(14)}px;
    color: ${PALLETE.black};
    margin: 0 0 30px 0;
    text-align: center;
  }
`;

export const BigTitle = styled.h1`
  text-align: left;
  font-weight: ${(props) => {
    if (props.regular) return '400';
    return '500';
  }};
  font-size: 25px;
  color: ${(props) => {
    if (props.color) return props.color;
    return 'black';
  }};
  margin: 0;
`;

export const Divisor = styled.hr`
  width: 100%;
  margin: 30px 0;
  /* TODO - Suavizar a expessura pra 0.6px #1298 */
  border: 1px solid #f5f5f5;
  opacity: 0.6;
`;

export const InfoNot = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .colored-check {
    ${(props) => {
      if (props.color) return dataTypes.color.filterGenerator(props.color);
      return 'filter: none';
    }};
  }


  .value {
    display: flex;
    font-weight: 400;
    font-size: 22px;
    margin-bottom: 6px;
    margin-top: 0;
    color: ${PALLETE.black};
  }

  .legend {
    font-weight: 400;
    font-size: 18px;
    margin: 0;
    color: ${PALLETE.inputGrey};
  }
`;

export const InfoCard = styled.div`
  box-sizing: border-box;
  height: ${size.width * 0.84}px;
  width: ${size.width}px;
  background-color: ${(props) => utils.dataTypes.color.hexToRgbAOpacity(props.color, 0.1)};
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  .colored-check {
    ${(props) => {
      if (props.color) return dataTypes.color.filterGenerator(props.color);
      return 'filter: none';
    }};
  }

  .value {
    color: ${(props) => props.color};
    font-weight: 700;
    font-size: ${responsive.ajustFont(12)}px;
    margin: 0;
  }

  .legend {
    color: ${PALLETE.black};
    font-weight: 400;
    font-size: ${responsive.ajustFont(14)}px;
    margin: 0;
    text-align: left;
  }
`;

export const FullWrapper = styled.div`
  width: 100%;
  text-align: center;
  background-color: ${(props) => {
    if (props.color) return props.color;
    return 'transparent';
  }};
`;

export const CardWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${gap.width}px;
  justify-content: center;
  /* align-items: center; */
`;
