import styled from 'styled-components';
import { PALLETE } from '../../configs/configs';
import utils from '../../helper/utils'

const size = utils.responsive.ajustSize(180, 40);

export const Button = styled.div`
  width: ${(props) => (`${props.width}px`)};
  height: 48px;
  box-sizing: border-box;
  padding: 15px;
  background: #F9F9F9;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  cursor: pointer;

  .button-label {
    font-weight: 400;
    font-size: 16px;
    margin: 0;
    color: ${PALLETE.inputGrey} !important;

    @media (max-width: 450px) {
      font-size: ${utils.responsive.ajustFont(12)}px;
    }
  }

  .arrow {
    ${utils.dataTypes.color.colorConverter(PALLETE.base).filter}
  }

  @media (max-width: 450px) {
			width: ${size.width}px;
      /* height: ${size.width * 0.222}px; */
      height: ${size.width * 0.15}px;
      padding: 13px;
		}
`;
