import styled from 'styled-components';

import { IMaskInput } from 'react-imask';
import { PALLETE } from '../../../configs/configs';
import utils from '../../../helper/utils';

const { responsive } = utils;

export const Code = styled(IMaskInput)`
  border: none;
  outline: none;
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : PALLETE.white)};
  border-radius: 0;
  font-weight: 700;
  font-size: ${(props) => {
    if (props.fontSize) return `${responsive.ajustFont(props.fontSize)}px`;
    return `${responsive.ajustFont(30)}px`;
  }};
  color: ${PALLETE.inputGrey};
  caret-color: ${PALLETE.main};
  min-height: 40px;
  padding: 10px 0;
  text-align: center;
  margin-right: ${(props) => props.filled ? `-50px` : 0};
  letter-spacing: 50px;
`;

export const Password = styled.input`
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : PALLETE.white)};
  border-radius: 25px;
  font-weight: 700;
  font-size: ${(props) => {
    if (props.fontSize) return `${responsive.ajustFont(props.fontSize)}px`;
    return `${responsive.ajustFont(30)}px`;
  }};
  color: ${PALLETE.inputGrey};
  min-height: 40px;
  width: 100%;
  text-align: center;
  padding: 10px 0;
  margin-right: ${(props) => props.value ? -30 : 0}px;
  letter-spacing: 30px;
`;

export const Text = styled.input`
  border: none;
  outline: none;
  caret-color: ${PALLETE.main};
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : 'transparent')};
  font-weight: 700;
  font-size: ${(props) => (props.fontSize) ? `${responsive.ajustFont(props.fontSize)}px` : `${responsive.ajustFont(30)}px`};
  color: ${PALLETE.inputGrey};
  min-height: 40px;
  width: 100%;
  text-align: center;
  padding: 10px 0;
`;

export const MaskedInput = styled(IMaskInput)`
  border: none;
  outline: none;
  caret-color: ${PALLETE.main};
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : 'transparent')};
  font-weight: 700;
  font-size: ${(props) => (props.fontSize) ? `${responsive.ajustFont(props.fontSize)}px` : `${responsive.ajustFont(30)}px`};
  color: ${PALLETE.inputGrey};
  min-height: 40px;
  width: 100%;
  text-align: center;
  padding: 10px 0;
`