import * as Style from './style.js';
import womanWithGlasses from '../../../assets/womanWithGlasses.png';
import aluguelverdetext from '../../../assets/aluguelverdetext.svg';
import image from '../../../assets/images/couple-mountains.svg';
import { PALLETE } from '../../../configs/configs.js';
import { ArrowButton, PriceSlider, TextToggle, ImageCover } from '../../../components/index.js';
import contentFactory from './contentFactory.json';
import { Outlined } from '../../../assets/newIcons.js';
import utils from '../../../helper/utils.js';

const AluVerde = ({ setSelectedAV, selectedAV, avRef }) => {
  const av = selectedAV === 'Para você';

  return (
    <>
      {utils.responsive.choose(
        <>
          <Style.BorderedImageContent>
            <div className="content-container" style={{ paddingTop: 0, paddingBottom: 32}}>
              <img src={aluguelverdetext} alt="sapiensinvest" width={129} />

              <div style={{ marginTop: 32 }}>
                <Style.BigTitle>
                  Parcele seu aluguel sem
                  <br />
                  precisar de cartão de crédito
                </Style.BigTitle>
              </div>

              {/* <ArrowButton label="Fale com um consultor" /> */}
            </div>

            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <img src={image} alt="man" />
            </div>
          </Style.BorderedImageContent>

          <Style.WhiteCover>
            <Style.FithContent style={{ paddingBottom: 50}}>
{/*               <Style.BigTitle color={PALLETE.black} style={{ marginBottom: 24 }}>
                Vantagens para
                <br />
                Inquilinos e imobiliária
              </Style.BigTitle> */}

              <TextToggle
                setSelected={setSelectedAV}
                first="Para você"
                second="Para a Imobiliária"
                toggleWidth={utils.responsive.choose(130)(180)}
                />
              <p className="subtitle-fith">
                {av ? 'Facilidade para pagar seu aluguel' : 'Custo zero para imobiliárias'}
              </p>
              {contentFactory[av ? 6 : 7].map((row, i) => {
                return (
                  <div className="row-fith-content" key={`fith-row-${i}`}>
                    <div className="icon">
                      <img src={Outlined(row.icon)} alt="row-icon" style={row.iconStyleMob} />
                    </div>
                    <p className="fith-content">
                      {row.text} <span>{row.span}</span>
                    </p>
                  </div>
                );
              })}

            </Style.FithContent>
          </Style.WhiteCover>



          {/*           <Style.WhiteCover color="#F9F9F9">
            <Style.FithContent center>
              {av ? (
                <h4 className="slider-title">Qual o valor aproximado do aluguel?</h4>
              ) : window.innerWidth < 390 ? (
                <p className="slider-title">
                  Qual o valor aproximado da carteira
                  <br />
                  de imóveis alugados?
                </p>
              ) : (
                <h4 className="slider-title">
                  Qual o valor aproximado da carteira de imóveis alugados?
                </h4>
              )}
              <PriceSlider
                steps={contentFactory[av ? 8 : 9]}
                defaultAmount={av ? 100 : 1000}
                getThousands={av}
              />
              <p className="slider-tiny-grey"></p>
              <ArrowButton label={av ? 'Solicitar' : 'Continuar'} />
            </Style.FithContent>
          </Style.WhiteCover> */}
        </>
      )(
        <Style.WhiteCover ref={avRef} >
          <div className="inline">
            <Style.FithContent >
              <div className="column-content">
                <div className="icon-container" style={{ textAlign: 'left', marginBottom: 24}}>
                  <img alt="sapiens credit" src={aluguelverdetext} />
                </div>
                <Style.BigTitle color={PALLETE.black} style={{ marginBottom: 50 }}>
                  Parcele seu aluguel sem
                  <br />
                  precisar de cartão de crédito
                </Style.BigTitle>
                

                <TextToggle
                  setSelected={setSelectedAV}
                  first="Para você"
                  second="Para a Imobiliária"
                  toggleWidth={180}
                  />
                <p className="subtitle-fith">
                  {av ? 'Facilidade para pagar seu aluguel' : 'Custo zero para imobiliárias'}
                </p>

                <div style={{ height: 136 }}>
                  {contentFactory[av ? 6 : 7].map((row, i) => {
                    return (
                      <div className="row-fith-content" key={`fith-row-${i}`}>
                        <div className="icon">
                          <img src={Outlined(row.icon)} alt="row-icon" style={row.iconStyle} />
                        </div>
                        <p className="fith-content">
                          {row.text} <span>{row.span}</span>
                        </p>
                      </div>
                    );
                  })}
                </div>

              </div>

              <img src={image} alt="man" style={{ height: 370, marginTop: 81 }} />
            </Style.FithContent>
          </div>
        </Style.WhiteCover>
      )}
    </>
  );
};

export default AluVerde;
