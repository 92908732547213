import React from "react";
import { BigContainer, BigTitle, FullWrapper } from "./style";
import { ColumnTable, SmallSquareButton } from "../../../components";

const WhiteTable = ({ data, createAccount }) => {
	return (
			<FullWrapper color={"transparent"}>
				<div style={{ display: "inline-block" }}>
					<BigContainer>
						<BigTitle
							style={{ marginTop: 120, marginBottom: 6, width: '70%' }}
						>
							{data.fullName} - {new Date(data.createdAt).getFullYear()}
						</BigTitle>
						<div className="row">
							<BigTitle >
								{data.tick} -
							</BigTitle>
							<img src={data.icon} style={{ marginLeft: 12 }} alt="token icon" />
						</div>
						<div
							className="row"
							style={{
								marginTop: 20,
								marginBottom: 94,
							}}
						>
							<SmallSquareButton
								color1={data.color1}
								color2={data.color2}
								label="Lâmina técnica"
								marginRight
								medium
							/>
							<SmallSquareButton
								color1={data.color1}
								color2={data.color2}
								label="Contrato"
                smallerLabel={'(leitura)'}
								marginRight
								medium
							/>
							<SmallSquareButton
								color1={data.color1}
								color2={data.color2}
								label="Investir"
								marginRight
								medium
                onClick={createAccount}
							/>
						</div>
            <ColumnTable arr1={data.table3Half1} arr2={data.table3Half2} color={data.color1}/>
            <div
						className="row"
						style={{
							marginTop: 70,
							marginBottom: 120,
							justifyContent: "center",
						}}
					>
						<SmallSquareButton
							color1={data.color1}
							color2={data.color2}
							label={"Investir"}
							marginRight
              selected
              onClick={createAccount}
						/>
            </div>
					</BigContainer>
				</div>
			</FullWrapper>
	);
};

export default WhiteTable;
