import { useEffect } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import RegisterProvider from '../context/register.provider'
import helper from '../helper/utils';

import Demo from '../pages/demo'
import Home from '../pages/home'
import CPF from '../pages/register/CPF'
import Phone from '../pages/register/Phone'
import Email from '../pages/register/Email'
import JustWait from '../pages/register/JustWait'
import Name from '../pages/register/Name'
import DetalhesInvest from '../pages/investiments';

const UserRoutes = () => {
  useEffect(() => {
    helper.analitics.maze(
      window,
      document,
      'https://snippet.maze.co/maze-universal-loader.js',
      'cf6f9289-b198-4bee-9d12-a3234c6ecac8',
    );

    helper.analitics.hotjar(
      window,
      document,
      'https://static.hotjar.com/c/hotjar-',
      '.js?sv=',
    );
  }, []);

  return (
    <RegisterProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/demo-sandbox" element={<Demo/>} />
          <Route path="/investment/:tokenid" element={<DetalhesInvest />} action={({ params }) => {}} />
          {/* Sessão de cadastro */}
          <Route path="/register" element={<CPF />} />
          <Route path="/register/name" element={<Name />} />
          <Route path="/register/phone" element={<Phone />} />
          <Route path="/register/email" element={<Email />} />
          <Route path="/register/justwait" element={<JustWait />} />
        </Routes>
      </BrowserRouter>
    </RegisterProvider>
  )
}

export default UserRoutes
