import React, { useState } from 'react';
import { useFetch } from '../../hooks';

import Chart from '../../components/Chart';
import Menu from '../../components/Chart/menu';
import * as STYLE from '../../components/Chart/style';


export default function Demo() {
  const [samples, setSamples] = useState([]);
  const api = useFetch();

  function onSelect(token) {
    const { tick } = token;
    api.fetch('get', {}, `web/simulate/${tick}`)
      .then(({ data }) => {
        console.log(data)
        setSamples(data);
      });
  }

  return (
    // <STYLE.Container>
    //   <Menu onSelect={onSelect} />
    //   <Chart
    //     yFormat="{value}%"
    //     datasets={samples}
    //   />
    // </STYLE.Container>
    <>
    </>
  )
}
