import styled from 'styled-components';
import { PALLETE } from '../../configs/configs';
import utils from '../../helper/utils'

const { responsive } = utils

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BigMenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  display: flex;
  margin: 60px 0 0 0;
  align-items: center;
  justify-content: space-around;
  width: 100%;
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const Dot = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 50px;
  background-color: ${(props) => {
    return props.backgroundColor;
  }};
  /* box-shadow: 0px 4px 4px 0px #00000040; */
  @media (max-width: 450px) {
    height: 6px;
    width: 6px;
  }
`;

export const MenuText = styled.p`
  margin: 0 0 0 10px;
  font-weight: 300;
  font-size: 14px;
  color: ${PALLETE.inputGrey};
  @media (max-width: 450px) {
    font-size: ${responsive.ajustFont(8)}px;
  }
`;

export const Percentage = styled.p`
  font-weight: 700;
  font-size: 10px;
  color: ${(props) => {
    return props.color;
  }};
`;

export const Svg = styled.svg`
  box-shadow: 0px 4px 10px 4px #00000040;
  border-radius: ${(props) => (props.radius ? props.radius * 2 : 0)}px;
`;
