import { useContext, useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

import utils from '../../../helper/utils'

import Context from '../../../context'
import { Input, Button, Modal } from '../../../components'
import RegisterTemplate from '../RegisterTemplate'
import * as Template from '../style'
import * as Style from './style'
import confirmGif from '../../../assets/confirm.gif'

import { useFetch } from '../../../hooks'

import ModalContent from './ModalContent'

export default function Phone() {
  const context = useContext(Context)
  const timer = useRef()
  const navigate = useNavigate()

  const apiRegister = useFetch('user/register/0')
  const apiGenCode = useFetch('user/register/1')
  const apiConfirm = useFetch('user/confirm-device')
  const apiChange = useFetch('user/change-device')

  const [disabled, setDisabled] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [number, setNumber] = useState();

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    }
  }, []);

  useEffect(() => {
    if (!accepted) return;
    timer.current = setTimeout(() => {
      navigate('/register/justwait')
    }, 2000);
  }, [accepted])

  useEffect(() => {
    if (number && number.length < 11) return;
    const n = utils.dataTypes.number.toPhone(number);
    context.data.cellphone = n.replace('+55 ', '');
  }, [number])

  const onChange = (value) => {
    value = utils.dataTypes.string.clear(value);
    setNumber(value);
    setDisabled(value.length < 11)
  }

  const handleRegisterUser = () => {
    context.data.register_origin = 1;

    apiRegister.fetch('post', context.data)
      .then(async (res) => {
        if (Array.isArray(res.errors)) {
          const [message, code] = res.errors;
          if (code !== 409) throw 'Error';
          return await apiChange.fetch('put', { cellphone: context.data.cellphone });
        }
        return await apiGenCode.fetch('post', {
          cpf: context.data.cpf,
          cellphone: context.data.cellphone,
        });
      })
      .then((res) => {
        setShowModal(true);
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleSubmit = (code) => {
    const body = { code, notificationToken: `web_${uuidv4()}` }

    apiConfirm.fetch('put', body)
      .then((res) => {
        if (Array.isArray(res.errors)) throw 'Error'
        setAccepted(true)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <>
      <RegisterTemplate  backTo={"/register/email"}>
        <Template.PageTitle>Digite seu número de telefone</Template.PageTitle>
        <Template.Margin />
        <Input type="phone" autofocus={!showModal} onChange={onChange} />
        <Template.Margin />
        <Button
          disabled={disabled}
          label="Continuar"
          rounded
          style={{ fontSize: 14, fontWeight: 700, width: '100%', boxSizing: 'border-box' }}
          onClick={handleRegisterUser}
          // onClick={() => setShowModal(true)}
        />
      <div
					style={{
						position: "fixed",
						height: 20,
						width: "100%",
						bottom: 0,
            zIndex: 150,
					}}
				>
     <Modal
        extended={showModal}
        onClickOut={setShowModal}
        size='bigger'
      >
        {showModal && <ModalContent
        extended={showModal}
          phone={number}
          onSubmit={handleSubmit}
        />}
      </Modal>
      </div>
      </RegisterTemplate>
      {
        accepted && (
          <Style.Loading>
            <p>Número<br/>confirmado!</p>
            <img src={confirmGif} alt="confirm" />
          </Style.Loading>
        )
      }
    </>
  )
};
