const name = (name) => {
    if (!name) return null;
    return typeof name === 'string' && /^[A-Za-zÁ-Úá-úÂ-Ûâ-û^~ ]*$/.test(name) && name.length > 3;
}

const email = (email) => {
    if (!email) return null;
    return /\S+@\S+\.\S+/.test(email);
}

const cpf = (cpf) => {
    if (!cpf) return null;
    if (typeof cpf === 'number') return null;
    cpf = cpf.replace(/[^\d]+/g, '');
    if (cpf === '') return false;
    const sortedCpf = [...cpf].sort();
    if (sortedCpf[0] === sortedCpf[10]) return false;
    // Valida 1o digito	
    let sum = 0;
    for (let i = 0; i < 9; i += 1) sum += parseInt(cpf.charAt(i)) * (10 - i);
    let rev = 11 - (sum % 11);
    if (rev === 10 || rev === 11) rev = 0;
    if (rev !== parseInt(cpf.charAt(9))) return false;
    // Valida 2o digito	
    sum = 0;
    for (let i = 0; i < 10; i++)	sum += parseInt(cpf.charAt(i)) * (11 - i);
    rev = 11 - (sum % 11);
    if (rev === 10 || rev === 11) rev = 0;
    return (rev === parseInt(cpf.charAt(10)));
}

const cnpj = (cnpj) => {
    cnpj = cnpj.replace(/[^\d]+/g, '');
    // verificando se tem a quantidade certa de caracter e se não tem todos caracteres iguais
    if (cnpj.length !== 14 || /^(\d)\1+$/.test(cnpj))
        return false;
    let t = cnpj.length - 2,
        d = cnpj.substring(t),
        d1 = parseInt(d.charAt(0)),
        d2 = parseInt(d.charAt(1)),
        calc = x => {
            let n = cnpj.substring(0, x),
                y = x - 7,
                s = 0,
                r = 0;
            for (let i = x; i >= 1; i--) {
                s += n.charAt(x - i) * y--;
                if (y < 2)
                    y = 9;
            }
            r = 11 - s % 11;
            return r > 9 ? 0 : r;
        }
    return calc(t) === d1 && calc(t + 1) === d2;
}

const document = (number) => {
    return number.length > 11 ? cnpj (number) : cpf (number);
}

const validators = {
    cpf,
    name,
    email,
    cnpj,
    document,
}

export default validators;
