import React, { useState, useEffect } from 'react';
import icons from '../../../assets/icons';

// Styles
import { Container, Box } from './style';

const Checkbox = ({
  boxStyle = {},
  checked = false,
  disabled,
  size = '20px',
  onChange = () => {},
}) => {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    onChange(isChecked);
  }, [isChecked]);

  useEffect(() => { 
    setIsChecked(checked)
  }, [checked])

  return (
      <Container
        onClick={() => {
          !disabled && setIsChecked(!isChecked);
        }}
      >
        <Box isChecked={isChecked} size={size} style={{...boxStyle}}>
          {isChecked && <img src={icons.checkWhite} alt="check"/>}
        </Box>
      </Container>
  );
};

export default Checkbox;