import styled from "styled-components";
import { PALLETE } from "../../../configs/configs";
import utils from "../../../helper/utils";

const { responsive } = utils;

const size = responsive.ajustSize(158, 125);

export const Card = styled.button`
	width: 209px;
	height: 174px;
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: space-between;
	/* justify-content: flex-start; */
	align-items: flex-start;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
	border-radius: 5px;
	background-color: ${(props) => {
		if (props.isGray) return "#F5F5F5";
		return "#fdfdfd";
	}};
	padding: 10px;
	box-sizing: border-box;
	border: none;
	/* margin-right: 15px; */
	margin-right: 0;
	opacity: ${(props) => {
		if (props.isGray) return '0.5'
		return '1'
	}};

	
	.bold {
		font-weight: 600;
	}

	.card-icon {
		height: 32px;
		width: auto !important;
		align-self: flex-start;
		@media (max-width: 450px) {
			height: ${responsive.ajustSize(null, 24).height}px;
		}
		/* @media (max-width: 380px) {
			height: 21px;
		} */
	}

	.full-row {
		width: 100%;
		justify-content: space-between;
	}

	.card-title {
		font-weight: 700;
		font-size: 15px;
		color: #070707;
		margin: 0 0 0 4px;
		text-align: left;
		@media (max-width: 450px) {
			font-size: ${responsive.ajustFont(10)}px;
		}
	}

	.money-text {
		font-weight: 700;
		font-size: 12px;
		color: #070707;
		margin: 0;
		text-align: left;
		@media (max-width: 450px) {
			font-size: ${responsive.ajustFont(9)}px;
			margin: 0;
		}
	}

	.tiny-text {
		font-weight: 300;
		font-size: 12px;
		color: #070707;
		margin: 0;
		text-align: left;
		@media (max-width: 450px) {
			font-size: ${responsive.ajustFont(8)}px;
		}
	}

	.variation {
		font-weight: 700;
		font-size: 16px;
		color: ${(props) => {
			if (props.down) return PALLETE.brightRed;
			return PALLETE.main;
		}};
		margin: 0 10px 0 0;
		@media (max-width: 450px) {
			font-size: ${responsive.ajustFont(10)}px;
		}
	}

	.profitability {
		font-weight: 700;
		font-size: 14px;
		color: ${(props) => {
			if (props.isGray) return "#070707";
			return "#FFFFFF";
		}};
		border-radius: 5px;
		margin: 0 0 0 5px;
		text-align: left;
		padding: 8px 8px;
		background-color: ${(props) => {
			if (props.isGray) return "rgba(7, 7, 7, 0.1)";
			if (props.color) return props.color;
			return "#03989E";
		}};
		@media (max-width: 450px) {
			padding: 4px 7px;
			font-size: 10px;
		}
	}

	.row {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.plusMargin {
		margin-top: 0px;
		@media (max-width: 450px) {
			margin-top: 0px;
		}
	}

	@media (max-width: 450px) {
		min-height: 125px;
		height: ${size.width * 0.84}px;
		width: ${size.width}px;
		padding: 10px 5px 10px 10px;
		margin: 0;
    box-sizing: border-box;
	}

	&:hover {
		/* cursor: pointer; */
		cursor: default;
	}
`;

export const InfoTags = styled.p`
	display: flex;
		border-radius: 3px;
		background-color: ${(props) => {
			if (props.color && !props.isGray && !props.complete) return utils.dataTypes.color.hexToRgbAOpacity(props.color, 0.1)
			if (props.isGray && !props.complete) return "rgba(7, 7, 7, 0.1)";
			if (props.complete) return 'rgba(245, 11, 151, 0.1)'
			return 'transparent'
		}};
		z-index: 4;
		padding: 6px 4px;
		font-size: 10px;
		margin: 0;
		color: ${(props) => {
			if (props.color && !props.isGray && !props.complete) return props.color;
			if (props.isGray && !props.complete) return "black";
			if (props.complete) return '#F50B97'
			return 'black'
		}};
		font-weight: 400;
		margin-left: ${(props) => {
			if (props.noMargin) return 0
			return '4px'
		}};
		@media (max-width: 450px) {
			padding: 4px 2px;
			font-size:${responsive.ajustFont(8)}px;;
		}
`