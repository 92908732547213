import React from 'react'
import MobModal from './mobile'

export default function Modal (props) {
    const { mobile = true, children, ...rest } = props

    if (mobile) {
        return (
            <MobModal {...rest} >
                {children}
            </MobModal>
        )
    } else {
        return;
    }

}