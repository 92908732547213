import styled from "styled-components";
import { PALLETE } from "../../../configs/configs";

export const ScrollContainer = styled.div`
	height: 100%;
	width: 100%;
	overflow: scroll;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 30px 30px 100px 30px;
	box-sizing: border-box;
	margin-bottom: 10px;
`;

const MAX_HEIGHT = 100;

export const WhiteTitle = styled.p`
	font-weight: 700;
	font-size: 18px;
	color: ${PALLETE.white};
	margin-bottom: 20px;
`;

export const CloseIcon = styled.div`
	padding: 30px 30px 0 30px;
	position: absolute;
	right: 0;
	top: 0;
`;
export const Row = styled.div`
	display: flex;
	flex-direction: row;
	margin: 0;
	justify-content: space-between;
	align-items: center;
	width: 100%;
`;
export const ColorTag = styled.div`
	padding: 3px 8px;
	border-radius: 100px;
	overflow: hidden;
	background-color: ${(props) => {
		if (props.color) return props.color;
		return PALLETE.disabled;
	}};
`;
export const SimpleText = styled.p`
	font-weight: ${(props) => {
		if (props.fontWeight) return props.fontWeight;
		return "400";
	}};
	font-size: ${(props) => {
		if (props.fontSize) return `${props.fontSize}px`;
		return `10px`;
	}};
	color: ${(props) => {
		if (props.color) return props.color;
		return PALLETE.black;
	}};
	margin: ${(props) => {
		if (props.margin) return props.margin;
		return "0";
	}};

	/* @media (max-width: 380px) {
		font-size: ${(props) => {
			if (props.fontSize) return `${props.fontSize}px`;
			return `9px`;
		}};
	} */
`;
export const Line = styled.div`
	width: 100%;
	height: 0;
	border: 0.5px solid ${PALLETE.lineGrey};
`;
export const VariationTag = styled.div`
	border-radius: 5px;
	background-color: ${(props) => {
		if (props.color) return props.color;
		return PALLETE.main;
	}};
	opacity: 0.1;
	overflow: hidden;
	flex-direction: row;
	padding: 5px;
`;
export const TokenVariation = styled.p`
	font-weight: 700;
	font-size: 10px;
	color: ${(props) => {
		if (props.down) return PALLETE.brightRed;
		return PALLETE.main;
	}};
	margin: 0 0 0 5px;
`;
export const ChartText = styled.p`
	color: ${(props) => props.color || "black"};
	font-style: normal;
	width: 100%;
	font-weight: 700;
	font-size: 18px;
	line-height: 20px;
	text-align: center;
	position: absolute;
	top: ${(props) => {
		if (props.history) return "25%";
		return "25%";
	}};
`;
export const HeaderModalContainer = styled.div`
	height: ${(props) => {
		const MIN = props.scrollMinHeight || 75;
		const SCROLL = Math.max(props.scrollTo, -MIN);
		return props.forceMinimize
			? MIN
			: Math.min(MAX_HEIGHT, Math.max(MIN, MAX_HEIGHT - SCROLL));
	}}px;
	position: relative;
	width: 100;
	overflow: hidden;
`;

export const CoverImage = styled.img`
	position: absolute;
	width: 100%;
	object-fit: cover;
`;
export const HeaderTitleContainer = styled.div`
	display: flex;
	height: 100%;
	width: 100%;
	background-color: #00000050;
	padding-left: 30px;
	align-items: flex-end;
	z-index: 12;
	position: absolute;
	box-sizing: border-box;
`;
