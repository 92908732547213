import styled from 'styled-components'
import { BREAKPOINTS, PALLETE } from '../../configs/configs';
import slider from './slider.svg'
import utils from '../../helper/utils';

export const SliderContainer = styled.div`
	width: 100%;
	box-sizing: border-box;
	padding: 0;

	@media (min-width: ${BREAKPOINTS.DEFAULT}px) {
		/* padding: 0 40px; */
		/* max-width: 592px; */
	}

	strong {
		font-style: normal;
		font-weight: 400;
		font-size: 50px;
		line-height: 48px;
		text-align: center;
		color: #070707;

		small {
			font-size: 12px;
		}
	}

	input[type="range"] {
		-webkit-appearance: none; /* Override default CSS styles */
		-webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
		appearance: none;
		/* background: #d3d3d3; /* Grey background */
		background: ${(props) =>
			`linear-gradient(to right, ${PALLETE.base}, ${PALLETE.base} ${props.rate}%, #d3d3d3 ${props.rate}%, #d3d3d3 100%)`};
		border-radius: 4px;
		height: 4px; /* Specified height */
		outline: none; /* Remove outline */
		transition: opacity 0.2s;
		width: 100%; /* Full-width */
		padding: 0;

		@media (min-width: ${BREAKPOINTS.DEFAULT}px) {
			margin-top: 0;
		}
	}

	input::-webkit-slider-thumb {
		-webkit-appearance: none;
		appearance: none;
		background: url(${slider});
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
		cursor: pointer;
		height: 24px;
		width: 24px;
		background-color: transparent;
		border-radius: 15px;
		padding: 0;
		margin: 0;
		/* border: 1px solid red;  */
	}
`;