import React, { useEffect, useState } from 'react';
import { BigContainer, BigTitle, Divisor, InfoNot, InfoCard, CardWrapper } from './style';
import {
  Footer,
  Header,
  SmallSquareButton,
  ProgressCircle,
  InvestCalculator,
  TokenCard,
  InvestHeader,
} from '../../../components';
import { mock } from '../mockDetalhesSite';
import { Solid } from '../../../assets/newIcons';
import utils from '../../../helper/utils';
import BlackTable from './blackTable';
import WhiteTable from './whiteTable';
import ColouredTable from './colouredTable';
import { useNavigate } from 'react-router-dom';

const DetalhesMobile = ({ data }) => {
  const [headerColor, setHeaderColor] = useState('#fdfdfd');
  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.overflowX = 'hidden';
  }, []);

  useEffect(() => {
    if (!data) return;
  }, []);

  window.addEventListener('scroll', function () {
    const divElement = document.getElementById('coloured-table');
    const divRect = divElement.getBoundingClientRect();
    if (divRect.top <= 100) {
      setHeaderColor(data.color1);
    }
    if (divRect.top <= -880) {
      setHeaderColor('#fdfdfd');
    }
    if (divRect.top >= 0) {
      setHeaderColor('#fdfdfd');
    }
  });

  if (!data.tick) return <></>;

  return (
    <>
      <InvestHeader fixed={true} backgroundColor={headerColor} data={data} />
      <BigContainer color1={data.color1}>
        {/* {console.log(data)} */}
        <BigTitle>
          {data.fullName} - {new Date(data.createdAt).getFullYear()}
        </BigTitle>
        <div className="row" style={{ marginTop: 14 }}>
          <BigTitle>{data.tick} - </BigTitle>
          <img src={data.icon} style={{ marginLeft: 6, height: 24 }} alt="icon" />
        </div>
        <div className="row" style={{ marginTop: 24 }}>
          <SmallSquareButton
            color1={data.color1}
            color2={data.color2}
            label={'Lâmina técnica'}
            marginRight
          />
          <SmallSquareButton
            color1={data.color1}
            color2={data.color2}
            label={'Investir'}
            marginRight
            selected
            onClick={() => {
              navigate('/register');
            }}
          />
        </div>
        <Divisor />
        <div style={{ position: 'relative' }}>
          {/* {console.log(data)} */}
          <ProgressCircle valueArray={data.chart} squareEnd />
          <div className="rate-position">
            <p className="span-rate">{Number(data.rate / 100)?.toFixed(2)?.replace('.', ',')} %</p>
            <span className="rate">Captado</span>
          </div>
        </div>
        <Divisor />

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <InfoNot>
            <p className="value">{data.profitability}% a.a.</p>
            <p className="legend">Rentabilidade</p>
          </InfoNot>
          <InfoNot>
            <p className="value">{data.min}</p>
            <p className="legend">Invest. mínimo</p>
          </InfoNot>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 58 }}>
          <InfoNot>
            <p className="value">{data.deadline} meses</p>
            <p className="legend">Prazo</p>
          </InfoNot>
          <InfoNot color={data.color1}>
            <p className="value">
              {data.category}
              {data.fgc && <img className="colored-check" src={Solid('bigCheckBlack')} />}{' '}
              {data.fgc ? 'FGC' : ''}
            </p>
            <p className="legend">Garantia</p>
          </InfoNot>
        </div>

        <Divisor />
        <CardWrapper style={{ width: '110%', marginLeft: -15 }}>
          <InfoCard color={data.color1}>
            <p className="value">
              {new Date(data.startAt).getDate() > 9
                ? new Date(data.startAt).getDate()
                : '0' + new Date(data.startAt).getDate()}{' '}
              {utils.dataTypes.date.numberToMonth(new Date(data.startAt).getMonth() + 1)[1]}{' '}
              {new Date(data.startAt).getFullYear()}
            </p>
            <p className="legend">
              Data de início
              <br />
              de rentabilidade
            </p>
          </InfoCard>
          <InfoCard color={data.color1}>
            <p className="value">
              {new Date(data.endAt).getDate() > 9
                ? new Date(data.endAt).getDate()
                : '0' + new Date(data.endAt).getDate()}{' '}
              {utils.dataTypes.date.numberToMonth(new Date(data.endAt).getMonth() + 1)[1]}{' '}
              {new Date(data.endAt).getFullYear()}
            </p>
            <p className="legend">
              Encerramento
              <br />
              de rentabilidade
            </p>
          </InfoCard>
          <InfoCard color={data.color1}>
            <p className="value">{data.recurrence}</p>
            <p className="legend">
              Modelo de
              <br />
              Investimento
            </p>
          </InfoCard>
          <InfoCard color={data.color1}>
            <p className="value" style={{ display: 'flex', alignItems: 'center' }}>
              {data.risk}
              <img className="colored-check" src={Solid('bigCheckBlack')} style={{ height: 17 }} />
            </p>
            <p className="legend">
              Classificação
              <br />
              de risco
            </p>
          </InfoCard>
        </CardWrapper>
        <Divisor />
        <InvestCalculator data={data} />
        <Divisor />
        <div className="row" style={{ marginTop: 30, marginBottom: 60 }}>
          <SmallSquareButton
            color1={data.color1}
            color2={data.color2}
            label={'Lâmina técnica'}
            marginRight
          />
          <SmallSquareButton
            color1={data.color1}
            color2={data.color2}
            label={'Investir'}
            marginRight
            selected
            onClick={() => {
              navigate('/register');
            }}
          />
        </div>
      </BigContainer>
      {data.table1 && data.table2 && <BlackTable data={data} />}
      {data.table3Half1 && data.table3Half2 && (
        <WhiteTable
          data={data}
          creactAccount={() => {
            navigate('/register');
          }}
        />
      )}
      {data.table3 && (
        <ColouredTable
          data={data}
          id="coloured-table"
          creactAccount={() => {
            navigate('/register');
          }}
        />
      )}
      {data.similarCards.length > 0 && (
        <BigContainer color1={data.color1} style={{ paddingTop: 0 }}>
          <h1 className="black-title">Investimentos Semelhantes</h1>
          <p className="normal-black-text">
            Selecionamos alguns investimentos
            <br />
            alinhados ao seu perfil de investidor
          </p>
          <CardWrapper style={{ width: '110%', marginLeft: -15, marginBottom: 60 }}>
            {data.similarCards.map((item) => {
              return (
                <TokenCard
                  key={item.tick}
                  icon={item.icon}
                  tokenName={item.tick}
                  min={item.min}
                  color={item.color}
                  value={item.lastPrice * item.circulating || 0}
                  variation={item.variation}
                  down={String(item.variation).includes('-')}
                  profitability={item.profitability}
                  index={item.index}
                  name={item.name}
                  rate={item.rate}
                  recurrence={item.recurrence}
                  deadline={item.deadline}
                  createdAt={item.createdAt}
                  fgc={item.fgc}
                  category={item?.category}
                  onClick={() => {navigate(`/investment/${item.id}`)}}
                />
              );
            })}
          </CardWrapper>
          <h1 className="bigger-black-title">
            Exclusão de
            <br />
            responsabilidade
          </h1>
          <p className="condition-text">{data.freeResponsibility}</p>
        </BigContainer>
      )}
      <Footer />
    </>
  );
};

export default DetalhesMobile;
