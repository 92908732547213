import { useState, useRef } from 'react'

import * as Style from './style'
import * as Template from '../style'
import { Button, Input } from "../../../components"

import utils from '../../../helper/utils'

const btnStyle = {
  fontSize: 14,
  fontWeight: 700,
  width: '100%',
  boxSizing: 'border-box',
  marginTop: 40,
};

export default function ModalContent({ phone, onSubmit = () => {}, extended }) {
  const [accepted, setAccepted] = useState(false);
  const code = useRef();

  return (
    <Template.ModalContainer>
      <div className='centered'>
        <Template.PageTitle>Verificar número!</Template.PageTitle>
        <Style.Row>
          Digite aqui o código de 4 dígitos que te enviaremos por SMS no número<br />
          <span>{utils.dataTypes.number.toPhone(phone)}</span>.
        </Style.Row>

        <div style={{ marginBottom: 30 }} />

        <Input
          type="code"
          autofocus
          filled={accepted}
          onAccept={() => setAccepted(true)}
          onFocus={() => setAccepted(false)}
          onChange={(c) => code.current = c}
        />

        <Button
          label="CONFIRMAR"
          rounded
          style={btnStyle}
          onClick={() => onSubmit(code.current)}
        />
      </div>
    </Template.ModalContainer>
  )
}