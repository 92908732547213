import { useState } from "react";
import banner from "../../assets/banner.png";
import { useNavigate } from "react-router-dom";
import * as Style from "./style";
import utils from "../../helper/utils";
import { PALLETE } from "../../configs/configs";

import Mobile from "./mobile";

const { responsive } = utils;

export default function Header({ fixed, onClickDesktop, backgroundColor }) {
	const navigate = useNavigate();
	const [scroll, setScroll] = useState(0);

	window.addEventListener("scroll", () => setScroll(window.scrollY));

	return responsive.choose(
		<Mobile scroll={scroll} backgroundColor={backgroundColor}/>
	)
	(
		<Style.Header fixed={fixed}>
			<div style={{ display: "inline-block" }}>
				<Style.Container fixed={fixed} scroll={fixed && scroll} >
					<Style.Banner src={require('../../assets/banner.png')} alt="Banner" fixed={fixed} />
					<Style.Button
						fixed={fixed}
						onClick={onClickDesktop}
					>
						Criar conta
					</Style.Button>
				</Style.Container>
			</div>
		</Style.Header>

    
	);
}
