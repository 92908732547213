import React, { useEffect, useState } from "react";
import { useFetch } from "../../hooks";

import utils from "../../helper/utils";
import DetalhesMobile from "./mobile/index";
import DetalhesDesktop from "./desktop/index";
import {mock} from './mockDetalhesSite';
import { useLocation } from "react-router-dom";
const { responsive } = utils;

function InvestDetails( ) {
	const api = useFetch();
	const [data, setData] = useState({});
  const location = useLocation();

	useEffect(() => {
    const url = location.pathname.split('/').pop()
		api.fetch('get', null, `web/token/${url}`)
			.then((res) => {
				setData(res.data);
			})
	}, [])

	return responsive.choose(<DetalhesMobile data={mock} />)(<DetalhesDesktop data={data} />);
}

export default InvestDetails;
