import styled from 'styled-components';
import { PALLETE } from '../../../configs/configs';
import utils from '../../../helper/utils'
const { responsive, dataTypes } = utils

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .row {
    padding: 35px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 450px) {
      padding: 20px 0;
      align-items: center;
  }
  }

  .smallRow {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .column {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid ${PALLETE.pureWhite};
  }

  .hidden-text {
    font-weight: 300;
    font-size: 24px;
    margin: 0;
    color: ${PALLETE.pureWhite};
    text-align: justify;
    @media (max-width: 450px) {
      font-size: ${responsive.ajustFont(12)}px;
    }
  }

  img {
    &:hover {
      cursor: pointer;
    }
  }

  .text {
    font-weight: 400;
    font-size: 32px;
    color: ${PALLETE.pureWhite};
    margin: 0;
    @media (max-width: 450px) {
      font-weight: 600;
      font-size: ${responsive.ajustFont(16)}px;
      text-align: left;
      color: ${PALLETE.pureWhite};
    }
  }

  @media (max-width: 450px) {
  }
`;

export const SliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  transition: max-height 0.5s;
  width: 100%;
  padding: ${(props) => {
    if (props.extended) return '0 0 30px 0';
    return '0';
  }};
  max-height: ${(props) => {
    if (props.extended) return '192px';
    return '0';
  }};
  overflow: hidden;

  @media (max-width: 450px) {
    padding: ${(props) => {
    if (props.extended) return '0 0 20px 0';
    return '0';
  }};
  }
`;
