import React from 'react';
import { BigContainer, BigTitle, Divisor, FullWrapper } from './style';
import { IconTable, SmallSquareButton, ValuesTable } from '../../../components';
import { Solid } from '../../../assets/newIcons';
import { PALLETE } from '../../../configs/configs';

const ColouredTable = ({ data, id, creactAccount }) => {
  return (
        <BigContainer style={{ paddingTop: 1 }} color={data.color1} id={id}>
          <BigTitle
            color={PALLETE.pureWhite}
            style={{ marginTop: 70, marginBottom: 6, width: '100%' }}
          >
            {data.fullName} - {new Date(data.createdAt).getFullYear()}
          </BigTitle>
          <div className="row" style={{ marginBottom: 20 }}>
            <BigTitle color={PALLETE.pureWhite}>{data.tick} -</BigTitle>
            <img src={Solid(data.iconTickInverted)} style={{ marginLeft: 12 }} height={24} />
          </div>
          <IconTable arr={data.table3} />
          <div className="row" style={{ marginTop: 60 }}>
            <SmallSquareButton
              color1={data.color1}
              color2={data.color2}
              label={'Lâmina técnica'}
              marginRight
            />
            <SmallSquareButton
              color1={data.color1}
              color2={data.color2}
              label={'Investir'}
              marginRight
              onClick={creactAccount}
            />
          </div>
          <div style={{ height: 60 }} />
        </BigContainer>
  );
};

export default ColouredTable;
