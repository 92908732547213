import styled from 'styled-components';
import { PALLETE } from '../../../configs/configs';
import utils from '../../../helper/utils';

const size = utils.responsive.ajustSize(252, 294);
const container = utils.responsive.ajustSize(266, 304);

export const Card = styled.div`
  width: ${(props) => {
    if (props.marginCircle) return '266px';
    return '252px';
  }};
  position: relative;
  display: flex;

  @media (max-width: 450px) {
    width: ${(props) => {
      if (props.marginCircle) return `${container.width}px`;
      return `${size.width}px`;
    }};
  }

  img {
    ${utils.dataTypes.color.colorConverter(PALLETE.base).filter}
  }

  .circle {
    position: absolute;
    top: 0;
    left: 0;
    height: 28px;
    width: 28px;
    background-color: ${(props) => {
      if (!('circleOpacity' in props)) return PALLETE.base;
      const sat = utils.dataTypes.color.ajustSatColor;
      const light = utils.dataTypes.color.ajustLightColor;

      return sat(
        light(PALLETE.base, 1 + props.circleOpacity),
        props.circleOpacity,
      );
    }};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 10px 10px 40px rgba(0, 0, 0, 0.25),
      1px 1px 1px ${PALLETE.base};
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card {
    box-sizing: border-box;
    width: 252px;
    min-height: 290.34px;
    padding: 33px 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background: #f9f9f9;
    border: 1px solid #f9f9f9;
    box-shadow: 0px 6.55887px 13.1177px rgba(31, 7, 59, 0.12);
    border-radius: 10px;
    margin: ${(props) => {
      if (props.marginCircle) return '14px 0 0 14px';
      return '0';
    }};
    @media (max-width: 450px) {
      width: ${size.width}px;
      height: ${size.width * 1.15}px;
    }
  }

  .min-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .white {
    color: ${PALLETE.bluieshWhite} !important;
  }

  .card-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: ${PALLETE.black};
    margin: 20px 0 0 0;

    @media (max-width: 450px) {
      font-size: ${utils.responsive.ajustFont(16)}px;
    }
  }

  .card-text {
    margin: 0;
    font-weight: 300;
    font-size: 15px;
    line-height: 23px;
    color: ${PALLETE.black};
    text-align: left;
  }
`;
