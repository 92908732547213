import React, { useState, useEffect, useRef } from 'react';
import { useFetch } from '../../../hooks';
import {
  getInvestDates,
  getInvestDetails,
  mock,
  mockLib,
  getBasicInfo,
  responseToNewRef
} from './factory';
import { TextGrid, ProgressCircle, Button } from '../../../components';
import * as STYLE from './style';

// Icones
import icons from '../../../assets/icons';


// Styles
import { PALLETE } from '../../../configs/configs'

const tokenData1 = {
  color: '#03989E',
}

const DetalhesInvest = ({
  tokenData = tokenData1,
  history = false,
  onClose = () => { },
  onCLickMainButton = () => { },
  buttonTitle = 'Seja um investidor',
}) => {
  //   const { setBuffer, wallet } = useContext(DashboardContext);

  const [loadPage, setLoadPage] = useState(true);
  const [contentPos, setContentPos] = useState(0);

  const token = useFetch('/');
  const data = useRef(mock);

  const onScrollFunc = () => {
    const div = document.getElementById('scroll-div-modal')
    return setContentPos(div.scrollTop)
  }

  useEffect(() => {
    if (!tokenData.tick) return;
    setLoadPage(true)
    const loadData = setTimeout(() => {
      token
        .fetch('get', null, `web/token/${tokenData.id}`)
        .then((res) => {
          const dt = res.data;
          // console.log(dt)
          responseToNewRef(data, dt, tokenData, history);
          setLoadPage(false);
        })
        .catch((err) => {
          console.error(err.message);
        });
    }, 200);

    return () => {
      clearTimeout(loadData);
    };
  }, []);

  return (
    <>
      {loadPage ? (
        // <NewLoading />
        <p></p>
      ) : (
        // <></>
      <>
        <STYLE.HeaderModalContainer
          scrollTo={contentPos}
          scrollMinHeight={70}
        >
          <STYLE.CoverImage
            src={data.current.image}
            alt='header image'
          />
          <STYLE.HeaderTitleContainer>
            <STYLE.WhiteTitle>
              {`${data.current?.name} ${data.current?.productType} #0${data.current?.round} - ${data.current?.token}`}
            </STYLE.WhiteTitle>
            <STYLE.CloseIcon onClick={onClose}>
              <img src={icons.xSolidWhite} alt='x icon' />
            </STYLE.CloseIcon>
          </STYLE.HeaderTitleContainer>
        </STYLE.HeaderModalContainer>
        <STYLE.ScrollContainer
          onScroll={() => onScrollFunc()}
          id="scroll-div-modal"
        >
          <STYLE.Row style={{ marginBottom: 20 }}>
            <STYLE.ColorTag color={tokenData.color}>
              <STYLE.SimpleText color={PALLETE.white} fontWeight={'700'}>
                {data.current?.paymentTag}
              </STYLE.SimpleText>
            </STYLE.ColorTag>
            <STYLE.Row style={{ width: 'auto' }}>
              <STYLE.SimpleText margin="0 3px 0 0">Rentabilidade:</STYLE.SimpleText>
              <STYLE.ColorTag color={tokenData.color}>
                <STYLE.SimpleText color={PALLETE.white} fontWeight={'700'}>
                  {data.current?.index && `${data.current?.index} + `}
                  {data.current?.profitability}% a.a.
                </STYLE.SimpleText>
              </STYLE.ColorTag>
            </STYLE.Row>
          </STYLE.Row>

          <STYLE.Line />
          <div style={{ marginTop: 20, width: '100%' }}>
            <TextGrid
              alignToRight
              data={getBasicInfo({ data: data.current })}
            />
          </div>
          {/* {console.log(data.current)} */}
          <div style={{ marginHorizontal: -10, marginBottom: 30, width: '100%' }}>
            <TextGrid alignToRight grey data={getInvestDates({ data: data.current })} />
          </div>
          <STYLE.Line />
          <div style={{ marginTop: 30, marginBottom: 30, width: '100%', position: 'relative' }}>
            <ProgressCircle valueArray={data.current.chart} squareEnd />
            <STYLE.ChartText color={tokenData.color} history={history}>
              {history ? `Concluído` : `${(data.current.rate * 100).toFixed(2)} %`}
              <p style={{ fontSize: 14, fontWeight: '300', color: 'black' }}>
                {`\n`}
                {history ? 'Pago' : 'Vendido'}
              </p>
            </STYLE.ChartText>
          </div>
          <STYLE.Line />
          <div style={{ marginTop: 30, marginBottom: 10, width: '100%' }}>
            <TextGrid
              alignToRight
              data={getInvestDetails({ data: data.current })}
            />
          </div>
          <STYLE.Line />
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 30, marginBottom: 30 }}>
            <div style={{ width: 360 }}>
              <Button
                disabled={history}
                label={buttonTitle}
                onClick={onCLickMainButton}
              />
            </div>
          </div>
          <STYLE.Line />
              <STYLE.SimpleText fontWeight={'700'} fontSize={16} style={{ marginTop: 30, alignSelf: 'center'}}>
                Detalhes da Token
              </STYLE.SimpleText>

              <div style={{ width: '100%', marginTop: 30}}>
                {mockLib.map(([label, path, esp]) => (
                  <Button label={label} style={path === 'white-paper' ? {
                    backgroundColor: tokenData.color,
                    color: PALLETE.white, marginBottom: 20, height: 50, fontSize: 14
                  } : { marginBottom:20, height: 50, fontSize: 14}} arrow arrowWhite={path === 'white-paper'}/>
                ))}
              </div>
        </STYLE.ScrollContainer>
      </>
       )}
    </>
  );
};

export default DetalhesInvest;
