import React, { useState } from 'react';
import { Table, SliderContainer } from './style';
import { Outlined } from '../../../assets/newIcons';

const IconTable = ({ arr }) => {
  const [extend, setExtend] = useState('');
  return (
    <Table>
      {arr.map((item, i) => {
        return (
          <div className="column" key={`black_table_${i}`}>
            <div className="row" >
              <div className="smallRow">
                <img src={Outlined(item.icon)} height={window.innerWidth >= 450 ? 28 : 12} style={{ marginRight: 12 }} />
                <p className="text">{item.value}</p>
              </div>
              <img
                src={Outlined('arrowWhiteTable')}
                height={window.innerWidth >= 450 ? 32 : 24}
                style={{
                  transform: extend === item.value ? 'rotate(180deg)' : 'rotate(0deg)',
                }}
                onClick={() => setExtend(extend === item.value ? '' : item.value)}
              />
            </div>
            <SliderContainer extended={extend === item.value}>
              <p className="hidden-text">{item.content}</p>
            </SliderContainer>
          </div>
        );
      })}
    </Table>
  );
};

export default IconTable;
