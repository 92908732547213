import utils from '../../helper/utils';
import HeaderDesktop from './desktop';
import HeaderMobile from './mobile';
const { responsive } = utils;

const Header = (props) => {
  return responsive.choose(
    <HeaderMobile white={props.white} />
  )(
    <HeaderDesktop {...props} />
  );
};

export default Header;
