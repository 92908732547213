import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	Footer,
	Modal,
  NewHeader
} from "../../components";
import DetalhesInvest from "../investiments/tokenDetail";
import utils from "../../helper/utils";
import icons from "../../assets/icons";

import { Intro, AppIntro, SapiensInvest, Captions, SapiensCredit, AluVerde, Neutralization, Simule, InteractiveMenu } from './sections'


function Mobile({ opportunities, closed }) {
	const [showModal, setShowModal] = useState(false);
	const [oportunityList, setOpportunityList] = useState([]);
  const [selectedAV, setSelectedAV] = useState('Para você');
  const [isDivAtTop, setIsDivAtTop] = useState(false);
  const introRef = useRef(null)
  const simuleRef = useRef(null)
  const navigate = useNavigate();

	const selectedToken = useRef({ tokenData: {}, history: false });

	useEffect(() => {
		document.body.style.overflowX = "hidden";
	}, []);

	useEffect(() => {
		if (!Array.isArray(opportunities)) return;
		Promise.all(
			opportunities.map(async (opp) => {
				const color = await utils.files.image.extractColor(icons[opp.icon]);
				opp.color = color;
				return opp;
			}),
		)
			.then((res) => {
				setOpportunityList(res);
			})
			.catch(() => {});
	}, [opportunities]);

  useEffect(() => {
    const handleScroll = () => {
      if (introRef.current) {
        const { top } = introRef.current.getBoundingClientRect();
        setIsDivAtTop(top < 0);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


	return (
		<>
			{/* <Header fixed={!responsive.isMobile} backgroundColor={headerColor}/> */}
      <NewHeader white={isDivAtTop}/>
			<Intro simuleRef={simuleRef} introRef={introRef}/>
			<AppIntro />
			<SapiensInvest simuleRef={simuleRef}/>
			<Captions
				oportunityList={oportunityList}
				closed={closed}
				selectedToken={selectedToken}
				setShowModal={setShowModal}
			/>
      <Simule simuleRef={simuleRef}/>
      <InteractiveMenu />
      <SapiensCredit />
      <AluVerde setSelectedAV={setSelectedAV} selectedAV={selectedAV} />
      <Neutralization />
			<Footer />
{/*       <End simuleRef={simuleRef}/> */}
			<div
				style={{
					position: "fixed",
					height: 20,
					width: "100%",
					bottom: 0,
				}}
			>
				<Modal
					extended={showModal}
					onClickOut={setShowModal}
					size="bigger"
					noCloseIcon
				>
					{showModal ? (
						<DetalhesInvest
							onClose={() => {
								setShowModal(false);
							}}
							history={selectedToken.current.history}
							tokenData={{
								...selectedToken.current.tokenData,
								// color: "#03989E",
							}}
							onCLickMainButton={() => navigate("/register")}
						/>
					) : (
						<></>
					)}
				</Modal>
			</div>
		</>
	);
}

export default Mobile;
