import React, { useEffect, useState } from "react";
import { useFetch } from "../../hooks";

import utils from "../../helper/utils";
import Mobile from './mobile'
import Desktop from "./desktop";
const { responsive } = utils;

const banners = [
	// {
	// 	image: require("../../assets/baner1.png")
	// },
	// {
	// 	image: require("../../assets/baner1.png")
	// },
	// {
	// 	image: require("../../assets/banner2.png")
	// },
	// {
	// 	image: require("../../assets/banner2.png")
	// },
]

function App() {
	const [opportunities, setOpportunities] = useState([]);
	const [closed, setClosed] = useState([]);

	const opportunitiesApi = useFetch('web/token-list?status=0');
	const closedApi = useFetch('web/token-list?status=2');

	useEffect(() => {
		opportunitiesApi.fetch('get')
			.then((res) => {
				setOpportunities(res.data);
			})

		closedApi.fetch('get')
			.then((res) => {
				setClosed(res.data);
			})
	}, [])


	return responsive.choose(
		<Mobile
			opportunities={opportunities}
			closed={closed}
			banners={banners}
		/>
	)(
		<Desktop
			opportunities={opportunities}
			closed={closed}
		/>
	)
}

export default App;
