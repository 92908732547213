import styled from 'styled-components'
import ReactSelect from 'react-select'
import { PALLETE } from '../../configs/configs'

export const Select = styled(ReactSelect)`
  .Select__control {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-align: left;
    height: 41px;
    width: 285px;
    padding-left: 0;
    background: transparent;
    border: none;
    border-bottom: 1px solid #EEEEEE;
    box-sizing: border-box;
    outline: hidden;
    &:hover {
      outline: hidden;
      background-color: #F9F9F9;
    }

    @media (max-width: 450px) {
     width: 102px;
     font-size: 12px;
     height: 24px;
     margin-bottom: 15px;
    }
  }
  .Select__control--is-focused {
    outline: hidden;
    border: none;
    border-bottom: 1px solid #EEEEEE;
    &:hover {
      outline: hidden;
      border: none;
      border-bottom: 1px solid #EEEEEE;
    }
    box-shadow: none;
  }
  .Select__indicator-separator {
    display: none;
  }
  .Select__indicator {
    color: ${PALLETE.base};
    padding-right: 15px;
  }
  .Select__menu {
    background-color:#FFFFFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-align: left;
    box-sizing: border-box;
    margin-top: 2px;
    width: 285px;
    @media (max-width: 450px) {
     width: 102px;
     font-size: 12px;
    }
  }
  .Select__option {
    width: 285px;
    color: #757575;
    border-bottom: 1px solid #F9F9F9;
    background-color:#FFFFFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    padding: 7px 15px;
    text-align: left;
    overflow: hidden;
    height: 38px;
    &:hover {
      background-color: #F9F9F9;
      color: #212121;
    }
    @media (max-width: 450px) {
     width: 102px;
     font-size: 12px;
     height: 24px;
    }
  }
`

export const SelectContainer = styled.div`
  position: relative;
`
