import icons from "../../assets/icons";
import LoadGif from "./style";

export default function LoadGifComponent(props) {
	const { black, confirm, disabled, ...rest } = props;
	return (
		<LoadGif
			green={black}
			src={(black && !disabled) ? icons.loadingWhite : icons.loadGifOutlined}
			alt="loading"
			{...rest}
		/>
	);
}
