import styled from "styled-components";
import { PALLETE } from "../../configs/configs";

export const Header = styled.div`
  display: flex;
  background-color: ${PALLETE.white};
  height: ${(props) => {
    if (props.down) return '130px'
    return '100px'
  }};
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  /* padding-top: 10px;
  padding-bottom: 10px; */
  padding: ${(props) => {
    if (props.down) return '20px 30px'
    return '45px 30px'
  }};
  /* padding: 20px 30px; */
  z-index: 10;
  box-shadow: 0px 2px 20px rgba(7, 7, 7, 0.035);
  box-sizing: border-box;
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const HeaderTitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin: 0;
  color: ${PALLETE.black};
`;

export const Wrapper = styled.div`
    background-color: ${PALLETE.white};
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;

`
export const Container = styled.div`
  width: 100%;
  padding: 95px 30px 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
`
export const PageTitle = styled.h1`
  font-weight: 500;
  font-size: ${(props) => {
    if (props.big) return '24px';
    return '20px'
  }};
  margin: 0;
  color:${PALLETE.black}
`

export const Margin = styled.div`
  margin: 15px 0;
` 
export const Error = styled.p`
  margin-top: 30px;
  color: ${PALLETE.pink};
  font-weight: 400;
  font-size: 12px;
`
export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 30px;
  box-sizing: border-box;

  .centered {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`
