import { Background, GeneralContainer, FormContainer } from "./style";

const ModalLateral = ({ children, onClickOut, showForm }) => {
	return (
		<GeneralContainer extended={showForm}>
				<Background onClick={onClickOut} extended={showForm} />
				<FormContainer extended={showForm}>
					{children}
				</FormContainer>
		</GeneralContainer>
	);
};

export default ModalLateral;
