import styled from 'styled-components';
import { PALLETE } from '../../configs/configs';
import utils from '../../helper/utils';

const { responsive, dataTypes } = utils;
const size = responsive.ajustSize(146, 39);
const smallsize = responsive.ajustSize(92, 36);

export const Button = styled.a`
  box-sizing: border-box;
  width: ${(props) => {
    if (props.medium) return '202px';
    return '180px';
  }};
  height: 48px;
  padding: 12px 16px 16px 16px;
  background: ${(props) => {
    if (props.selected) return props.color1;
    if (props.gradient) return `linear-gradient(180deg, ${props.color1} 0%, ${props.color2} 100%);`;
    return PALLETE.white;
  }};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: ${(props) => {
    if (props.marginRight) return '20px';
    return '0';
  }};
  color: ${(props) => {
    if (props.selected) return PALLETE.pureWhite;
    return PALLETE.inputGrey;
  }};
  .label {
    font-weight: 400;
    font-size: 16px;
    margin: 0;
    @media (max-width: 450px) {
      font-size: ${responsive.ajustFont(12)}px;
    }

    .smallerLabel {
      font-weight: 400;
    font-size: 8px;
    margin: 0;
    }
  }

  .arrow-down {
    ${(props) => {
      if (props.color1) return dataTypes.color.filterGenerator(props.color1);
      return 'filter: none';
    }};
  }

  .dot {
    width: 6px;
  height: 6px;
  border-radius: 25px;
  background-color: ${props => {
    if (props.selected) return PALLETE.pureWhite;
    return props.color1;
  }};
  }

  &:hover {
    /* color: ${(props) => {
      if (props.selected) return PALLETE.pureWhite
      return props.color1
      }}; */
    /* background: ${(props) => {
      if (props.selected) return PALLETE.white;
    }}; */
    cursor: pointer;
  }

  @media (max-width: 450px) {
    /* min-height: 39px; */
    height: ${(props) => {
      if (props.smallMob) return `${smallsize.width * 0.39}px`
      return `${size.width * 0.27}px`
    }};
     width: ${(props) => {
      if (props.smallMob) return `${smallsize.width}px`
      return `${size.width}px`
    }};
    /* height: ${size.width * 0.27}px; */
    /* width: ${size.width}px; */
    padding: 12px 9px 12px 9px;
  }
`;
