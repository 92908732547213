import styled from "styled-components";
import utils from "../../helper/utils.js";
import { BREAKPOINTS, PALLETE } from "../../configs/configs";

export const Banner = styled.img`
	width: 153px;
	@media (min-width: ${BREAKPOINTS.DEFAULT}px) {
		width: 190px;
	}
`;
export const Header = styled.div`
	position: fixed;
	z-index: 11;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  background: ${props => {
    if (props.white) return "#FFFFFF"
    return 'linear-gradient(180deg, #000000 -48.89%, rgba(0, 0, 0, 0) 100%)'
  }};
 /* @media (max-width: 450px) {
      max-width: 100%;
 } */

`;

export const Container = styled.header`
	display: flex;
  justify-content: space-between;
	box-sizing: border-box;
	width: 1440px;
	padding: 0 100px;
	align-items: center;
	z-index: 11;
	height: 90px;

  @media (max-width: 450px) {
    width: 100%;
    height: 60px;
    padding: 0 30px;
  }

  .ico-container {
    display: flex;
    gap: 10px;
    margin-right: 50px;
    @media (max-width: 450px) {
      margin-right: 0;
    }

    .leaf {
      height: 33px;
      ${(props) => utils.dataTypes.color.filterGenerator(
        props.white ? PALLETE.base : PALLETE.white
      )}
    }
    .mobile {
      height: 22px;
    }
  }

  .header-logo {
    width: 152px;
    @media (max-width: 450px) {
      width: 100px;
    }
  }

  .header-row {
    width: 677px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header-text-item {
    font-weight: 500;
    font-size: 16px;
    color: ${(props => {
      if (props.white) return PALLETE.black
      return PALLETE.pureWhite
    })};
    margin: 0;
    cursor: pointer;
  }
`;