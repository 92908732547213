import styled from 'styled-components'
import playIco from '../../assets/play.svg'
import { BREAKPOINTS } from '../../configs/configs'

export const Container = styled.div`
  width: 100%;
  max-width: 725px;
  height: calc(auto + 5px);
  overflow: hidden;
  max-height: 640px;
  filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.25));
  border-radius: 5px;

  #ico-play {
    width: 32px;
    height: 32px;
    background-image: url(${playIco});
    background-size: cover;
    position: absolute;
    left: calc(50% - 16px);
    top: calc(50% - 16px);
    z-index: 11;

    @media (min-width: ${BREAKPOINTS.DEFAULT}px) {
      width: 62px;
      height: 62px;
    }
  }
`
export const Player = styled.video`
  width: 100%;
`