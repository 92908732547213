import { useState, useContext } from 'react'
import axios from 'axios'
import Context from '../context'

import { CONNECTION } from '../configs/configs.js'

const parseError = (error) => {
  const { response, isAxiosError } = error
  if (response && response.data) {
    // console.log(response.data)
    const { data, status } = response
    return [data, status] || []
  }
  if (isAxiosError) {
    // console.log(response)
    return { message: 'Falha ao enviar dados ao servidor', status: response.status }
  }
  return []
}

const useFetch = (endpoint = '/', headers = {} ) => {
  const [curEndpoint, setEndpoint] = useState(endpoint)
  const [isLoading, setIsLoading] = useState(false)
  const context = useContext(Context)

  // TODO - Acrescentei o parâmetro URL provisóriamente até descobrir o motivo
  // pelo qual o setEndpoint não estar funcionando
  const fetch = async (
    request = 'get',
    data = {},
    url
  ) => {
    const URL = `${CONNECTION.URL}${url || curEndpoint}`
    try {
      const options = { headers }
      setIsLoading(true)

      const method = {
        post: async () => {
          const r = await axios.post(URL, data, options)
          if (r.data?.token) context.token = r.data.token

          return { data: r.data, status: r.status, fail: false }
        },
        put: async () => {
          options.headers.Authorization = context.token
          const r = await axios.put(URL, data, options)
          if (r.data?.token) context.token = r.data.token

          return { data: r.data, status: r.status, fail: false }
        },
        get: async () => {
          options.headers.Authorization = context.token
          const r = await axios.get(URL, options)
          if (r.data?.token) context.token = r.data.token

          return { data: r.data, status: r.status, fail: false }
        },
        delete: async () => {
          options.headers.Authorization = context.token
          const r = await axios.delete(URL, options)

          return { data: r.data, status: r.status, fail: false }
        },
      }
      const response = await method[request]()
      setIsLoading(false)
      return response
    } catch (error) {
      setIsLoading(false)
      return { data: null, fail: true, errors: parseError(error) }
    }
  }

  return {
    setEndpoint, // TODO - Por algum motivo, isto não está atualizando
    fetch,
    isLoading,
  }
}

export default useFetch
