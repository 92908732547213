import { useState, useEffect } from "react";
import { useFetch } from "../../../hooks";
import icons from "../../../assets/icons";
import utils from "../../../helper/utils";
import {
	FormContainer,
	Form,
	CloseIcon,
	Divisor,
	Title,
	MaskedInput,
	Text,
	Label,
	Column,
	Row,
	CheckboxLabel,
	Error,
	EyeIcon,
} from "./style";
import { Button, Checkbox } from "../../../components";
import ValidarCell from "./validar";
import Confirm from "./confirm";

const DesktopForm = ({ onClickOut }) => {
	const [data, setData] = useState({});
	const [error, setError] = useState({});
	const [status, setStatus] = useState();
	const [loading, setLoading] = useState(false);
	const [eye, setEye] = useState({ password: false, cpassword: false });

	const registerApi = useFetch("web/register-user");

	useEffect(() => {
		setStatus();
	}, []);

	const onSubmit = () => {
		// console.log(data)
		setLoading(true);
		if (data?.cpassword !== data?.password) {
			setLoading(false);
			return setError({ cpassword: "As duas senhas devem ser identicas" });
		}
		registerApi
			.fetch("post", data)
			.then((res) => {
				if (res.errors) {
					// const [err] = res.errors;
					// const [field, message] = err.message.split(";");
					setError(res.errors[0].message);
					// console.log({ [field]: message })
					setLoading(false);
					return;
				}
				setLoading(false)
				setStatus("code");
			})
			.catch((err) => {
				console.log("err", err);
			});
	};

	switch (status) {
		case "confirm":
			return (
				<Confirm
					data={data}
					setData={setData}
					onClickOut={onClickOut}
					onBack={() => setStatus("code")}
				/>
			);
		case "code":
			return (
				<ValidarCell
					data={data}
					setData={setData}
					onClickOut={onClickOut}
					onBack={() => setStatus("idle")}
					setStatus={setStatus}
				/>
			);
		default:
			return (
				<FormContainer>
					<Form>
						<CloseIcon
							src={icons.xSolid}
							alt="close icon"
							onClick={onClickOut}
						/>
						<Title>
							Crie sua conta
						</Title>
						<Divisor />
						<Column>
							{error?.name && (
								<Row style={{ marginTop: 7, marginBottom: 0 }}>
									<img src={icons.errorOutlined} alt="error" />
									<Error>{error?.name}</Error>
								</Row>
							)}
							<Text
								error={error.name}
								type="text"
								name="name"
								onChange={(e) => {
									setData((prev) => ({ ...prev, name: e.target.value }));
								}}
							/>
							<Label for="name">Nome completo</Label>
						</Column>
						<Column>
							{error?.document && (
								<Row style={{ marginTop: 7, marginBottom: 0 }}>
									<img src={icons.errorOutlined} alt="error" />
									<Error>{error.document}</Error>
								</Row>
							)}
							<MaskedInput
								error={error.document}
								mask={[
									{ mask: "000.000.000-00" },
									{ mask: "00.000.000/0000-00" },
								]}
								name="document"
								onAccept={(value) => {
									setData((prev) => ({
										...prev,
										document: utils.dataTypes.string.clear(value),
									}));
								}}
							/>
							<Label for="document">CPF</Label>
						</Column>
						<Column>
							{error?.cellphone && (
								<Row style={{ marginTop: 7, marginBottom: 0 }}>
									<img src={icons.errorOutlined} alt="error" />
									<Error>{error?.cellphone}</Error>
								</Row>
							)}
							<MaskedInput
								error={error.cellphone}
								mask="(00) 00000-0000"
								name="phone"
								onAccept={(value) => {
									setData((prev) => ({
										...prev,
										cellphone: value,
									}));
								}}
							/>
							<Label for="phone">Telefone</Label>
						</Column>
						<Column>
							{error?.email && (
								<Row style={{ marginTop: 7, marginBottom: 0 }}>
									<img src={icons.errorOutlined} alt="error" />
									<Error>{error?.email}</Error>
								</Row>
							)}
							<Text
								error={error.email}
								type="email"
								name="email"
								onChange={(e) => {
									setData((prev) => ({ ...prev, email: e.target.value }));
								}}
							/>
							<Label for="email">Email</Label>
						</Column>
						<Column style={{ position: "relative" }}>
							{error?.password && (
								<Row style={{ marginTop: 7, marginBottom: 0 }}>
									<img src={icons.errorOutlined} alt="error" />
									<Error>{error?.password}</Error>
								</Row>
							)}
							<Text
								error={error.password}
								className="input"
								type={eye.password ? "text" : "password"}
								name="password"
								onChange={(e) => {
									setData((prev) => ({ ...prev, password: e.target.value }));
								}}
							/>
							<Label for="password">Senha</Label>
								<EyeIcon
									src={eye.password ?  icons.eyeClosedSmall : icons.eyeOpenSmall}
									alt="eye icon"
									onClick={() =>
										setEye((prev) => ({ ...prev, password: !eye.password }))
									}
								/>
						</Column>
						<Column style={{ position: "relative" }}>
							{error?.cpassword && (
								<Row style={{ marginTop: 7, marginBottom: 0 }}>
									<img src={icons.errorOutlined} alt="error" />
									<Error>{error?.cpassword}</Error>
								</Row>
							)}
							<Text
								error={error.cpassword}
								type={eye.cpassword ? "text" : "password"}
								name="cpassword"
								onChange={(e) => {
									setData((prev) => ({ ...prev, cpassword: e.target.value }));
								}}
							/>
							<Label for="cpassword">Confirmação de senha</Label>
								<EyeIcon
									src={eye.cpassword ? icons.eyeClosedSmall : icons.eyeOpenSmall}
									alt="eye icon"
									onClick={() =>
										setEye((prev) => ({ ...prev, cpassword: !eye.cpassword }))
									}
								/>
						</Column>
						<Row>
							<Checkbox
								onChange={(e) => {
									setData((prev) => ({ ...prev, agreement: e }));
								}}
							/>
							<CheckboxLabel>
								Li e concordo com os{" "}
								<a href="https://sapiensbank.com.br/assets/docs/terms.pdf">
									Termos de Uso
								</a>
								{` e`}
								<br />
								<a href="https://sapiensbank.com.br/assets/docs/policy.pdf">
									Política de Privacidade
								</a>
							</CheckboxLabel>
						</Row>
						<div style={{ width: "75%" }}>
							<Button
								loading={loading}
								black
								label="Criar conta"
								style={{ fontSize: 12, fontWeight: "700" }}
								onClick={onSubmit}
								disabled={
									!data?.cpassword ||
									!data?.password ||
									!data?.email ||
									!data?.cellphone ||
									!data?.document ||
									!data?.name ||
									!data?.agreement
								}
							/>
						</div>
					</Form>
				</FormContainer>
			);
	}
};

export default DesktopForm;
