import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Wrapper, DSlider } from "./style";

const items = [
	{ id: 1, src: "blue" },
	{ id: 2, src: "red" },
	{ id: 3, src: "green" },
	{ id: 4, src: "purple" },
	{ id: 5, src: "brown" },
	{ id: 5, src: "black" },
	{ id: 5, src: "gray" },
	{ id: 5, src: "orange" },
	{ id: 5, src: "pink" },
];

function AutoplayCarousel({
	children,
	autoplay = false,
	focusOnSelect = false,
	paused = false,
}) {
	const sliderRef = useRef(null);

	useEffect(() => {
		// console.log(paused);
		if (paused) {
			sliderRef.current.slickPause();
		} else {
			sliderRef.current.slickPlay();
		}
	}, [paused]);

	const settings = {
		dots: false,
		infinite: true,
		speed: 2000,
		slidesToShow: 3,
		// slidesToScroll: 1,
		swipe: true,
		swipeToSlide: true,
		// cssEase: "linear",
		autoplay: autoplay,
		autoplaySpeed: 100,
		centerMode: true,
		centerPadding: "20%",
		focusOnSelect: focusOnSelect,
		pauseOnHover: true,
		draggable: true,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
				},
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 2,
				},
			},
		],
	};

	return (
		<Slider ref={sliderRef} {...settings}>
			{children}
		</Slider>
	);
}

export default AutoplayCarousel;
