import React, { useState, useEffect } from 'react';
import utils from '../../helper/utils';
import { PALLETE } from '../../configs/configs';

export default function S({ items, onUpdate = () => {} }) {
  const [buttonList, setButtonList] = useState([]);

  const stylesheet = {
    chartStubtitle: {
      fontWeight: '400',
      fontSize: 12,
      color: PALLETE.black,
      marginTop: 22,
      marginBottom: 24,
      textAlign: 'left',
      alignSelf: window.innerWidth > 450 ? 'flex-start' : 'center'
    },
    chartButtonRow: {
      display: 'flex',
      alignItems: 'center',
      background: '#f5f5f5',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
      borderRadius: 5,
    },
    line: {
      width: window.innerWidth > 450 ? '100%' : '100%',
      border: '0.5px solid #eeeeee70',
      marginTop: 24,
      marginBottom: window.innerWidth > 450 ? 0 : 24

    },
    selectorButton(btn) {
      return {
        background: btn.selected ? btn.borderColor : 'transparent',
        borderRadius: 5,
        border: 'none',
        boxShadow: btn.selected ? `0px 4px 10px ${utils.dataTypes.color.hexToRgbAOpacity(PALLETE.base, 0.3)}` : 'none',
        boxSizing: 'border-box',
        color: btn.selected ? 'white' : '#757575',
        height: 35,
        marginRight: 2,
        padding: '8px 16px',
        fontWeight: '700',
        fontSize: window.innerWidth > 450 ? 12 : utils.responsive.ajustFont(8)
      }
    }
  };

  useEffect(() => {
    const list = items.map((item, index) => {
      return { ...item, selected: index <= 1 };
    });

    setButtonList(list);
    onUpdate(list.filter((item) => item.selected));
  }, [items]);

  function toggleButton(index) {
    const list = buttonList.map((item, i) => {
      if (i === index) item.selected = !item.selected;
      return item;
    });

    onUpdate(list.filter((item) => item.selected));
    setButtonList(list);
  }

  return (
    <>
      <p style={stylesheet.chartStubtitle}>Selecione o índice comparativo</p>
      <div style={{ alignSelf: window.innerWidth > 450 ? 'flex-start' : 'center'}}>
        <div  style={stylesheet.chartButtonRow}>
          {buttonList.map((btn, i) => {
            return (
              <button
                type="button"
                key={`btn_${i}`}
                style={stylesheet.selectorButton(btn)}
                onClick={() => toggleButton(i)}
              >
                {btn.label}
              </button>
            );
          })}
        </div>
        {utils.responsive.choose()(<div className="line" style={stylesheet.line}/>)}
      </div>
      {utils.responsive.choose(<div style={stylesheet.line}/>)()}
    </>
  );
}
