import * as Style from './style.js';
import { ArrowButton, ImageCover } from '../../../components/index.js';
import womanWithLaptop from '../../../assets/womenWithLaptop.png';
import mobileBg from '../../../assets/mobile-intro-bg2.png';
import utils from '../../../helper/utils.js';
import { PALLETE } from '../../../configs/configs.js';

const { responsive } = utils;

const Intro = ({ simuleRef, introRef }) => {
  return responsive.choose(
    <ImageCover
      image={mobileBg}
      height={530}
      introRef={introRef}
    >
      <Style.FirstContent>
        <Style.BigTitle style={{
          color: PALLETE.white,
          marginTop: 44,
        }}>
          <span>Investimentos</span> que <br />protegem o <span>futuro</span>
        </Style.BigTitle>

        <p className="content-text">
          Uma poderosa combinação<br />entre tecnologia, investimentos<br />e environmental.
        </p>

        <ArrowButton
          label="Simule investimento"
          style={{ marginTop: 15 }}
          onClick={() => {
            responsive.scrollToContent(simuleRef);
          }}
        />
      </Style.FirstContent>
    </ImageCover>
  )(
    <ImageCover
      image={womanWithLaptop}
      height={612}
      introRef={introRef}
    >
    <Style.FirstContent>
      <Style.BigTitle style={{
        marginBottom: 9,
        color: PALLETE.bluieshWhite,
        marginTop: 44,
      }}>
        <span>Investimentos</span> que <br />protegem o <span>futuro</span>
      </Style.BigTitle>

      <p className="content-text">
        Uma poderosa combinação<br />entre tecnologia, investimentos<br />e environmental.
      </p>

      <ArrowButton
        label="Simule investimento"
        style={{ marginTop: 15 }}
        onClick={() => {
          responsive.scrollToContent(simuleRef);
        }}
      />
    </Style.FirstContent>
  </ImageCover>
  );
};

export default Intro;
