import * as Style from './style.js';
import manOnPhone from '../../../assets/cover2.png';
import sapiensinvest from '../../../assets/sapiensinvest.svg';
import contentFactory from './contentFactory.json';
import { Outlined } from '../../../assets/newIcons.js';
import { TextCard, ArrowButton, ImageCover } from '../../../components/index.js';
import utils from '../../../helper/utils.js';
import { PALLETE } from '../../../configs/configs.js';

const { responsive } = utils;
const Invest = ({ investRef, simuleRef }) => {
  return (
    <>
      {utils.responsive.choose(
        <>
          <Style.BorderedImageContent>
            <div className="content-container" style={{ paddingTop: 0, paddingBottom: 0 }}>
              <div className="icon-container">
                <img src={Outlined('greenGraph')} alt="Chart" className="icon" />
                <img
                  src={sapiensinvest}
                  alt="sapiensinvest"
                  width={129}
                  style={{ marginLeft: 30 }}
                />
              </div>

              <div style={{ marginTop: 32, marginBottom: 32 }}>
                <Style.BigTitle medium>
                  Ativos de renda fixa que
                  <br />
                  rendem acima de 18% ao ano.
                </Style.BigTitle>
              </div>

              {/* <ArrowButton label="Simule investimento" onClick={() => utils.responsive.scrollToContent(simuleRef)}/> */}
            </div>
          </Style.BorderedImageContent>
          <Style.WhiteCover>
            <div className="inline">
              <Style.ThirdContent>
                <p className="title">Por que investir com a Sapiensbank?</p>
                <div className="row">
                  {contentFactory[2].map((item, i) => {
                    return <TextCard item={item} i={i} key={`text-card-1-${i}`} />;
                  })}
                </div>
              </Style.ThirdContent>
            </div>
          </Style.WhiteCover>
        </>
      )(
        <>
          <Style.WhiteCover ref={investRef}>
            <div className="inline">
              <Style.SecondContent>
                <div
                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}
                >
                  <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 120 }}>
                    <img src={Outlined('greenGraph')} alt="Building" className="icon" />
                    <img src={sapiensinvest} alt="sapiensinvest" style={{ marginLeft: 30 }} />
                  </div>
                  <div style={{ height: 20 }} />
                  <Style.BigTitle medium>
                    Ativos de renda fixa que
                    <br />
                    rendem acima de 18% ao ano
                  </Style.BigTitle>
                </div>
              </Style.SecondContent>
            </div>
          </Style.WhiteCover>  

          <Style.WhiteCover>
            <div className="inline">
              <Style.ThirdContent>
                <p className="title">Por que investir com a Sapiensbank?</p>
                <div className="row">
                  {contentFactory[2].map((item, i) => {
                    return <TextCard item={item} i={i} key={`text-card-1-${i}`} />;
                  })}
                </div>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>

                <ArrowButton
                  label="Simule investimento"
                  style={{ marginTop: 40, alignSelf: 'center' }}
                  onClick={() => {
                    responsive.scrollToContent(simuleRef);
                  }}
                  />
                  </div>
              </Style.ThirdContent>
            </div>
          </Style.WhiteCover>
        </>
      )}
    </>
  );
};

export default Invest;
