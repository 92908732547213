import { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import * as Style from './style.js';
import { TextToggle, TokenCard } from '../../../components/index.js';
import { PALLETE } from '../../../configs/configs.js';
import utils from '../../../helper/utils.js';

const 
Captions = ({
  // setSelectedOportu,
  // selectedOportu,
  oportunityList,
  closed,
  selectedToken = {},
  setShowModal = () => {},
}) => {
  const [op, setOp] = useState(true);

  useEffect(() => {
    setOp(oportunityList.length > 0)
  }, [oportunityList.length]);

  return (
    <Style.WhiteCover color="#F5F5F5">
      <div className="inline">
        <Style.FourthContent>
          <TextToggle
            setSelected={(s) => {
              setOp(s === 'Captações abertas')
            }}
            first="Captações abertas"
            second="Captações concluídas"
            toggleWidth={utils.responsive.choose(155)(210)}
            selected={oportunityList.length === 0}
          />
          <Style.BigTitle color={PALLETE.black} style={{ marginTop: 20, marginBottom: 20 }}>
            {op ? 'Seu dinheiro em uma' : 'Operações concluídas'}
            <br />
            {op ? 'Renda fixa de verdade.' : 'com sucesso.'}
          </Style.BigTitle>
          {op ? (
            <p className="text-content">
              Confira as ultimas ofertas publicadas com
              <br />
              Rentabilidade e Patrimônio protegido.
            </p>
          ) : (
            <p className="text-content">
              Ativos que renderam de 18% a 27% ao ano<br />aos nossos mais de 500 investidores.
            </p>
          )}
          <p className="tiny-text-fourth">{op ? 'Captações abertas:' : 'Captações concluídas:'}</p>
          <div className="cards-row">
            {op
              ? oportunityList.slice(0, 8).map((item) => {
                  return (
                    <TokenCard
                      key={item.tick}
                      icon={item.icon}
                      tokenName={item.tick}
                      min={item.min}
                      color={item.color}
                      value={item.lastPrice * item.circulating || 0}
                      variation={item.variation}
                      down={String(item.variation).includes('-')}
                      profitability={item.profitability}
                      index={item.index}
                      name={item.name}
                      rate={item.rate}
                      recurrence={item.recurrence}
                      deadline={item.deadline}
                      createdAt={item.createdAt}
                      fgc={item.fgc}
                      category={item?.category}
                      onClick={() => {
                        if (window.innerWidth > 450) {
                          // return navigate(`/investment/${item.id}`);
                        }
                        selectedToken.current = {
                          tokenData: item,
                          history: false,
                        };
                        // setShowModal(true);
                      }}
                    />
                  );
                })
              : closed.slice(0, 4).map((item) => {
                  const { icon, tick, lastPrice, profitability, index } = item;
                  return (
                    <TokenCard
                      lancamento
                      icon={icon.replace(/([A-Z][a-z]*)$/, 'Expired')}
                      tokenName={tick}
                      value={lastPrice}
                      profitability={profitability}
                      expired
                      index={index}
                      ribbon="greenRibbon"
                      ribbonText="Concluído"
                      onClick={() => {
                        if (window.innerWidth > 450) {
                          //return navigate(`/investment/${item.id}`);
                        }
                        selectedToken.current = {
                          tokenData: item,
                          history: true,
                        };
                        //setShowModal(true);
                      }}
                      rate={item.rate}
                      name={item.name}
                      recurrence={item.recurrence}
                      deadline={item.deadline}
                      createdAt={item.createdAt}
                      fgc={item.fgc}
                      category={item?.category}
                      min={item.min}
                    />
                  );
                })}
          </div>
        </Style.FourthContent>
      </div>
    </Style.WhiteCover>
  );
};

export default Captions;
