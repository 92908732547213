import styled from "styled-components";
import utils from '../../helper/utils'
import { BREAKPOINTS, PALLETE } from "../../configs/configs";

export const Container = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	font-family: "Inter", sans-serif;
	font-style: normal;
	color: #070707;
  align-items: flex-end;

  @media (max-width: 450px) {
		flex-wrap: wrap;
    align-items: flex-start;
	}

	.align-center {
		align-items: center;
	}

  .margin-big {
    
    @media (max-width: 450px) {
    margin-bottom: 48px;
    }
  }
  
	.col {
    display: flex;
		flex-direction: column;
		line-height: 150%;
		width: 150px;
		justify-content: flex-start;
    @media (max-width: 450px) {
    justify-content: center;
    width: 100px;
  }

		img.ico {
			height: 32px;
			display: block;
			margin-bottom: 22px;
			${utils.dataTypes.color.filterGenerator(PALLETE.bluieshWhite)}
			@media (max-width: 450px) {
				/* height: 21px; */
        margin-bottom: 12px;
			}
		}

		strong {
			font-weight: 500;
			font-size: 24px;
			word-spacing: 1px;
      margin-bottom: 0px;

			.small {
        font-size: 12px;
				display: inline-flex;
				line-height: 0;
        
				@media (min-width: ${BREAKPOINTS.DEFAULT}px) {
          font-size: 20px;
				}
			}

			@media (min-width: ${BREAKPOINTS.DEFAULT}px) {
				font-size: 50px;
        margin-bottom: 20px;
			}
		}

		small {
			font-weight: 300;
			font-size: 16px;
			display: block;
			line-break: normal;
			padding: 0;
			
			@media (min-width: ${BREAKPOINTS.DEFAULT}px) {
				font-size: 20px;
				padding: 0;
        margin-top: 12px;
			}
		}

		hr {
			border: 1px solid ${PALLETE.pureWhite};
			width: 16px;
			height: 0px;
      margin: 15px 0;
			@media (min-width: ${BREAKPOINTS.DEFAULT}px) {
				width: 32px;
				border-width: 2px;
			}
		}

		@media (min-width: ${BREAKPOINTS.DEFAULT}px) {
			width: auto;
			line-height: 300%;
			padding: 0;
		}
	}

	@media (min-width: ${BREAKPOINTS.DEFAULT}px) {
		justify-content: space-between;
		max-width: 100%;
	}
`;
