import icons from "../../../assets/icons";
import {
	FormContainer,
	Form,
	CloseIcon,
	Divisor,
	Subtext,
	Bold,
	BackIcon,
} from "./style";
import { Button } from "../../../components";
import oneLink from "../../../assets/onlink.png";

const Confirm = ({ data, onClickOut, onBack }) => {
	return (
		<FormContainer>
			<Form>
				<BackIcon src={icons.arrowLeftSolid} alt="seta voltar" onClick={onBack} />
				<CloseIcon src={icons.xSolid} alt="close icon" onClick={onClickOut} />
				<Bold style={{ fontWeight: "400" }}>
					<Bold>
						{data?.name}, tudo certo!
						<br />
					</Bold>
					Agora é só baixar o app.
				</Bold>
				<Divisor />

				<Subtext style={{ fontSize: 20, marginBottom: 64 }}>
					Aplicativo disponível para iOs e Android
				</Subtext>
				<Subtext style={{ fontSize: 20, margin: 0 }}>
					Leia o QR code para
				</Subtext>
				<Subtext style={{ fontSize: 20, marginTop: 0, marginBottom: 24 }}>
					acessar o app pelo celular
				</Subtext>
				<img src={oneLink} style={{ height: 180, marginBottom: 84 }} />
				<div style={{ width: "75%" }}>
					<Button
						black
						label="Ok, maravilha"
						style={{ fontSize: 12, fontWeight: "700" }}
						onClick={onClickOut}
					/>
				</div>
			</Form>
		</FormContainer>
	);
};

export default Confirm;
