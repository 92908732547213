import styled from 'styled-components';
import { PALLETE } from '../../../configs/configs';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
`
export const Box = styled.div`
display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  border: ${(props) => {
    if (props.isChecked) return `${PALLETE.main} 1px solid`;
    return `${PALLETE.lightGrey} 1px solid`;
  }};
  background-color: ${(props) => {
    if (props.isChecked) return PALLETE.main;
    return 'transparent';
  }};
  border-radius: 4px;
  width: ${(props) => {
    return props.size;
  }};
  height: ${(props) => {
    return props.size;
  }};
`;
