import styled from 'styled-components';
import { PALLETE } from '../../../configs/configs';
import utils from '../../../helper/utils';
import { IMaskInput } from 'react-imask';

// ---------------------------DESKTOP--------------------------------

export const BigTitle = styled.h1`
  font-weight: 500;
  font-size: ${(props) => {
    if (props.medium) return '26px';
    return '40px';
  }};
  line-height: ${(props) => {
    if (props.medium) return '36px';
    return '52px';
  }};
  margin: 0;
  text-align: left;
  color: ${(props) => {
    if (props.color) return props.color;
    return PALLETE.black;
  }};
  span {
    font-weight: 500;
  }

  @media (max-width: 450px) {
    /* font-size: 22px; */
    font-size: ${(props) => {
      if (props.medium) return '20px';
      return '24px';
    }};
    line-height: ${(props) => {
      if (props.medium) return '28px';
      return '30px';
    }};
    /* line-height: 30px; */
  }
`;

export const FirstContent = styled.div`
  height: 100%;
  width: 1440px;
  text-align: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end; */
  justify-content: center;
  align-items: flex-start;
  padding: 128px 235px 56px 235px;

  .container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: ${utils.responsive.choose(16)(20)}px;

    span {
      margin-left: 30px;
    }
  }

  .smaller-text {
    font-weight: 400;
    font-size: 16px;
    color: #f9f9f9;
    margin: 0;
    @media (max-width: 450px) {
      font-size: 12px;
    }
  }

  .content-text {
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: ${PALLETE.white};
    width: 485px;
    margin: 20px 0 35px 0;
    text-align: left;

    @media (max-width: 450px) {
      font-size: ${utils.responsive.ajustFont(14)}px;
      line-height: 21px;
      width: 100%;
      margin: 16px 0 24px 0;
    }
  }

  @media (max-width: 450px) {
    width: 100%;
    height: 100%;
    padding: 40px 30px 0  30px;
  }
`;

export const WhiteCover = styled.div`
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  background-color: ${(props) => {
    if (props.color) return props.color;
    return '#FFFFFF';
  }};

  .icon {
      display: block;
      margin-right: ${utils.responsive.choose(8)(18)}px;
      height: ${utils.responsive.choose(20)(24)}px;
      ${utils.dataTypes.color.colorConverter(PALLETE.base).filter}
    }

  .container {
   margin-left: 230px;
  }

  .inline {
    display: inline-block;

    @media (max-width: 450px) {
      width: 100%;
    }
  }
`;

export const SecondContent = styled.div`
  text-align: center;
  width: 1440px;
  padding: 0 205px 20px 225px;
  box-sizing: border-box;
  .row {
    display: flex;
    justify-content: space-between;

    @media (max-width: 450px) {
      flex-direction: column;
    }
  }

  .screens {
    margin-top: 30px;
    height: 560px;
    width: auto;

    @media (max-width: 450px) {
      align-self: center;
      width: 115%;
      height: auto;
      margin-top: 0
    }
  }

  .content-text {
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: #070707;
    width: 485px;
    margin: 20px 0 35px 0;
    text-align: left;

    @media (max-width: 450px) {
      font-size: ${utils.responsive.ajustFont(14)}px;
      line-height: 21px;
      width: 100%;
      margin: 16px 0 24px 0;
    }
  }

  @media (max-width: 450px) {
    width: 100%;
    padding: 50px 30px 0 30px;
  }
`;

export const BorderedImageContent = styled.div`
  text-align: center;
  height: ${(props) => {
    if (props.biggerHeight) return '380px';
    return '340px';
  }};
  width: 1440px;
  padding: ${(props) => {
    if (props.withVertPadding) return '80px 141px 0 141px';
    return '0 141px';
  }};
  box-sizing: border-box;

  @media (max-width: 450px) {
    width: 100%;
    height: 100%;
    padding: 0;
  }

  .border-image {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    object-fit: cover;
    border-radius: 30px;
    position: relative;
  }

  .ancor {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .gradient {
    position: absolute;
    border-radius: 30px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: linear-gradient(183.12deg, rgba(0, 0, 0, 0) -27.24%, #000000 97.42%);
    top: 0;
  }

  .content-container {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    top: 0;
    z-index: 2;
    padding: 60px 80px 60px 195px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 450px) {
      position: static;
      padding: 60px 30px 50px 30px;
      flex-direction: column;
    }
  }

  .col {
    display: flex;
    flex-direction: column;
  }

  .row-to-end {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .icon-container {
    display: flex;
    align-items: center;

    .icon {
      height: 26px;
      ${utils.dataTypes.color.filterGenerator(PALLETE.bluieshWhite)}

      @media (max-width: 450px) {
        height: 17px;
        ${utils.dataTypes.color.filterGenerator(PALLETE.base)}
      }
    }
  }
`;

export const ThirdContent = styled.div`
  text-align: center;
  width: 1440px;
  padding: ${(props) => {
    if (props.numberedCards) return '80px 128px';
    return '60px 141px';
  }};
  box-sizing: border-box;

  @media (max-width: 450px) {
    width: 100%;
    padding: 20px 30px 50px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title {
    font-weight: 500;
    font-size: 24px;
    color: ${PALLETE.black};
    margin-bottom: 74px;

    @media (max-width: 450px) {
      font-size: ${utils.responsive.ajustFont(16)}px;
      margin-bottom: 50px;
      margin-top: 0;
    }
  }

  .row {
    display: flex;
    width: 100%;
    justify-content: space-between;

    @media (max-width: 450px) {
      flex-direction: column;
      align-items: center;
      gap: 24px;
    }
  }

  .button-row {
    margin-top: 60px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
`;

export const FourthContent = styled.div`
  text-align: center;
  width: 1440px;
  height: 692px;
  padding: 80px 235px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media (max-width: 450px) {
    width: 100%;
    padding: 50px 30px;
    height: 620px;
  }

  .text-content {
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: ${PALLETE.black};
    margin: 0 0 60px 0;
    text-align: left;
    @media (max-width: 450px) {
      font-size: ${utils.responsive.ajustFont(14)}px;
      line-height: ${utils.responsive.ajustFont(21)}px;
      margin: 0 0 30px 0;
    }
  }

  .tiny-text-fourth {
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
    color: ${PALLETE.black};
    margin: 0 0 20px 0;
    text-align: left;
    @media (max-width: 450px) {
      font-size: ${utils.responsive.ajustFont(10)}px;
      line-height: ${utils.responsive.ajustFont(15)}px;
      margin: 0 0 8px 0;
    }
  }

  .cards-row {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    gap: 44px;
    flex-wrap: wrap;

    @media (max-width: 450px) {
      gap: ${utils.responsive.ajustFont(15)}px;
      /* gap: 0; */
      justify-content: center;
      margin: 0 -30px;
      width: 100vw;
    }
  }
`;

export const FithContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 120px 225px 120px 225px;
  width: 1440px;

  @media (max-width: 450px) {
    width: 100%;
    padding: 32px 30px 0px 30px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: ${(props) => {
      if (props.center) return 'center';
      return 'flex-start';
    }};
  }



  .icon {
    ${utils.dataTypes.color.colorConverter(PALLETE.base).filter}
  }

  .bottom {
    align-self: flex-end;
    margin-bottom: 20px;
  }

  .column-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .subtitle-fith {
    font-weight: 700;
    font-size: 16px;
    color: ${PALLETE.black};
    margin: 50px 0 30px 0;
    text-align: left;
    @media (max-width: 450px) {
      margin: 24px 0;
    }
  }

  .row-fith-content {
    display: flex;
    margin-bottom: 20px;
    align-items: center;

    .icon {
      height: 32px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @media (max-width: 450px) {
      margin-bottom: 16px;
      align-items: flex-start;
      text-align: left;
    }
  }

  .slider-container {
    height: 362px;
    width: 390px;
    box-sizing: border-box;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
    border-radius: 20px;
  }

  .slider-title {
    font-weight: 400;
    font-size: 12px;
    color: ${PALLETE.black};
    margin: 0 0 30px 0;
  }

  .slider-tiny-grey {
    font-weight: 400;
    font-size: 12px;
    color: ${PALLETE.inputGrey};
    margin: 30px 0;
    @media (max-width: 450px) {
      font-size: ${utils.responsive.ajustFont(10)}px;
    }
  }

  .fith-content {
    margin: 0 0 0 42px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${PALLETE.black};

    @media (max-width: 450px) {
      font-size: ${utils.responsive.ajustFont(12)}px;
      line-height: ${utils.responsive.ajustFont(24)}px;
      margin: 0 0 0 32px;
    }
  }

  span {
    font-weight: 700;
  }
`;

export const SixthContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 80px 175px;
  width: 1440px;

  .ico {
    ${utils.dataTypes.color.filterGenerator(PALLETE.white)}
  }

  @media (max-width: 450px) {
    width: 100%;
    padding: 50px 30px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .title-six {
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;
    margin-bottom: 100px;

    @media (max-width: 450px) {
      font-size: ${utils.responsive.ajustFont(18)}px;
      line-height: ${utils.responsive.ajustFont(25)}px;
      margin: 0 0 50px 0;
    }
  }
`;

export const SeventhContent = styled.div`
  width: 1440px;
  padding: 94px 135px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  @media (max-width: 450px) {
    width: 100%;
    height: 100%;
    padding: 73px 40px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
  }

  .subtitle-seven {
    font-weight: 400;
    font-size: 24px;
    line-height: 140%;
    color: ${PALLETE.pureWhite};
    margin: 25px 0 32px 0;
    text-align: left;
    @media (max-width: 450px) {
      font-size: ${utils.responsive.ajustFont(12)}px;
      line-height: 24px;
      margin: 25px 0 30px 0;
    }
  }
`;

export const EithContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 100px 135px;
  width: 1440px;

  @media (max-width: 450px) {
    width: 100%;
    padding: 32px 30px 0 30px;
  }

  .chart-row {
    display: flex;
    width: 100%;
    @media (max-width: 450px) {
      flex-direction: column;
    }
  }

  .eith-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;
    color: ${PALLETE.black};
    margin: 0 0 50px 0;

    @media (max-width: 450px) {
      font-size: ${utils.responsive.ajustFont(22)}px;
      line-height: ${utils.responsive.ajustFont(31)}px;
      margin: 0 0 35px 0;
    }
  }
`;

export const MoneyInput = styled(IMaskInput)`
  width: 100%;
  height: 41px;
  box-sizing: border-box;
  padding: 8px 0;
  border: none;
  border-bottom: 1px solid #eeeeee;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
  caret-color: ${PALLETE.main};
  &:focus {
    outline: none;
  }
  @media (max-width: 450px) {
    font-size: 12px;
    line-height: 24px;
    height: 37px;
    padding-left: 15px;
    margin-bottom: 15px;
  }
`;

export const FakeInput = styled.div`
    width: 100%;
  height: 41px;
  box-sizing: border-box;
  padding: 8px 0;
  border-bottom: 1px solid #eeeeee;
  /* margin-bottom: 20px; */

  @keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

  .blinking-caret {
    height: 75%;
    width: 1.5px;
    background-color: ${PALLETE.base};
    animation: blink 0.7s infinite;

    @media (max-width: 450px) {
      width: 1px;
      height: 72%;
    }
  }

  @media (max-width: 450px) {
    height: 37px;
    padding-left: 15px;
    margin-bottom: 15px;
  }
`

export const NinethContet = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  // AVISAR DANI
  padding: 100px 190px;
  width: 1440px;

  @media (max-width: 450px) {
    width: 100%;
    flex-direction: column;
    padding: 32px 30px;
  }

  .nine-text-content-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 560px;
    @media (max-width: 450px) {
      /* flex-direction: column; */
      align-items: center;
    }
  }

  .nineth-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;
    color: ${PALLETE.black};
    margin: 0;

    @media (max-width: 450px) {
      font-size: ${utils.responsive.ajustFont(22)}px;
      line-height: ${utils.responsive.ajustFont(31)}px;
      margin: 0;
    }
  }

  .nineth-text-content {
    text-align: justify;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: ${PALLETE.black};
    @media (max-width: 450px) {
      font-size: ${utils.responsive.ajustFont(14)}px;
      line-height: ${utils.responsive.ajustFont(21)}px;
      margin: 0;
    }
  }
`;
