import React, { useState } from 'react';
import { Table, SliderContainer } from './style';
import { Outlined, Solid } from '../../../assets/newIcons';
import utils from '../../../helper/utils';

const { responsive } = utils;
const Desktop = ({ item, i, extend, setExtend }) => {
  return (
    <div className="column" key={`black_table_${i}`}>
      <div className="row">
        <p className="text">{item.legend}</p>
        <div style={{ display: 'flex' }}>
          <p className="text">{item.value}</p>
          <img
            className="arrow-down"
            src={Solid('arrowDownBlack')}
            style={{
              marginLeft: 32,
              transform: extend === item.legend ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
            onClick={() => setExtend(extend === item.legend ? '' : item.legend)}
          />
        </div>
      </div>
      <SliderContainer extended={extend === item.legend}>
        <p className="hidden-text">{item.content}</p>
      </SliderContainer>
    </div>
  );
};

const Mobile = ({ item, i, extend, setExtend }) => {
  return (
    <div className="column" key={`black_table_${i}`}>
      <div className="row">
        <div className="smallColumn">
          <p className="mobile-legend">{item.legend}</p>
          <p className="text">{item.value}</p>
        </div>
        <img
          className="arrow-down"
          src={Solid('arrowDownBlack')}
          style={{
            transform: extend === item.legend ? 'rotate(180deg)' : 'rotate(0deg)',
          }}
          onClick={() => setExtend(extend === item.legend ? '' : item.legend)}
        />
      </div>
      <SliderContainer extended={extend === item.legend}>
        <p className="hidden-text">{item.content}</p>
      </SliderContainer>
    </div>
  );
};

const ValuesTable = ({ arr, isWhiteTable = false, color = false }) => {
  const [extend, setExtend] = useState('');

  return (
    <Table isWhiteTable={isWhiteTable} color={color}>
      {/* {console.log(color)} */}
      {arr.map((item, i) => {
        return responsive.choose(
          <Mobile item={item} i={i} extend={extend} setExtend={setExtend} color={color} />
        )(<Desktop item={item} i={i} extend={extend} setExtend={setExtend} color={color} />);
      })}
    </Table>
  );
};

export default ValuesTable;
