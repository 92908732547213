import React, { useState, useRef, useEffect } from 'react';
import { useFetch } from '../../../hooks';
import * as Style from './style';
import { Chart, ChartMenu, Select } from '../../../components';
import utils from '../../../helper/utils';
import { PALLETE } from '../../../configs/configs';


const { clearString } = utils.dataTypes.string;
const { choose } = utils.responsive;

const Simule = ({ simuleRef }) => {
  const [samples, setSamples] = useState([]);
  const [selected, setSelected] = useState({});
  const [amount, setAmount] = useState('0');
  const [tokens, setTokens] = useState([]);
  const [loading, setLoading] = useState(true);
  const [realInput, setRealInput] = useState(false);
  const timer = useRef();
  const amountRef = useRef();
  const api2 = useFetch('web/token-list');

  useEffect(() => {
    api2.fetch('get').then((response) => {
      timer.current = setTimeout(() => {
        if (!response.data) return;
        setTokens(response.data);
        setLoading(false);
      }, 100);

      return () => {
        clearTimeout(timer.current);
      };
    });
  }, []);

  useEffect(() => {
    if (tokens.length === 0) return;
    const [selected] = tokens;
    onSelect(selected);
  }, [tokens]);

  const api = useFetch();

  function onSelect(token) {
    setSelected(token);
    const { tick } = token;
    api.fetch('get', {}, `web/simulate/${tick}`).then(({ data }) => {
      if (data.length > 0) data[0].label = 'Sapiensbank';
      data = data.map(d => ({ ...d, borderColor: PALLETE[d.borderColor] }));
      setSamples(data);
    });
  }

  const stylesheet = {
    inputsRow: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    inputsColumn: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    inputTitle: {
      fontSize: utils.responsive.ajustFont(10),
      fontWeight: '400',
      marginTop: 0,
      marginBottom: 12,
    },
  };

  return loading ? (
    <></>
  ) : (
    <Style.WhiteCover ref={simuleRef}>
      <div className="inline">
        <Style.EithContent>
          <h3 className="eith-title">Simule seu investimento</h3>
          <div className="chart-row">
            {choose(
              <>
                <div style={stylesheet.inputsRow}>
                  <div style={stylesheet.inputsColumn}>
                    <small style={stylesheet.inputTitle}>Selecione o ativo</small>
                    <Select
                      options={tokens.map((i) => {
                        return { value: i, label: `${i.tick}` };
                      })}
                      defaultValue={{
                        value: tokens[0],
                        label: `${tokens[0]?.tick}`,
                      }}
                      placeholder="Captação"
                      isSearchable={false}
                      onChange={(e) => onSelect(e.value)}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                    />
                  </div>
                  <div style={stylesheet.inputsColumn}>
                    <small style={stylesheet.inputTitle}>Qual valor voce quer investir?</small>
                    {realInput ? (
                      <Style.MoneyInput
                      ref={amountRef}
                      mask="R$ num"
                      autoFocus={true}
                      onBlur={() => {
                        if (amount === '' || amount === '0') {
                          return setRealInput(false) 
                        }
                      }}
                      blocks={{
                        num: {
                          mask: Number,
                          thousandsSeparator: '.',
                          scale: 2,
                          padFractionalZeros: true,
                          radix: ',',
                        },
                      }}
                      onAccept={(e) => {
                        setAmount(e);
                      }}
                      />
                      ) : (
                        <Style.FakeInput onClick={() => setRealInput(true)}>
                          <div className="blinking-caret" />
                        </Style.FakeInput>
                      )}
                  </div>
                </div>
                <Chart
                  yFormat="{value}%"
                  datasets={samples}
                  value={clearString(amount)}
                  delimiter="4"
                />
                <ChartMenu
                  onSelect={onSelect}
                  selectedToken={{ ...selected, ...samples[0]?.resume }}
                  amount={amount}
                  setAmount={setAmount}
                  tokens={tokens}
                  loading={loading}
                />
              </>
            )(
              <>
                <ChartMenu
                  onSelect={onSelect}
                  selectedToken={{ ...selected, ...samples[0]?.resume }}
                  amount={amount}
                  setAmount={setAmount}
                  tokens={tokens}
                  loading={loading}
                />
                <Chart yFormat="{value}%" datasets={samples} value={clearString(amount)} />
              </>
            )}
          </div>
        </Style.EithContent>
      </div>
    </Style.WhiteCover>
  );
};

export default Simule;
