import styled from 'styled-components';
import { PALLETE } from '../../configs/configs';
import utils from '../../helper/utils';

export const Toggle = styled.div`
  position: relative;
  height: 44px;
  width:${(props) => (`${props.toggleWidth * 2}px`)};
  justify-content: space-between;
  display: flex;
  background-color:  #F5F5F5;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  cursor: pointer;

  @media (max-width: 450px) {
      height: 32px;
    }

  .box {
    box-sizing: border-box;
    width: ${(props) => (`${props.toggleWidth}px`)};
    padding: 12px 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    @media (max-width: 450px) {
      font-size: ${utils.responsive.ajustFont(10)}px;
      line-height: ${utils.responsive.ajustFont(12)}px;
      padding: 10px 16px;
    }
  }

  .green-toggle {
    border-radius: 5px;
    z-index: 2;
    top: 1px;
    left: 1px;
    position: absolute;
    box-sizing: border-box;
    width:${(props) => (`${props.toggleWidth}px`)};
    height: 41px;
    background-color: ${PALLETE.base};
    transition: transform 0.3s;

    @media (max-width: 450px) {
      height: 30px;
    }
  }

  .moved {
    transform: ${(props) => (`translateX(${props.toggleWidth}px)`)}
    /* transform: translateX(193px); */
  }
  
  .back {
    transform: translateX(0px);
  }
  
  .toggle-text {
    position: relative;
    z-index: 3;
    margin: 0;
    background-color: transparent;
    color: ${PALLETE.inputGrey};
  }
  
  .text-animation-in {
    animation: text-color-in 0.3s linear forwards;
  }

  .text-animation-out {
    animation: text-color-out 0.3s linear backwards;
  }

  /* .back + .box .toggle-text {
    color: white; 
  } */

  @keyframes text-color-in {
    0% {
      color: ${PALLETE.inputGrey};
    }
    100% {
      color: ${PALLETE.pureWhite};
      font-weight: 700;
    }
  }
  @keyframes text-color-out {
    0% {
      color: ${PALLETE.pureWhite};
    }
    100% {
      color: ${PALLETE.inputGrey};
    }
  }

`;
