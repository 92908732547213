export default function useStorage() {
  return {
    set(name, data) {
      localStorage.setItem(name, data);
    },
    get(name) {
      localStorage.getItem(name);
    },
    clear() {
      localStorage.clear();
    }
  }
}