import React, { useState } from 'react';
import { Toggle } from './style';

const ToggleComp = ({
  first,
  second,
  setSelected,
  toggleWidth=210,
  selected=false,
}) => {
  const [isMoved, setIsMoved] = useState(selected);

  const moveToggle = () => {
    setIsMoved(!isMoved);
  };

  return (
    <Toggle toggleWidth={toggleWidth}>
      <div className={`green-toggle ${isMoved ? 'moved' : 'back'}`} id="green-toggle" />
      <div
        className={`box`}
        onClick={() => {
          moveToggle();
          setSelected(first);
        }}
      >
        <p className={`toggle-text ${isMoved ? 'text-animation-out' : 'text-animation-in'}`}>
          {first}
        </p>
      </div>
      <div
        className={`box`}
        onClick={() => {
          moveToggle();
          setSelected(second);
        }}
      >
        <p className={`toggle-text ${isMoved ? 'text-animation-in' : 'text-animation-out'}`}>
          {second}
        </p>
      </div>
    </Toggle>
  );
};

export default ToggleComp;
