import React from 'react';
import utils from '../../helper/utils';
import { Container, Row, Celula, SimpleText, VariationTag, TokenVariation } from './style';
import icons from '../../assets/icons';

const { dataTypes } = utils;

const dvalueStyle = {
  fontSize: 14,
  fontWeight: '700',
  margin: 0,
};

const dlegentStyle = {
  fontSize: 10,
  fontWeight: '300',
  marginTop: 0,
};

const TextGrid = ({
  rowGap = '20px',
  reverse = false,
  data = [],
  alignToRight = false,
  grey = false,
  valueStyle = dvalueStyle,
  legendStyle = dlegentStyle,
  style= {}
}) => {
  return (
    <Container style={{ paddingHorizontal: grey ? 10 : 0, paddingTop: grey ? 20 : 0,  ...style}} grey={grey}>
      {data.map((item, i) => {
        return (
          <Row key={`row+${i}`} rowGap={rowGap}>
            <Celula reverse={reverse}>
              <div
                style={{
                display: 'flex',
                  marginBottom: item.first.variation ? -2 : 0,
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <SimpleText style={valueStyle} fontSize={14}>{item.first.value}</SimpleText>
                <div/>
                {'variation' in item.first && (
                  <VariationTag down={item.first.variation < 0}>
                    <img
                        src={item.first.variation < 0 ? icons.arrowDownRed : icons.arrowUpGreen}
                        alt="variation arrow"
                    />
                    <TokenVariation down={item.second.variation < 0}>
                      {`${dataTypes.number.toMoney(item.first.variation)}%`}
                    </TokenVariation>
                  </VariationTag>
                )}
              </div>
              <SimpleText style={legendStyle}>{item.first.legend}</SimpleText>
            </Celula>
            <Celula alignToRight={alignToRight} reverse={reverse}>
              <div
                style={{
                    display: 'flex',
                  marginBottom: item.second.variation ? -2 : 0,
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                {'variation' in item.second && (
                  <VariationTag down={item.second.variation < 0}>
                    <img
                        src={item.first.variation < 0 ? icons.arrowDownRed : icons.arrowUpGreen}
                        alt="variation arrow"
                    />
                    <TokenVariation down={item.second.variation < 0}>
                      {`${dataTypes.number.toMoney(item.second.variation)}%`}
                    </TokenVariation>
                  </VariationTag>
                )}
                <SimpleText fontSize={14} style={valueStyle}>{item.second.value}</SimpleText>
              </div>
              <SimpleText style={legendStyle}>{item.second.legend}</SimpleText>
            </Celula>
          </Row>
        );
      })}
    </Container>
  );
};

export default TextGrid;
