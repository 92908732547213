import React, { useEffect, useState, useRef, useCallback, useContext } from 'react';
import { DEVICE, MODAL, PALLETE } from '../../configs/configs';

// Styles

import {
    Background,
    Modal,
    CoverImage,
    CloseIcon,
    IconContainer,
    ChildrenContainer,
    ShadowView,
} from './style';

//image
import icons from '../../assets/icons';


const MODAL_BORDER_RADIUS = 20;

export const fixedSize = (size = 'FULL') => {
    const curHeight = window.innerHeight;
    if (size.toUpperCase() === 'FULL') return curHeight;
    const ratio = curHeight / DEVICE.DEFAULT.HEIGHT;
    const sz = typeof size === 'number' ? size : MODAL[size.toUpperCase()];
    return Math.min(Math.ceil(sz * ratio), curHeight);
};


const MobModal = ({
    extended = false,
    modalTitle = false,
    children,
    size = 'small',
    onClickOut = () => { },
    onImageLoadEnd = () => { },
    justifyContent = 'flex-start',
    backgroundColor = PALLETE.white,
    image,
    noPadding = false,
    bgZIndex,
    containerStyle = {},
    closeIconStyle = {},
    closeModal = false,
    noCloseIcon = false,
    closeIconColor = PALLETE.white,
}) => {

    return (
        <>
            {extended && <Background
                onClick={() => onClickOut(false)}
                bgZIndex={bgZIndex}
            />}
            <Modal
                extended={extended}
                height={fixedSize(size)}
                bgZIndex={bgZIndex}
                backgroundColor={backgroundColor}
                full={size.toUpperCase() === 'FULL'}
            >
                <ChildrenContainer
                    noCloseIcon={noCloseIcon}
                    justifyContent={justifyContent}
                    noPadding={noPadding}
                    style={{ ...containerStyle }}
                >
                    {!noCloseIcon && (
                        <IconContainer noPadding={noPadding} modalTitle={modalTitle}>
                            {modalTitle && (
                                <p
                                    style={{
                                        fontSize: 16,
                                        fontWeight: '400',
                                        marginTop: 30,
                                        padding: 0,
                                        textAlign: 'center',
                                    }}
                                >
                                    {modalTitle}
                                </p>
                            )}
                            <CloseIcon
                                modalTitle={modalTitle}
                                noPadding={noPadding}
                                onClick={() => onClickOut(false)}
                                style={closeIconStyle}
                            >
                                <img
                                    src={icons.xSolid}
                                    alt='modal close'
                                />
                            </CloseIcon>
                        </IconContainer>
                    )}
                    {children}
                </ChildrenContainer>
            </Modal>
        </>
    );
};

export default MobModal;
