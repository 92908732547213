import { useState, useRef, useEffect } from "react";
import * as Style from "./style";
import { useFetch } from "../../hooks";
import utils from "../../helper/utils";
import TokenCarousel from "./tokenCarousel";

const { responsive } = utils;

const steps = [
	100, 1000, 5000, 10000, 15000, 20000, 30000, 40000, 50000, 60000, 70000,
	80000, 90000, 100000, 200000, 400000, 500000, 1000000,
];

export default function Calculator({ style }) {
	const [amount, setAmount] = useState(100);
	const [options, setOptions] = useState([]);
	const [selected, setSelected] = useState({});

	const api = useFetch("web/token-list?status=0or1");
	const apiIndex = useFetch("web/index-list");

	const indexes = useRef([]);
	const inputRef = useRef();

	const formatter = (noDigits) =>
		new Intl.NumberFormat("pr-BR", {
			style: "currency",
			currency: "BRL",
			maximumFractionDigits: noDigits ? 0 : 2,
		});

	const toParts = (amount) => {
		const c = formatter(true).formatToParts(amount);
		const mult = ["reais", "mil", "milhão", "bilhão"][
			c.filter((cur) => cur.type === "group").length
		];

		const [score] = c.filter((cur) => cur.type === "integer");

		return (
			<strong>
				{score.value} <small>{mult}</small>
			</strong>
		);
	};

	const calculateRentability = (aa, amount, time, absolute) => {
		if (!time || !aa) return "R$ 0,00";
		const am = aa / 100 / 12;
		amount += am * time * amount;
		const f = formatter();

		return absolute ? amount : f.format(amount);
	};

	useEffect(() => {
		inputRef.current.value = 0;
		apiIndex
			.fetch("get")
			.then((res) => {
				indexes.current = res.data;
				return;
			})
			.finally(async () => {
				const { data } = await api.fetch('get');
				// console.log(data)
				setOptions(
					data.map((item) => ({
						tick: item.tick,
						label: `One Earth Pro - ${item.tick}`,
						value: item.id,
						min: item.min,
						price: item.lastPrice,
						rentability: item.profitability,
						available: item.available,
						deadline: 36,
						icon: item.icon,
					})),
				);
				// setOptions(mock)
			});
	}, []);

	useEffect(() => {
		if (!options) return;
		setSelected({});
	}, [options]);

	return (
		<Style.Container style={style}>
			<div className="sub-container">
				<>
					{responsive.choose(<p className="carousel-title">Selecione um token:</p>)(<p className="carousel-title">Selecione o ativo:</p>)}
				{options.length > 0 &&	<TokenCarousel
						onSelect={setSelected}
						selected={selected}
						options={options}
					/>}
				</>
				{responsive.choose(
					<>
						<Style.Divisor style={{ marginBottom: 16, marginTop: 25 }} />
						{/* TODO - Ajustar o tamanho das fontes */}
						<Style.Table>
							<Style.Cel >
								<small>Disponível</small>
								<p>{selected.available || 0}</p>
							</Style.Cel>
							<Style.Cel >
								<small>Investimento mín.</small>
								<p>
									{selected.price
										? formatter()?.format(selected.min * selected.price)
										: `R$ 0,00`}
								</p>
							</Style.Cel>
							<Style.Cel >
								<small>Prazo</small>
								<p>{selected.deadline || 0} meses</p>
							</Style.Cel>
							<Style.Cel>
								<small>Rentabilidade</small>
								<p>{selected.rentability || 0}% ao ano</p>
							</Style.Cel>
						</Style.Table>

						<Style.Divisor style={{ marginTop: 16 }} />
					</>,
				)(
					<Style.Table
						style={{ marginBottom: 34, paddingRight: 40, paddingLeft: 40 }}
					>
						<Style.Cel>
							<Style.CelTitle>Disponível</Style.CelTitle>
							<Style.CelValue>{selected.available || 0}</Style.CelValue>
						</Style.Cel>
						<Style.Cel>
							<Style.CelTitle>Investimento mínimo</Style.CelTitle>
							<Style.CelValue>
								{selected.price
									? formatter(true)?.format(selected.min * selected.price)
									: `R$ 0,00`}
							</Style.CelValue>
						</Style.Cel>
						<Style.Cel>
							<Style.CelTitle>Prazo</Style.CelTitle>
							<Style.CelValue>{selected.deadline || 0} meses</Style.CelValue>
						</Style.Cel>
						<Style.Cel>
							<Style.CelTitle>Rentabilidade</Style.CelTitle>
							<Style.CelValue>
								{selected.rentability || 0}% ao ano
							</Style.CelValue>
						</Style.Cel>
					</Style.Table>,
				)}
				{/* TODO - Remover linhas no desktop - #0166 */}
				<Style.SliderContainer
					rate={
						inputRef.current?.value
							? (inputRef.current?.value / Math.max(steps.length - 1, 1)) * 99
							: 0
					}
				>
					<strong>{toParts(amount)}</strong>
					<input
						ref={inputRef}
						type="range"
						min={0}
						max={Math.max(steps.length - 1, 0)}
						step={1}
						onChange={(ev) => {
							setAmount(steps[ev.target.value]);
						}}
					/>
				</Style.SliderContainer>

				{responsive.choose(
					<Style.Table style={{ marginTop: 30, maxWidth: 735 }}>
						<Style.Cel style={{ alignItems: "flex-start" }}>
							<b>Sapiensbank</b>
							<em>CDI</em>
							<em>IPCA</em>
							<em>Poupança</em>
						</Style.Cel>
						<Style.Cel style={{ alignItems: "flex-end" }}>
							<mark className="marked">
								{calculateRentability(
									selected.rentability,
									amount,
									selected.deadline,
								)}
							</mark>
							<mark>
								{calculateRentability(
									indexes.current.find((item) => item.name === "CDI")?.aa,
									amount,
									selected.deadline,
								)}
							</mark>
							<mark>
								{calculateRentability(
									indexes.current.find((item) => item.name === "IPCA")?.aa,
									amount,
									selected.deadline,
								)}
							</mark>
							<mark>
								{calculateRentability(
									indexes.current.find((item) => item.name === "POUPANCA")?.aa,
									amount,
									selected.deadline,
								)}
							</mark>
						</Style.Cel>
					</Style.Table>,
				)(
					<Style.Table
						style={{
							marginTop: 45,
							maxWidth: 670,
							paddingRight: 40,
							paddingLeft: 40,
						}}
					>
						<Style.Cel style={{ alignItems: "flex-start" }}>
							<b>Sapiensbank</b>
							<em>CDI</em>
							<em>IPCA</em>
							<em>Poupança</em>
						</Style.Cel>
						<Style.Cel style={{ alignItems: "flex-end" }}>
							<mark className="marked">
								{calculateRentability(
									selected.rentability,
									amount,
									selected.deadline,
								)}
							</mark>
							<mark>
								{calculateRentability(
									indexes.current.find((item) => item.name === "CDI")?.aa,
									amount,
									selected.deadline,
								)}
							</mark>
							<mark>
								{calculateRentability(
									indexes.current.find((item) => item.name === "IPCA")?.aa,
									amount,
									selected.deadline,
								)}
							</mark>
							<mark>
								{calculateRentability(
									indexes.current.find((item) => item.name === "POUPANCA")?.aa,
									amount,
									selected.deadline,
								)}
							</mark>
						</Style.Cel>
					</Style.Table>,
				)}

				{responsive.choose(
					<Style.Table style={{ marginTop: 30, marginBottom: 30 }}>
						<Style.Cel style={{ alignItems: "flex-start" }}>
							<em>Quantidade de token</em>
							<em style={{ fontSize: 14, color: "#070707", margin: 0 }}>
							{selected?.price ?	<>
								{formatter(true)
									.format(amount / selected.price)
									.replace("R$", "")
									.trim()}{" "}
								{selected.tick}
									</> : "0"}
							</em>
						</Style.Cel>
						<Style.Cel style={{ alignItems: "flex-end" }}>
							<em>Rentabilidade</em>
							<em style={{ fontSize: 14, color: "#070707", margin: 0 }}>
								{selected.rentability
									? formatter().format(
											calculateRentability(
												selected.rentability,
												amount,
												selected.deadline,
												true,
											) - amount,
									  )
									: "R$0,00"}
							</em>
						</Style.Cel>
					</Style.Table>,
				)(
					<Style.Table
						style={{
							marginTop: 25,
							marginBottom: 30,
							paddingRight: 40,
							paddingLeft: 40,
						}}
					>
						<Style.Cel style={{ alignItems: "flex-start" }}>
							<em
								style={{
									fontSize: 20,
									color: "#616161",
									fontWeight: 500,
									marginBottom: 0,
								}}
							>
								Quantidade de token
							</em>
							<em style={{ fontSize: 30, color: "#070707", margin: 0 }}>
								{" "}
								{selected?.price ?	<>
								{formatter(true)
									.format(amount / selected.price)
									.replace("R$", "")
									.trim()}{" "}
								{selected.tick}
									</> : "0"}
							</em>
						</Style.Cel>
						<Style.Cel style={{ alignItems: "flex-end" }}>
							<em
								style={{
									fontSize: 20,
									color: "#616161",
									fontWeight: 500,
									marginBottom: 0,
								}}
							>
								Rentabilidade
							</em>
							<em style={{ fontSize: 30, color: "#070707", margin: 0 }}>
								{selected.rentability
									? formatter().format(
											calculateRentability(
												selected.rentability,
												amount,
												selected.deadline,
												true,
											) - amount,
									  )
									: "R$0,00"}
							</em>
						</Style.Cel>
					</Style.Table>,
				)}
			</div>
		</Style.Container>
	);
}
