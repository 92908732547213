import styled from "styled-components";
import { BREAKPOINTS, PALLETE } from "../../configs/configs";

export const Banner = styled.img`
	width: 153px;
	@media (min-width: ${BREAKPOINTS.DEFAULT}px) {
		width: 190px;
	}
`;
export const Header = styled.div`
	position: ${(props) => (props.fixed ? "fixed" : "relative")};
	background: #fdfdfd;
	z-index:11;
	@media (min-width: ${BREAKPOINTS.DEFAULT}px) {
		text-align: center;
		width: 100vw;
	}
`;

export const Container = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: #fdfdfd;
	z-index: 11;
	height: ${(props) => {
		if (!props.fixed || !props.scroll) return 90;
		const MIN_HEIGHT = 60;
		return Math.max(90 - props.scroll, MIN_HEIGHT);
	}}px;
	@media (min-width: ${BREAKPOINTS.DEFAULT}px) {
		box-sizing: border-box;
		width: 1440px;
		padding: 0 226px 0 220px;
	}
`;

export const Button = styled.a`
	@import url("https://fonts.googleapis.com/css2?family=Inter&family=Roboto:wght@300;500;700&display=swap");
	background: #070707;
	border-radius: 5px;
	color: #ffffff;
	filter: drop-shadow(1px 1px 1px ${PALLETE.base});
	font-family: "Inter", sans-serif;
	font-style: normal;
	font-size: 12px;
	font-weight: 400;
	height: 14px;
	letter-spacing: 0.011em;
	text-align: center;
	width: 88px;
	padding: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	&:hover {
		cursor: pointer;
	}

	@media (min-width: 450px) {
		width: 226px;
		height: 36px;
		padding: 0;
	}
`;

export const mobile = {
	Header: styled.div`
		position: ${(props) => (props.fixed ? "fixed" : "relative")};
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
    height: 59px;
    background-color: ${(props) => {
      if (props.backGroundColor) return props.backGroundColor
      return '#fdfdfd'
    }};
		padding: 0px 30px 15px 31px;
		box-sizing: border-box;
		width: 100%;
		z-index: 11;
	`,
	Button: styled.a`
		@import url("https://fonts.googleapis.com/css2?family=Inter&family=Roboto:wght@300;500;700&display=swap");
		background: #070707;
		border-radius: 5px;
		color: #ffffff;
		filter: drop-shadow(1px 1px 1px ${PALLETE.base});
		font-family: "Inter", sans-serif;
		font-style: normal;
		font-size: 12px;
		font-weight: 400;
		height: 14px;
		letter-spacing: 0.011em;
		text-align: center;
		width: 88px;
		padding: 5px;
		display: flex;
		align-items: center;
		justify-content: center;
	`,
	Banner: styled.img`
		width: 153px;
	`,
};
