import styled from "styled-components";
import { PALLETE } from "../../configs/configs";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	background-color: ${(props) => {
		if (props.grey) return "#03989e05";
		return "transparent";
	}};
	border-radius: 5px;
	overflow: hidden;
	width: 100%;
`;
export const Row = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: ${(props) => {
		if (props.rowGap) return props.rowGap;
		return "20px";
	}};
	align-items: center;
`;

export const Celula = styled.div`
	display: flex;
	flex-direction: ${(props) => {
		if (props.reverse) return "column-reverse";
		return "column";
	}};
	width: auto;
	align-items: ${(props) => {
		if (props.alignToRight) return "flex-end";
		return "flex-start;";
	}};
`;

export const SimpleText = styled.p`
	font-weight: ${(props) => {
		if (props.fontWeight) return props.fontWeight;
		return "400";
	}};
	font-size: ${(props) => {
		if (props.fontSize) return `${props.fontSize}px`;
		return `10px`;
	}};
	color: ${(props) => {
		if (props.color) return props.color;
		return PALLETE.black;
	}};
	margin: ${(props) => {
		if (props.margin) return props.margin;
		return "0";
	}};
  	/* @media (max-width: 380px) {
		font-size: 9px;
	} */
`;

export const VariationTag = styled.div`
	display: flex;
	align-items: center;
	border-radius: 5px;
	margin: 0 10px;
	background-color: ${(props) => {
		if (props.down) return "#d612121a";
		return "#0bf5691a";
	}};
	overflow: hidden;
	flex-direction: row;
	padding: 5px;
`;
export const TokenVariation = styled.p`
	font-weight: 700;
	font-size: 10px;
	color: ${(props) => {
		if (props.down) return PALLETE.brightRed;
		return PALLETE.main;
	}};
	margin: 0 0 0 5px;

	/* @media (max-width: 380px) {
		font-size: 9px;
	} */
`;
