import { Button } from './style';
import icons from '../../assets/icons';
import { Solid } from '../../assets/newIcons';
const SquareButton = ({
  label,
  color1,
  color2,
  onClick = () => {},
  selected = false,
  marginRight = false,
  medium = false,
  smallMob = false,
  smallerLabel,
  dot = false,
}) => {
  return (
    <>
      <Button
        smallMob={smallMob}
        medium={medium}
        selected={selected}
        color1={color1}
        color2={color2}
        onClick={onClick}
        marginRight={marginRight}
      >
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <p className="label">{label}</p>
          {smallerLabel && (
            <p className="smallerLabel" style={{ fontSize: 8, margin: 0, marginLeft: 1, marginBottom: 2 }}>
              {smallerLabel}
            </p>
          )}
        </div>
        {!dot && (selected ?  (
          <img src={icons.arrowWhiteSolid} />
        ) : (
          <img 
          className='arrow-down'
          src={Solid('arrowDownBlack')} 
          style={{
            transform: 'rotate(270deg)',
          }} />
        ))}
        {dot && <div className="dot" />}
      </Button>
    </>
  );
};

export default SquareButton;
