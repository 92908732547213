// import { useRef } from 'react';
import * as Style from './style';
import arrowIco from '../../../assets/select-arrow.svg';
import LoadGif from '../../LoadGif';
import icons from '../../../assets/icons';

export default function Button({ onClick = () => {}, ...props}) {
  const { loading, black, disabled, label, arrowWhite, arrow } = props;
  return (
    <Style.Button
      {...props}
      onClick={() => onClick()}
    >
      {loading ? <LoadGif black={black} disabled={disabled} /> : label}
      {arrowWhite ? <img src={icons.arrowWhiteSolid} alt='arrow' style={{ marginRight: 6}} /> : arrow ? <img src={arrowIco} alt='arrow' className='arrow' /> : <></>}
    </Style.Button>
  );
}