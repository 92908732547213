import styled from "styled-components";
import { PALLETE } from "../../configs/configs";

export const DotIndicator = styled.div`
	width: 6px;
	height: 6px;
	border: none;
	box-sizing: border-box;
	border-radius: 50%;
	margin: 5px 8px;

	@media (max-width: 450px) {
		width: 5px;
		height: 5px;
	}
`;

export const CarouselContainer = styled.div`
	margin: ${(props) => {
		if (props.isMob) return '0 -30px 0 -30px'
		if (props.removeOuterPadding) return "0 -8px 0 -10px";
		if (props.removeHoriPadd) return '0 0 0 10px';
		return "0";
	}};
	position: relative;


	.slider-container {
		padding: ${(props) => {
			if (props.arrows) return "10px 0px 40px 0px";
			if (props.isCalculator) return "0 0px 0 0px";
			if (props.removeHoriPadd) return '10px 8px 40px 10px';
			return "10px 8px 40px 0px";
		}};
		margin: ${(props) => {
			if (props.arrows) return "0";
			if (props.isCalculator) return "0 -10px";
			return "0";
		}};
	}

	#dotButton {
		background: transparent;
		border: none;
	}

	.not-selected {
		background: #cccccc;
	}

	.selected {
		background: #0bf569;
	}

	.carousel-slider-class {
		padding: ${(props) => {
			if (props.noPaddingLeft) return "0";
			if (props.isCalculator) return "0";
			return "0 0 0 10px";
		}};
		@media (max-width: 450px) {
			height: ${(props) => {
				if (props.isCalculator) return "100px";
				return "auto";
			}};
		}
	}

	.carousel-item-class {
		@media (max-width: 450px) {
			width: ${(props) => {
				if (props.removeHoriPadd) return 'fit-content !important';
				return 'auto'
			}};

		}
	}

	.react-multi-carousel-dot-list {
		z-index: -1 !important;
	}
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */
`;

export const ArrowRight = styled.img`
	position: absolute !important;
	top: 15px;
	z-index: 10;
	right: -45px;
	height: 32px;
	display: inline-block;
	padding: 6px;
	transform: rotate(-90deg);
`;

export const ArrowLeft = styled.img`
	position: absolute !important;
	top: 15px;
	left: -60px;
	z-index: 10;
	height: 32px;
	display: inline-block;
	padding: 6px;
	transform: rotate(90deg);
`;
