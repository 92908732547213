import React, { useState, useEffect } from 'react';
import utils from '../../helper/utils';
import { PALLETE } from '../../configs/configs';

export default function R({ datasets = [], value = 1000 }) {
  const [investment, setInvestment] = useState({});

  const stylesheet = {
    chartResumeTitle: {
      fontWeight: '500',
      fontSize: 14,
      color: PALLETE.black,
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeft: 8,
      borderLeft: `1px solid ${PALLETE.base}`,
      marginTop: 20,
      marginBottom: 20,
      textAlign: 'left',
    },
    chartRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
    },
    bigChartRow: {
      width: '100%',
      justifyContent: 'space-between',
    },
    resumeTitleChart: {
      fontWeight: '400',
      fontSize: 14,
      color: PALLETE.black,
      margin: 0,
      textAlign: 'right',
    },
    resumeValueChart: {
      fontWeight: '700',
      fontSize: 12,
      color: PALLETE.main,
      marginLeft: 8,
      marginTop: 0,
      marginBottom: 0,
      textAlign: 'left',
    },
  };

  useEffect(() => {
    const [data] = datasets;
    if (!data) return;

    const sets = Object.values(data.data).map((v) => (v / 100) * value);
    setInvestment({
      mountly: sets[0],
      annualy: sets[11] || sets[0] * 12,
      total: sets.pop(),
    });
  }, [datasets, value]);

  return (
    <>
      <div style={stylesheet.chartResumeTitle}>
        Rentabilidade do ativo <small>(correção IR)</small>:
      </div>
      <div
        style={{ ...stylesheet.chartRow, ...stylesheet.bigChartRow }}
      >
        <div style={stylesheet.chartRow}>
          {value > 100000 ? (
            <>
              <p style={{...stylesheet.resumeTitleChart, textAlign: 'left'}}>
                Valor
                <br />
                aplicado:
              </p>
              <span style={stylesheet.resumeValueChart}>
                <br />
                R$
                {utils.dataTypes.number.toMoney(value)}
              </span>
            </>
          ) : (
            <>
              <p  style={stylesheet.resumeTitleChart}>
                Valor aplicado:
              </p>
              <span style={stylesheet.resumeValueChart}>
                R$ {utils.dataTypes.number.toMoney(value)}
              </span>
            </>
          )}
        </div>
        <div  style={stylesheet.chartRow}>
          {value > 100000 ? (
            <>
              <p style={stylesheet.resumeTitleChart}>
                Rent.
                <br />
                no mês:
              </p>
              <span  style={stylesheet.resumeValueChart}>
                <br />
                R$
                {utils.dataTypes.number.toMoney(investment.mountly)}
              </span>
            </>
          ) : (
            <>
              <p  style={stylesheet.resumeTitleChart}>
                Rent. no mês:
              </p>
              <span  style={stylesheet.resumeValueChart}>
                R$ {utils.dataTypes.number.toMoney(investment.mountly)}
              </span>
            </>
          )}
        </div>
        <div style={stylesheet.chartRow}>
          {value > 100000 ? (
            <>
              <p  style={stylesheet.resumeTitleChart}>
                Rent.
                <br />
                no ano:
              </p>
              <span  style={stylesheet.resumeValueChart}>
                <br />
                R$
                {utils.dataTypes.number.toMoney(investment.annualy)}
              </span>
            </>
          ) : (
            <>
              <p style={stylesheet.resumeTitleChart}>
                Rent. no ano:
              </p>
              <span  style={stylesheet.resumeValueChart}>
                R$ {utils.dataTypes.number.toMoney(investment.annualy)}
              </span>
            </>
          )}
        </div>
        <div  style={stylesheet.chartRow}>
          {value > 100000 ? (
            <>
              <p  style={stylesheet.resumeTitleChart}>
                Rent.
                <br />
                no período:
              </p>
              <span  style={stylesheet.resumeValueChart}>
                <br />
                R$ {utils.dataTypes.number.toMoney(investment.total)}
              </span>
            </>
          ) : (
            <>
              <p style={stylesheet.resumeTitleChart}>
                Rent. no período:
              </p>
              <span style={stylesheet.resumeValueChart}>
                R$ {utils.dataTypes.number.toMoney(investment.total)}
              </span>
            </>
          )}
        </div>
      </div>
    </>
  );
}
