import * as Style from './style.js';
import womenWorking from '../../../assets/womenWorking.png';
import sapienscredito from '../../../assets/sapienscredito.svg';
import manMap from '../../../assets/images/man-map.svg'
import { PALLETE } from '../../../configs/configs.js';
import { ArrowButton, PriceSlider, TextCard, ImageCover } from '../../../components/index.js';
import contentFactory from './contentFactory.json';
import { Outlined } from '../../../assets/newIcons.js';
import utils from '../../../helper/utils.js';

const Credit = ({ creditRef }) => {
  return (
    <>
      {utils.responsive.choose(
        <>
          <Style.BorderedImageContent >
            <div className="content-container" style={{ paddingBottom: 0}}>
              <div className="icon-container">
                <img src={Outlined('building')} alt="Building" className="icon" />
                <img
                  src={sapienscredito}
                  alt="sapiensinvest"
                  width={129}
                  style={{ marginLeft: 23 }}
                />
              </div>

              <div style={{ marginTop: 32, marginBottom: 32 }}>
                <Style.BigTitle>
                  Crédito pensado para<br />
                  empresas que constroem<br />
                  <span>o futuro.</span>
                </Style.BigTitle>
              </div>

            </div>
          </Style.BorderedImageContent>

          <Style.WhiteCover>
            <Style.FithContent style={{ paddingTop: 0, paddingBottom: 32 }}>
{/*               <Style.BigTitle color={PALLETE.black} style={{ marginBottom: 24 }}>
                Vantagens do
                <br />
                Sapienscrédito privado
              </Style.BigTitle> */}
      
              {contentFactory[3].map((row, i) => {
                return (
                  <div className="row-fith-content" key={`fith-row-${i}`}>
                    <img
                      src={Outlined(row.icon)}
                      alt="row-icon"
                      style={row.iconStyleMob}
                      className="icon"
                    />
                    <p className="fith-content">
                      {row.text} <span>{row.span}</span>
                    </p>
                  </div>
                );
              })}

            </Style.FithContent>
          </Style.WhiteCover>

          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: 60 }}>
            <img src={manMap} alt="man" />
          </div>

          <Style.WhiteCover>
            <Style.ThirdContent numberedCards>
              <p className="title">Juros baixo, rápido e sem burocracia</p>
              <div className="row">
                {contentFactory[5].map((item, i) => {
                  return <TextCard item={item} i={i} key={`text-card-2-${i}`} />;
                })}
              </div>
            </Style.ThirdContent>
          </Style.WhiteCover>
        </>
      )(
        <>
          <Style.WhiteCover ref={creditRef}>
            <div className="inline">
              <Style.FithContent>
                <div className="column-content">
                  <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: 20}}>
                    <img src={Outlined('building')} alt="Building" className="icon" />
                    <img
                      src={sapienscredito}
                      alt="sapiens credit"
                      style={{ marginLeft: 30 }}
                    />
                  </div>
                  <Style.BigTitle
                    color={PALLETE.black}
                    style={{ marginBottom: 50, marginTop: 50 }}
                  >
                    Crédito pensado para empresas
                    <br />
                    que constroem o futuro
                  </Style.BigTitle>
                  {
                    contentFactory[3].map((row, i) => {
                      return (
                        <div
                          className="row-fith-content"
                          key={`fith-row-${i}`}
                        >
                          <img
                            className="icon"
                            src={Outlined(row.icon)}
                            alt="row-icon"
                            style={row.iconStyle}
                          />
                          <p className="fith-content">
                            {row.text} <span>{row.span}</span>
                          </p>
                        </div>
                      );
                    })
                  }
                </div>

                <img src={manMap} alt="man" />


              </Style.FithContent>
            </div>
          </Style.WhiteCover>
          <Style.WhiteCover color="#F5F5F5">
            <div className="inline">
              <Style.ThirdContent numberedCards>
                <p className="title">Juros baixo, rápido e sem burocracia</p>
                <div className="row">
                  {contentFactory[5].map((item, i) => {
                    return <TextCard item={item} i={i} key={`text-card-2-${i}`} />;
                  })}
                </div>
                <div className="button-row">
                  <ArrowButton label="Fale com um especialista" width={235} />
                </div>
              </Style.ThirdContent>
            </div>
          </Style.WhiteCover>
        </>
      )}
    </>
  );
};

export default Credit;
