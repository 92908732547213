import React, { useState } from "react";
import {
	BigContainer,
	BigTitle,
	Divisor,
	FullWrapper,
} from "./style";
import {
	SmallSquareButton,
	ValuesTable,
} from "../../../components";
import { Solid } from "../../../assets/newIcons";
import { PALLETE } from "../../../configs/configs";
import utils from '../../../helper/utils';

const { dataTypes } = utils

const BlackTable = ({ data }) => {
  const [button, setButton] = useState(data.table1[0].title)
  return (
    // <FullWrapper color={"#1C2025"}>
		// 		<div style={{ display: "inline-block" }}>
					<BigContainer style={{ paddingTop: 1}} color={"#1C2025"}>
						<BigTitle
							color={data.color1}
							style={{ marginTop: 85, marginBottom: 6, fontSize: 32 }}
						>
							Cenários projetados
						</BigTitle>
						<div className="row">
							<img src={data.icon} style={{ marginRight: 12 }} height={24}/>
							<BigTitle color={PALLETE.pureWhite} style={{ fontSize: 22}}>
								{data.name} - {new Date(data.createdAt).getFullYear()}
							</BigTitle>
						</div>
						<div
							className="row"
							style={{
								marginTop: 20,
								marginBottom: 76,
                justifyContent: 'space-between'
							}}
						>
              {data.table1.map(table => {
                return (
                  <SmallSquareButton
                  dot
                    color1={data.color1}
                    color2={data.color2}
                    label={table.title}
                    smallMob
                    selected={table.title === button}
                    onClick={() => setButton(table.title)}
                  />
                )
              })}
						</div>
            {/* {console.log(dataTypes.color.filterGenerator(data.color1))} */}
						<ValuesTable arr={data.table1.filter((table) => table.title === button)[0].row} mobile color={data.color1}/>
						<Divisor
							style={{ marginTop: 60, marginBottom: 60, opacity: "0.1" }}
						/>
						<BigTitle color={data.color1} style={{ marginBottom: 6,  fontSize: 32 }}>
							Garantia e mitigante
						</BigTitle>
						<div className="row" style={{ marginBottom: 76}}>
							<img src={data.icon} style={{ marginRight: 12 }} height={24} />
							<BigTitle color={PALLETE.pureWhite}  style={{ fontSize: 22}}>
								{data.name} - {new Date(data.createdAt).getFullYear()}
							</BigTitle>
						</div>
						<ValuesTable arr={data.table2} mobile color={data.color1}/>
						<div style={{ height: 60}} />
					</BigContainer>
			// 	</div>
			// </FullWrapper>
  )
}

export default BlackTable;