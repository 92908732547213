import styled from "styled-components";

export const RibbonContainer = styled.div`
  position: absolute;
  top: -6px;
  left: -6px;
  z-index: 5;
  @media (max-width: 450px) {
    top: -3px;
    left: -3px;
  }

`
export const RibbonTextContainer = styled.div`
  height: 35px;
  left: -3px;
  position: absolute;
  top:11px;
  transform: rotate(-46deg);
  width: 60px;
  justify-content: center;
  @media (max-width: 450px) {
    top: 2px;
    left: -9px;
  }
`
export const RibbonText = styled.p`
  font-size: 13px;
  text-align: center;
  font-weight: 700;
  color: white;
  @media (max-width: 450px) {
    font-size: 8px;
  }
`

export const Ribbon = styled.img`
  height: ${(props) => {
    if (props.height) return `${props.height}px`
    return 'auto'
  }};
   width: ${(props) => {
    if (props.width) return `${props.width}px`
    return 'auto'
  }};
  @media (max-width: 450px) {
    height: ${(props) => {
        if (props.height) return `${props.height/2}px`
        return 'auto'
      }};
       width: ${(props) => {
        if (props.width) return `${props.width/2}px`
        return 'auto'
      }};
  }
`