import React from 'react';
import { Card } from './style';
import icons from '../../../assets/icons';
import utils from '../../../helper/utils'

const { responsive } = utils

export default function ColouredCard({
    icon = 'houseGreenOutlined',
    text = 'botão clicavel',
    onClick = () => { },
    color,
}) {
    return (
        <Card
        color={color}
            // type="button"
            onClick={onClick}
        >
            <img
                align="left"
                className='card-icon'
                alt='icon in card'
                src={icons[icon]}
            />
            <p className='card-text'>{text.split('<br/>')[0]}<br/>{text.split('<br/>')[1]}</p>
        </Card>
    )
}