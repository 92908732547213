import React from 'react';
import { ImageContainer } from './style';

export default ({
  image,
  children,
  height = 612,
  introRef,
}) => {
  return (
    <ImageContainer height={height} ref={introRef}>
      <img className="background-image" src={image} alt="background-image" />
      <div className="gradient" />
      <div className="content-container">
        <div className='inline-cover'>{children}</div>
      </div>
    </ImageContainer>
  );
};
