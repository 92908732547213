import * as Style from './style.js';
import { PALLETE } from '../../../configs/configs.js';
import screenShoot3 from '../../../assets/screenShot3.png';
import screenShot5 from '../../../assets/screenShot5.png'
import utils from '../../../helper/utils.js';
import icons from '../../../assets/icons';

export default ({  }) => {
  return (
    <Style.WhiteCover>
      <div className="inline">
        <Style.SecondContent>
          <div className="row">
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Style.BigTitle
                color={PALLETE.black}
                style={{ marginTop: utils.responsive.choose(0)(134) }}
              >
                Coloque seu dinheiro
                <br />
                onde seus valores estão.
              </Style.BigTitle>
              <p className="content-text">
                O mercado financeiro tem um papel fundamental para enfrentarmos 
                os desafios dos próximos anos, e o ESG é essencial nas nossas
                estratégias de facilitar o acesso à uma Renda Fixa digital através
                das nossas operações de Crédito Privado, Crédito Imobiliário e Ativos
                Ecológicos, de forma segura e responsável.
              </p>
              <div style={{ display: 'flex' }}>
                <img
                  src={icons.downloadAppStore}
                  alt="store"
                  height={utils.responsive.choose(40)(48)}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    window.open('https://apps.apple.com/br/app/sapiensbank/id1572548699');
                  }}
                />
                <img
                  src={icons.downloadGoogleStore}
                  alt="store"
                  height={utils.responsive.choose(40)(48)}
                  style={{ marginLeft: 10, cursor: 'pointer' }}
                  onClick={() => {
                    window.open(
                      'https://play.google.com/store/apps/details?id=com.sapiensbank.sapiensbank'
                    );
                  }}
                />
              </div>
            </div>
            <img
              src={screenShot5}
              alt="store"
              className="screens"
            />
{/* <img
  src={utils.responsive.choose(screensMobile)(screensDesktop)}
  alt="store"
  className="screens"
/> */}
          </div>
        </Style.SecondContent>
      </div>
    </Style.WhiteCover>
  );
};
