import { useReducer, useRef, useCallback, useState, useEffect } from 'react';
import 'pixi.js';
import './style.css';
import utils from '../../helper/utils';
import { Stage, Sprite, useTick, Container, Text } from '@pixi/react';
import slice from './path.png';
import sliceBlur from './path_blur.png';
import { PALLETE } from '../../configs/configs';
//commit

const reducer = (_, { data }) => data;
const Selector = (props) => {
  const { rotation, ...propList } = props;

  const i = useRef(0.0);
  const speed = useRef(props.speed || 0.1);

  const [clockwise, setClockwise] = useState(false);
  const [motion, update] = useReducer(reducer);
  const [locked, setLocked] = useState(false);

  useEffect(() => {
    const r = rotation%(2*Math.PI);
    setClockwise(r > i.current);
    setLocked(false);
  }, [rotation])

  useTick((delta) => {
    if (locked) return;
    const r = rotation%(2*Math.PI);

    if (clockwise) {
      if (i.current > r) return;
      i.current += (speed.current * delta);
    } else {
      if (i.current < r) return;
      i.current -= (speed.current * delta);
    }

    if ((Math.abs(i.current - r) * delta) < ((speed.current * 1.5) * delta) ) {
      i.current = rotation;
      setLocked(true);
    }

    i.current = Math.abs(i.current%(2*Math.PI));
    update({
      type: 'update',
      data: { rotation: i.current },
    });
  });

  return (
    <Sprite
      {...propList}
      image={sliceBlur}
      scale={0.3}
      anchor={{ x: 0.5, y: -0.61 }}
      tint={parseInt(PALLETE.base.replace('#', ''), 16)}
      {...motion}
    />
  )
}

const Slice = (props) => {
  const {
    onClick = () => {},
    onHover = () => {},
    ...propList
  } = props;

  const [hover, setHover] = useState(false);
  const ajustLight = useCallback(utils.dataTypes.color.ajustLightColor, []);
  const ajustSat = useCallback(utils.dataTypes.color.ajustSatColor, []);

  useEffect(() => {
    onHover(hover);
  }, [hover])

  return (
    <Sprite
      {...propList}
      interactive
      image={slice}
      anchor={{ x: 0.5, y: 0.5 }}
      scale={0.3}
      tint={(hover && !utils.responsive.isMobile) ? (
        parseInt(
          ajustSat(ajustLight(PALLETE.base, 1.6), 0.7).replace('#', ''),
          16,
        )
      ): 0xcccccc}
      pointerdown={onClick}
      pointerover={() => setHover(true)}
      pointerout={() => setHover(false)}
    />
  )
}

const Menu = ({ size = 380, zoom = 1, data = [], onSelect = () => {} }) => {
  const [selectedId, setSelectedId] = useState(0);
  const canvas = useRef();

  useEffect(() => {
    onSelect(selectedId);
  }, [selectedId])

  const distribute = useCallback((i, radius) => {
    const angleStep = (2 * Math.PI) / data.length;
    return {
      x: radius * Math.cos(angleStep * i),
      y: radius * Math.sin(angleStep * i),
      rotation: (1.5*Math.PI) + ((i / data.length) * (2 * Math.PI)),
    }
  }, [data.length]);

  return (
    <Stage
      ref={canvas}
      width={size}
      height={size}
      options={{ backgroundAlpha: 0, antialias: true }}
    >
      <Container
        position={[size/2, size/2]}
        rotation={(1.75/data.length) * (2*Math.PI)}
        scale={zoom}
      >
        {
          data.map((_, i) => {
            const pos = distribute(i, 118);
            return (
              <Slice
                key={`area_${i}`}
                x={pos.x}
                y={pos.y}
                rotation={pos.rotation}
                onClick={() => setSelectedId(i)}
                // onHover={(sel) => hover.current = sel ? i : -1}
              />
            )
          })
        }
        <Selector rotation={distribute(selectedId).rotation} />
        {
          data.map((btn, i) => {
            const pos = distribute(i, 122);
            return (
              <Sprite
                key={`icon_${i}`}
                scale={i === selectedId ? 0.13 : 0.1}
                anchor={{ x: 0.5, y: 0.5 }}
                x={pos.x}
                y={pos.y}
                image={btn.icon}
                rotation={1.5*Math.PI}
                tint={
                  (i === selectedId) ? 0xffffff : 0x757575
                }
              />
            )
          })
        }
      </Container>
      <Text
        position={[size/2, size/2]}
        anchor={{ x: 0.5, y: 0.5 }}
        text={data[selectedId].title}
        style={{
          fontFamily: 'Inter',
          textAlign: 'center',
          fontSize: 20 * zoom,
          color: '#212121',
        }}
      />
    </Stage>
  );
};

export default Menu;
