import * as Style from './style';
import utils from '../../helper/utils';
import { Solid } from '../../assets/newIcons';

const { responsive } = utils;
const Desktop = ({ fixed, onClickDesktop, scroll, data }) => {
  return (
    <Style.Header fixed={fixed}>
      <div style={{ display: 'inline-block' }}>
        <Style.Container fixed={fixed} scroll={fixed && scroll} isScrolling={false}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={Solid(data.iconLeaf)} style={{ marginRight: 10 }} />
            <img src={Solid('logoOutlinedBlack')} />
          </div>
          <Style.Button
            fixed={fixed}
            onClick={onClickDesktop}
            color1={data.color1}
            color2={data.color2}
          >
            Criar conta
          </Style.Button>
        </Style.Container>
      </div>
    </Style.Header>
  );
};

export default Desktop;
