import React, { useEffect, useState, useRef } from 'react';

// sections
import {
  Intro,
  AppIntro,
  SapiensInvest,
  Captions,
  SapiensCredit,
  AluVerde,
  Neutralization,
  End,
  Simule,
  InteractiveMenu
} from './sections';

// components
import { Footer, ModalLateral, NewHeader } from '../../components';
import DesktopForm from '../register/DeskTopForm';

// services
import utils from '../../helper/utils';
import icons from '../../assets/icons';
import configs from '../../configs/configs';

function Desktop({ opportunities, closed }) {
  const [showModal, setShowModal] = useState(false);
  const [oportunityList, setOpportunityList] = useState([]);
  //const [selectedOportu, setSelectedOportu] = useState('Captações abertas');
  const [selectedAV, setSelectedAV] = useState('Para você');
  const [isDivAtTop, setIsDivAtTop] = useState(false);
  const investRef = useRef(null);
  const creditRef = useRef(null);
  const avRef = useRef(null);
  const introRef = useRef(null);
  const simuleRef = useRef(null)

  useEffect(() => {
    if (!Array.isArray(opportunities) || opportunities.length === 0) return;
    Promise.all(
      opportunities.map(async (opp) => {
        try {
          const color = await utils.files.image.extractColor(icons[opp.icon]);
          opp.color = color;
        } catch (_) { opp.color = configs.PALLETE.main }
        return opp;
      })
    )
    .then((res) => {
      setOpportunityList(res);
    })
    .catch((err) => {
      console.log('Error:', err);
    }); 
  }, [opportunities]);

  useEffect(() => {
    const handleScroll = () => {
      if (introRef.current) {
        const { top } = introRef.current.getBoundingClientRect();
        setIsDivAtTop(top < 0);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <NewHeader
        investRef={investRef}
        creditRef={creditRef}
        avRef={avRef}
        white={isDivAtTop}
        onClickDesktop={() => {
          setShowModal(true);
        }}
      />
      <Intro simuleRef={simuleRef} introRef={introRef}/>
      <AppIntro />
      <SapiensInvest investRef={investRef} simuleRef={simuleRef} />
      <Captions
        //selectedOportu={selectedOportu}
        //setSelectedOportu={setSelectedOportu}
        oportunityList={oportunityList}
        closed={closed}
      />
      <Simule simuleRef={simuleRef}/>
      <InteractiveMenu />
      <SapiensCredit creditRef={creditRef} />
      <AluVerde setSelectedAV={setSelectedAV} selectedAV={selectedAV} avRef={avRef} />
      <Neutralization />
      <Footer />
{/*       <End simuleRef={simuleRef}/> */}
      <ModalLateral showForm={showModal} onClickOut={() => setShowModal(false)}>
        <DesktopForm onClickOut={() => setShowModal(false)} />
      </ModalLateral>
    </>
  );
}

export default Desktop;
