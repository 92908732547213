import styled from "styled-components";
import { PALLETE } from "../../../configs/configs";
import utils from '../../../helper/utils'

const {dataTypes} = utils

export const Table = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
  gap: 30px;

  .arrow-down {
    ${(props) => {
      if (props.color) return dataTypes.color.filterGenerator(props.color);
      return 'filter: none';
    }};
  }


	.column {
		display: flex;
		flex-direction: column;
		width: 50%;
	}

	.small-column {
		display: flex;
		flex-direction: column;
		border-bottom: 1px solid ${PALLETE.greyLine};
	}

	.row {
		display: flex;
		flex-direction: row;
		padding: 17px 0;
    justify-content: space-between;
	}

	.legend {
		font-weight: 400;
		font-size: 12px;
		margin: 0;
		color: ${PALLETE.inputGrey};
	}

	.value {
		font-weight: 600;
		font-size: 16px;
		margin: 0;
		color: ${PALLETE.inputGrey};
    &:hover {
      color: ${(props) => (props.color)};
    }
	}
  
  .small-row {
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:hover .value {
      color: ${(props) => (props.color)};
    }
  }
  img {
    &:hover {
      cursor: pointer;
    }
  }
  .hidden-text {
		font-weight: 300;
		font-size: 12px;
		margin: 0;
		color: ${PALLETE.inputGrey};
    text-align: justify;
	}
`;

export const SliderContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	transition: max-height 1s;
	width: 100%;
	padding: ${(props) => {
    if (props.extended) return '0 0 16px 0'
    return '0'
  }};
	max-height: ${(props) => {
		if (props.extended) return "192px";
		return "0";
	}};
	overflow: hidden;
`;
