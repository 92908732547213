import styled from 'styled-components'
import { PALLETE } from '../../../configs/configs'

export const BigContainer = styled.div`
    box-sizing: border-box;
    height: 80vh;
    width: 100%;
    padding: 0 30px 120px 30px;
    background: ${PALLETE.white};
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: scroll;

    img {
        width: 315px;
        height: 282px;
    }
`

export const Text = styled.p`
    font-weight: 600;
    font-size: 25px;
    color: ${PALLETE.black};
    margin-bottom: 20px;
    margin-top: 50px;
`

export const Subtext = styled.div` 
    color: ${PALLETE.inputGrey};
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 40px;
`