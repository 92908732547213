import { BREAKPOINTS, DEVICE } from "../configs/configs";
import axios from "axios";
import { getColor, colorConverter } from "./filterColor";

const defaultWIDTH = 1440

const helper = {
	// Analytics
	analitics: {
		maze(m, a, z, e) {
			let s, t;
			try {
				t = m.sessionStorage.getItem("maze-us");
			} catch (err) {
				console.error(err);
			}

			if (!t) {
				t = new Date().getTime();
				try {
					m.sessionStorage.setItem("maze-us", t);
				} catch (err) {
					console.error(err);
				}
			}

			s = a.createElement("script");
			s.src = z + "?t=" + t + "&apiKey=" + e;
			s.async = true;
			a.getElementsByTagName("head")[0].appendChild(s);
			m.mazeUniversalSnippetApiKey = e;
		},
		hotjar(h, o, t, j, a, r) {
			h.hj =
				h.hj ||
				function () {
					(h.hj.q = h.hj.q || []).push(arguments);
				};
			h._hjSettings = { hjid: 3454155, hjsv: 6 };
			a = o.getElementsByTagName("head")[0];
			r = o.createElement("script");
			r.async = 1;
			r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
			a.appendChild(r);
		},
	},

	// Gerais
	dataTypes: {
		string: {
			clear(str) {
				if (typeof str !== "string") throw new Error("Invalid format");
				return str.replace(/[^\d]+/g, "");
			},
      clearString(value) {
        return Number(value.replaceAll('.', '').replace(',', '.').replace('R$ ', ''));
      }
		},
		number: {
			toMoney(number) {
				if (number === "--") return number;
				const intl = new Intl.NumberFormat("pt-BR", {
					style: "currency",
					currency: "BRL",
				});
				return intl.format(number).replace("R$", "");
			},
			toFixedNoRound(num, precision = 2) {
				if (num === "--") return num;
				const factor = Math.pow(10, precision);
				return Math.floor(num * factor) / factor;
			},
			toOrdinal(number, decimals = 2) {
				if (number === 0) return number;
				if (!number || number === "--") return "--";
				if (typeof number !== "number" || number < 1000) return number;
				number =
					// Nine Zeroes for Trillions
					Math.abs(Number(number)) >= 1.0e12
						? `${this.toFixedNoRound(number / 1.0e12, decimals)} T`
						: // Nine Zeroes for Billions
						Math.abs(Number(number)) >= 1.0e9
						? `${this.toFixedNoRound(number / 1.0e9, decimals)} Bn`
						: // Six Zeroes for Millions
						Math.abs(Number(number)) >= 1.0e6
						? `${this.toFixedNoRound(number / 1.0e6, decimals)} M`
						: // Three Zeroes for Thousands
						Math.abs(Number(number)) >= 1.0e3
						? `${this.toFixedNoRound(number / 1.0e3, decimals)} K`
						: Math.abs(Number(number));
				return number.replace(/\./gi, ",");
			},
			withSeparator(x, separator = ".") {
				if (x === 0) return x;
				if (x > 1000000) return this.toOrdinal(Number(x));
				if (!x || x === "--") return "--";
				x = x.toString();
				const pattern = /(-?\d+)(\d{3})/;
				while (pattern.test(x)) x = x.replace(pattern, `$1${separator}$2`);
				return x;
			},
			toPhone(num) {
				const mask = "+55 ($1) $2-$3",
					pattern = /^(\d{2})(\d{5})(\d{4})$/;
				return String(num).replace(pattern, mask);
			},
		},
		date: {
			format(date) {
				return date.toISOString().split("T")[0];
			},
			datetimeToDate(datetime) {
				if (datetime === "--") return datetime;
				if (!datetime) return "--";
				const [year, month, day] = this.format(datetime).split("-");
				return `${day}/${month}/${year}`;
			},
			numberToMonth(number) {
				return [
					["Janeiro", "JAN"],
					["Fevereiro", "FEV"],
					["Março", "MAR"],
					["Abril", "ABR"],
					["Maio", "MAI"],
					["Junho", "JUN"],
					["Julho", "JUL"],
					["Agosto", "AGO"],
					["Setembro", "SET"],
					["Outubro", "OUT"],
					["Novembro", "NOV"],
					["Dezembro", "DEZ"],
				][number - 1];
			},
		},
		color: {
			hexToRgbAOpacity(hex, opacity) {
				var c;
				c = hex.substring(1).split("");
				if (c.length === 3) {
					c = [c[0], c[0], c[1], c[1], c[2], c[2]];
				}
				c = "0x" + c.join("");
				return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(
					",",
				)},${opacity})`;
				// "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + "," + opacity + ")"
			},
			ajustSatColor(hexColor, rate = 1) {
				// Remover o símbolo '#' se estiver presente
				hexColor = hexColor.replace('#', '');
				const r = 1 - rate;
			
				// Converter o hex em valores RGB
				const red = parseInt(hexColor.slice(0, 2), 16);
				const green = parseInt(hexColor.slice(2, 4), 16);
				const blue = parseInt(hexColor.slice(4, 6), 16);
			
				// Calcular os novos valores de RGB com 70% menos saturação
				const desaturatedRed = Math.floor(red * r + rate * (red + green + blue) / 3);
				const desaturatedGreen = Math.floor(green * r + rate * (red + green + blue) / 3);
				const desaturatedBlue = Math.floor(blue * r + rate * (red + green + blue) / 3);
			
				// Converter os valores de volta para hexadecimal
				const desaturatedHex = `#${desaturatedRed.toString(16).padStart(2, '0')}${desaturatedGreen.toString(16).padStart(2, '0')}${desaturatedBlue.toString(16).padStart(2, '0')}`;
			
				return desaturatedHex;
			},
			ajustLightColor(hexColor, rate = 1) {
				// Remover o símbolo '#' se estiver presente
				hexColor = hexColor.replace('#', '');

				// Converter o hex em valores RGB
				const red = parseInt(hexColor.slice(0, 2), 16);
				const green = parseInt(hexColor.slice(2, 4), 16);
				const blue = parseInt(hexColor.slice(4, 6), 16);
			
				// Calcular os novos valores de RGB com 70% a mais de iluminação
				const increasedRed = Math.min(255, Math.floor(red * rate));
				const increasedGreen = Math.min(255, Math.floor(green * rate));
				const increasedBlue = Math.min(255, Math.floor(blue * rate));
			
				// Converter os valores de volta para hexadecimal
				const increasedHex = `#${increasedRed.toString(16).padStart(2, '0')}${increasedGreen.toString(16).padStart(2, '0')}${increasedBlue.toString(16).padStart(2, '0')}`;
			
				return increasedHex;
			},
      filterGenerator(hex) {
        return getColor(hex)
      },
			colorConverter,
		},
	},

	// Files
	files: {
		image: {
			async extractColor(url) {
				try {
					const { data } = await axios.get(url);
					const regex = /#[0-9a-f]{3,6}/gi;
					const [color] = regex.exec(data);
					return color;
				} catch (err) {
					console.error(err);
					return "#0f0f0f50";
				}
			},
		},
	},

	// Responsividade
	responsive: {
		isMobile: window.innerWidth < BREAKPOINTS.DEFAULT,
		choose: (mobile) => (desktop) => {
			return window.innerWidth < BREAKPOINTS.DEFAULT
				? mobile || <></>
				: desktop || <></>;
		},
		ajustSize(w, h) {
			return {
				width: Math.floor(window.innerWidth * w) / DEVICE[DEVICE.USE].WIDTH,
				height: Math.floor(window.innerHeight * h) / DEVICE[DEVICE.USE].HEIGHT,
			};
		},
		ajustFont(size) {
			return this.ajustSize(size).width;
		},
    ajustFontDesktop(size) {
      return  Math.floor(window.innerWidth * size) / 1440
    },
    ajustOnWidth(num) {
      const result = (num * window.innerWidth) / defaultWIDTH
      // console.log(window.innerWidth)
      // console.log(result)
      return result > num ? num : result;
    },
    scrollToContent(ref) {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: 'smooth' });
      }
    },
	},
};

export default helper;
