import styled from 'styled-components';
import { PALLETE } from '../../../configs/configs';
import utils from '../../../helper/utils'

import { IMaskInput } from 'react-imask';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 340px;
  box-sizing: border-box;
  padding: 60px 16px 0px 16px;
  @media (max-width: 450px) {
      width: 100%;
      padding: 0 0 32px 0;
    }


  .inputs-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 81px;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 30px;
  }

  p {
    font-size: 14px;
    color: ${PALLETE.black};
    font-weight: 400;
    margin: 0;
  }
  .selected-title {
    font-size: 12px;
  }
  .tiny-label {
    font-size: 10px;
  }
  .green {
    font-weight: 700;
    color: ${PALLETE.base};
    margin-left: 8px;
  }
  .tag-content {
    font-size: 12px;
    font-weight: 600;
  }

  .no-margin {
    margin: 0;
  }

  .menu-tag {
    display: flex;
    align-items: center;
    background: ${utils.dataTypes.color.hexToRgbAOpacity(PALLETE.base, 0.2)};
    border-radius: 3px;
    box-sizing: border-box;
    padding: 6px 3px;
    margin-left: 6px;
  }
  .dot {
    width: 8px;
    height: 8px;
    background: ${PALLETE.base};
    border-radius: 8px;
    margin-right: 16px;
  }
  .menu-row {
    display: flex;
    align-items: center;
  }

  .row-for-tags {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .space {
    justify-content: space-between;
  }
  .bottom {
    margin-bottom: 16px;
  }
  .right {
    margin-right: 8px;
    @media (max-width: 450px) {
      margin-right: 16px;
    }
  }

  .align-right {
    border: 1px solid red;
    justify-self: end;
  }

  .left {
    margin-left: 8px;
  }
  .line {
    width: 100%;
    border: 1px solid #eeeeee70;
  }
  .first {
    margin: 17px 0 20px 0;
  }
  .second {
    margin-top: 20px;
  }

  .menu-cluster {
    display: flex;
    flex-direction: column;
  }
  .start {
    align-items: flex-start;
  }

  .end {
    align-items: flex-end;
  }
  .margin-cluster {
    margin-bottom: 24px;
  }

  .mobile-menu-chart-title {
    font-weight: 500;
    font-size: ${utils.responsive.ajustFont(14)}px;
    line-height:${utils.responsive.ajustFont(14)}px;
    color: ${PALLETE.black};
    padding: 0 8px;
    border-left: 1px solid ${PALLETE.base};
    margin: 0 24px 0 0;
  }
`;

export const MoneyInput = styled(IMaskInput)`
  width: 100%;
  height: 41px;
  box-sizing: border-box;
  padding: 8px 0;
  border: none;
  border-bottom: 1px solid #eeeeee;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
  &:focus {
    outline: none;
  }
  caret-color: ${PALLETE.main};
`;


export const FakeInput = styled.div`
  width: 100%;
  height: 41px;
  box-sizing: border-box;
  padding: 8px 0;
  border-bottom: 1px solid #eeeeee;
  cursor: text;

  @keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

  .blinking-caret {
    height: 75%;
    width: 1.5px;
    background-color: ${PALLETE.base};
    animation: blink 0.7s infinite;
  }
`