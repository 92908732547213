import { PALLETE } from '../../configs/configs';
import styled from 'styled-components';
import { BREAKPOINTS } from '../../configs/configs';


export const FooterWrapper = styled.div`
  width: 100%;
  text-align: center;
  background-color: ${PALLETE.white};
`
export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${PALLETE.white};
  padding: 60px 30px 30px 30px;
  z-index: 3;
  color: ${PALLETE.black};

  a {
    color: ${PALLETE.black} !important;
    font-weight: 500;
    text-decoration: underline !important;
  }

  .socialMedia {
    margin-top: 135px;
    margin-left: 30px;

    @media (max-width: 450px) {
      margin-top: 15px;
      height: 16px;
      margin-left: 0;
    }
  }

  .bigLogos {
    margin: 30px 0;
  }

  .mediumLogos {
    margin: 30px 0;
  }

  @media (min-width: ${BREAKPOINTS.DEFAULT}px) {
    padding: 60px 226px 30px 226px;
    width: 1440px;
    box-sizing: border-box;
    position: relative;
  }
`
export const FooterTitle = styled.p`
  font-weight: 700;
  font-size: 12px;
  margin-bottom: 20px;
  text-align: left;
  @media (max-width: 450px) {
   margin-top: ${(props) => {
    if (props.marginTopBaixe) return '30px'
    if (props.marginTopMenos) return '10px'
    return '0'
   }};
  }
`
export const FooterTxt = styled.p`
  font-weight: 300;
  font-size: 12px;
  color: ${PALLETE.inputGrey};
  text-align: left;
  margin-bottom: ${(props) => {
    if (props.marginBottom) return '0'
    return '20px'
  }};

  a {
    text-decoration: none !important;
    font-weight: 300 !important;
  }
`
export const FooterSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 450px) {
    flex-direction: column;
    align-items: center;
  }
`
export const IconsButton = styled.div`
  border: none;
  background-color: transparent;
  margin-right: 20px;
  &:hover {
    cursor: pointer;
  }

`
export const SmallFooterText = styled.p`
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: ${PALLETE.inputGrey};
  text-align: center;
  margin: 30px 0;
  @media (max-width: 450px) {
    text-align: ${(props) => {
      if (props.justify) return 'justify'
      return 'center'
    }}
  }
`
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${(props) => {
    if (props.gap) return `${props.gap}px`
    return '0'
  }};
  width: ${(props) => {
    if (props.width) return `${props.width}`
    return ''
  }};
`
export const GreyText = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  margin: 0;
  color: ${PALLETE.inputGrey};
  @media (max-width: 450px) {
    font-size: 12px;
  }
`
export const GreyLine = styled.div`
  height: 0px;
  width: 100%;
  border: 0.2px solid ${PALLETE.whitestGrey};
  margin: ${(props) => {
    if (props.small) return '30px 0'
    return '60px 0'
  }};
  @media (max-width: 450px) {
    margin: ${(props) => {
      if (props.small) return '15px 0'
      return '30px 0'
    }};
    opacity: 0.5;
  }
`
export const PartnerIcon = styled.div`
  height: 62px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 30px;
  &:hover {
    cursor: pointer;
  }
`
