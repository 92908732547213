import helper from "../../../helper/utils";
import { PALLETE } from "../../../configs/configs";
// investimento comprado

const { dataTypes } = helper;

export const getBasicInfo = ({ data }) => [
	{
		first: {
			value: `R$ ${dataTypes.number.toMoney(data?.marketCap)}`,
			// value:  `R$ 400.000,00`,
			legend: "Market cap",
			variation: data.marketVariation || 0,
		},
		second: {
			value: `${dataTypes.number.withSeparator(data?.totalTokens)} ${
				data?.token
			}`,
			legend: "Total de tokens",
		},
	},
	{
		first: {
			value: `${data?.deadline} meses`,
			legend: "Prazo",
		},
		second: {
			value: `R$ ${dataTypes.number.toMoney(data?.lastPrice)}`,
			legend: "Último preço",
			variation: data.priceVariation || 0,
		},
	},
];

export const getInvestDates = ({ data }) => [
	{
		first: {
			value: dataTypes.date.datetimeToDate(data?.startedAt),
			legend: "Data de emissão",
		},
		second: {
			value: dataTypes.date.datetimeToDate(data?.paymentStartedAt),
			legend: "Início do Pagamento",
		},
	},
	{
		first: {
			value: dataTypes.date.datetimeToDate(data?.paymentOverAt),
			legend: "Último Pagamento",
		},
		second: {
			value: dataTypes.date.datetimeToDate(data?.withdrawAllowedAt),
			legend: "Queima da Token",
		},
	},
];

export const getInvestDetails = ({ data }) => [
	{
		first: {
			value: `R$ ${dataTypes.number.toMoney(data?.pago)}`,
			legend: "Pago",
		},
		second: {
			value: `${data?.expiredDeadline} meses`,
			legend: "Prazo para vencimento",
		},
	},
	{
		first: {
			value: `${
				data.index !== null ? data.index + " + " : ""
			}R$ ${dataTypes.number.toMoney(data?.monthParcel)}`,
			legend: "Projetado mensal",
		},
		second: {
			value: `${
				data.index !== null ? data.index + " + " : ""
			}R$ ${dataTypes.number.toMoney(data?.finalRentability)}`,
			legend: "Rentabilidade projetada",
		},
	},
];

// export const responseToRef = (ref, dt ) => {
//   const { current } = ref;

//   current.marketCap = dt.data.circulatingSupply * (dt.resume.price.current || dt.resume.price.ico)
//   current.token = dt.tokenTick
//   current.totalTokens = dt..data.maxSupply
// }

// responseToRefNew = {
// marketCap: res.data.data.circulatingSupply * (res.data.resume.price.current || res.data.resume.price.ico),
// token: res.data.tokenTick,
// total de Tokens: res.data.data.maxSupply,
// prazo: res.data.data.deadline
// ultimo preço : res.data.resume.price.current || res.data.resume.price.ico
// data de emissao: res.data.createdAt
// inicio de pagamento: res.data.tradeAt + 1 mes
// ultimo pagamento: res.data.burnAt - 1 mes
// queima da token: res.data.burnAt
// pago: res.data.data.paid
// Prazo para vencimento: res.data.resume.deadline.remains
// projetado mensal: resume.payment.expected * res.data.data.circulatingSupply
// index ?
// rentabilidade projetada: res.data.resume.payment.projected * res.data.data.circulatingSupply
// round : res.data.resume.product.round
// tipo de pagamento (pagamento no vencimento?) deixar como default
// rentabilidade: res.data.data.profitability toFixed(2)
// nome do produto: res.data.resume.product.name
// tipo de produto:  res.data.resume.product.type
// price variation: res.data.resume.price.current/res.data.resume.price.ico
// market cap variation: : res.data.resume.price.current/res.data.resume.price.ico por enquanto, tem que fazer o calculo ainda

// }
export const responseToNewRef = (ref, dt, tokenData, history) => {
	const { current } = ref;
	current.marketCap =
		dt.data.circulatingSupply *
		(dt.resume.price.current || dt.resume.price.ico);
	current.token = dt.tokenTick;
	current.totalTokens = dt.data.maxSupply;
	current.deadline = dt.data.deadline;
	current.lastPrice = dt.resume.price.current || dt.resume.price.ico;
	current.marketVariation = dt.resume.price.current / dt.resume.price.ico;
	current.priceVariation = dt.resume.price.current / dt.resume.price.ico;
	current.startedAt = new Date(dt.createdAt);
	// current.paymentStartedAt = new Date(dt.createdAt).setMonth(new Date(dt.createdAt).getMonth() + 1)
	// current.paymentOverAt = new Date(dt.burnAt).setMonth(new Date(dt.burnAt).getMonth() - 1)
	current.paymentStartedAt = new Date(
		new Date(dt.createdAt).getFullYear(),
		new Date(dt.createdAt).getMonth() + 1,
		new Date(dt.createdAt).getDate(),
	);
	current.paymentOverAt = new Date(
		new Date(dt.burnAt).getFullYear(),
		new Date(dt.burnAt).getMonth() - 1,
		new Date(dt.burnAt).getDate(),
	);

	// current.paymentStartedAt = new Date(2023, 12, 21)
	current.withdrawAllowedAt = new Date(dt.burnAt);
	current.pago = dt.resume.payment.paid;
	current.expiredDeadline = dt.resume.deadline.remains;
	current.index = dt.index?.name || null;
	// current.index = ''
	current.monthParcel = dt.resume.payment.expected * dt.data.circulatingSupply;
	current.finalRentability =
		dt.resume.payment.projected * dt.data.circulatingSupply;
	current.profitability = dt.data.profitability;
	current.chart = history
		? [
				{ rate: 1, title: "Período de queima", color: PALLETE.orange },
				{ rate: 0.8, title: "Rentabilidade paga", color: tokenData.color },
				{ rate: 0.1, title: "Período de lançamento", color: PALLETE.main },
		  ]
		: dt.chart?.map((chrt) => ({
				...chrt,
				color: chrt.color || tokenData.color,
		  }));

	current.name = dt.resume.product.name;
	// current.name = 'lalala'
	current.round = dt.resume.product.round;
	current.paymentTag = dt.resume.payment.type;
	current.productType = dt.resume.type;
	current.rate =
		(dt.resume.deadline.total - dt.resume.deadline.remains) /
		dt.resume.deadline.total;
	current.color = dt.data.color;
};

export const responseToRef = (ref, dt, tokenData, lancamento, history) => {
	const { current } = ref;
	const monthParcel = history
		? dt.supply.circulating * dt.payment.expected
		: dt.payment.expected * tokenData.amount;

	const reserved = dt.supply.reserved / dt.supply.max || 0;
	const rate = lancamento
		? history
			? 1
			: Math.max(dt.supply.circulating / dt.supply.max, reserved)
		: (dt.deadline?.total - dt.deadline?.remains) / dt.deadline?.total;

	current.amount = tokenData.amount * dt.price.current;
	current.value = dt.price.current;
	current.name = dt.name;
	current.token = tokenData.tick.toUpperCase();
	current.profitability = dt.payment.profitability;
	current.marketCap = history ? dt.marketCap.total : dt.captalization; // AQUI
	current.index = dt.payment.index || null;
	current.rentability = dt.payment.projected * dt.supply.max;
	current.paymentTag = dt.payment.label;
	current.sold = ((dt.supply.circulating / dt.supply.max) * 100).toFixed(2);
	current.availableToken = dt.supply.max - dt.supply.circulating;
	current.begin = dt.createdAt ? new Date(dt.createdAt) : "--";
	current.end = dt.tradeAt ? new Date(dt.tradeAt) : "--";
	current.image = dt.image;
	current.totalTokens = lancamento
		? dt.supply.max
		: history
		? dt.supply.circulating
		: tokenData.amount;
	current.deadline = dt.deadline?.total || "--";
	current.lastPrice = tokenData.value;
	current.startedAt = dt.createdAt ? new Date(dt.createdAt) : "--"; // Emissão
	current.paymentStartedAt = dt.payment.firstAt
		? new Date(dt.payment.firstAt)
		: "--"; // Primeiro pagamento
	current.paymentOverAt = dt.payment.lastAt
		? new Date(dt.payment.lastAt)
		: "--"; // Último pagamento
	current.withdrawAllowedAt = dt.burnAt ? new Date(dt.burnAt) : "--"; // Queima
	current.paid = dt.payment.paid;
	current.pago = dt.payment.userPaid
		? dt.payment.userPaid
		: monthParcel * (dt.deadline.total - dt.deadline.remains);
	current.expiredDeadline = history ? 0 : dt.deadline?.remains || "--";
	current.monthParcel = dt.projectedPayment;
	current.marketVariation = dt.price.current / dt.price.ico || "--";
	current.priceVariation = tokenData.variation;
	current.monthParcel = monthParcel;
	current.finalRentability = dt.deadline?.total
		? monthParcel * dt.deadline.total
		: "--";
	current.rate = rate;
	current.chart = history
		? [
				{ rate: 1, title: "Período de queima", color: PALLETE.orange },
				{ rate: 0.8, title: "Rentabilidade paga", color: tokenData.color },
				{ rate: 0.1, title: "Período de lançamento", color: PALLETE.main },
		  ]
		: dt.chart?.map((chrt) => ({
				...chrt,
				color: chrt.color || tokenData.color,
		  }));

	// responseToRef(data, dt, tokenData, lancamento, history)
	Object.keys(current).forEach((item) => {
		if (item === "index") return;
		if (!current[item]) {
			if (current[item] === 0) return;
			current[item] = "--";
		}
	});
};

export const mock = {
	name: "One Earth Pro",
	round: 0,
	token: "OEP2",
	image: "https://sapiensbank.com.br/assets/agrogreen3.jpeg",
	paymentTag: "Pagamento mensal",
	profitability: 0,
	color: "#03989E",
	marketCap: 0,
	totalTokens: 0,
	deadline: 0,
	lastPrice: 0,
	marketVariation: 0,
	priceVariation: 0,
	startedAt: new Date(),
	paymentOverAt: new Date(),
	paymentStartedAt: new Date(),
	withdrawAllowedAt: new Date(),
	chart: [
		{ rate: 0.85, title: "Período de queima", color: PALLETE.orange },
		{ rate: 0.75, title: "Rentabilidade paga", color: PALLETE.blue },
		{ rate: 0.15, title: "Período de lançamento", color: PALLETE.main },
	],
	paid: 0,
	expiredDeadline: 0,
	monthParcel: 0,
	finalRentability: 0,
};

export const mockLancamentos = {
	name: "One Earth Pro",
	round: "02",
	token: "OEP2",
	image: "https://sapiensbank.com.br/assets/agrogreen3.jpeg",
	paymentTag: "Pagamento no vencimento",
	index: "IPCA",
	profitability: 15,
	color: "#03989E",
	value: 100,
	totalTokens: "200.000",
	deadline: 36,
	rentability: 9000,
	sold: 27.8,
	availableToken: "144.400",
	startedAt: new Date(),
	paymentOverAt: new Date(),
	paymentStartedAt: new Date(),
	withdrawAllowedAt: new Date(),
	begin: new Date(),
	end: new Date(),
	rate: 0.4,
	chart: [
		{ rate: 1, title: "Restante", color: "#EEEEEE" },
		{ rate: 0.4, title: "Comprado", color: PALLETE.blue },
		{ rate: 0.15, title: "Reservado", color: PALLETE.main },
	],
};

export const mockLib = [
	["White paper", "white-paper", true],
	["Composição", "composicao", true],
	["Ficha técnica", "ficha-tecnica", true],
	["Contrato", "contrato"],
	["Regulamentos", "regulamentos"],
	["Tributação", "tributacao"],
];
