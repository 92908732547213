import styled from 'styled-components';
import utils from '../../helper/utils';


export const ButtonSelectorChart = styled.button`
  background: ${(props) => {
    if (props.color) return props.color;
    return 'tranparent';
  }};
  border-radius: 5px;
  border: none;
  box-shadow: ${(props) => {
    if (props.isSelected) return '0px 4px 10px rgba(4, 197, 0, 0.3)';
    return 'none';
  }};
  box-sizing: border-box;
  color: ${(props) => (props.color ? 'white' : '#757575')};
  height: 35px;
  margin-right: 2px;
  padding: 8px 16px;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  

  @media (max-width: 450px) {
    font-size: ${utils.responsive.ajustFont(8)}px;
    line-height: ${utils.responsive.ajustFont(10)}px;
  }
`;