import styled from "styled-components";
import { PALLETE } from "../../configs/configs";

export const GeneralContainer = styled.div`
	box-sizing: border-box;
	height: 100%;
	width: ${(props) => (props.extended ? "100%" : "0")};
	position: fixed;
	top: 0;
	display: flex;
	z-index: 15;
`;
export const Background = styled.div`
	box-sizing: border-box;
	height: 100%;
	width: ${(props) => (props.extended ? "100%" : "0")};
	position: fixed;
	z-index: 16;
	background: transparent;
	animation: ${(props) => (props.extended ? "fadeBackground 0.3s" : "none")};
	animation-fill-mode: forwards;
	@keyframes fadeBackground {
		from {
			background-color: transparent;
		}
		to {
			background-color: #000000;
			opacity: 50%;
		}
	}
`;

export const FormContainer = styled.div`
	box-sizing: border-box;
	transition: width 0.3s;
	width: ${(props) => (props.extended ? "510px" : "0")};
	height: 100%;
	position: fixed;
	background-color: #ffffff;
	right: 0;
	z-index: 16;
	overflow-x: hidden;
	overflow-y: scroll;
	/* display: flex;
	align-items: center; */
`;
