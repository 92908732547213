import styled from "styled-components";
import slider from "./slider.svg";
import slider3 from './slider3.svg';
import { BREAKPOINTS } from "../../configs/configs";

export const Container = styled.div`
	@import url("https://fonts.googleapis.com/css2?family=Inter&family=Roboto:wght@300;400;500;700&display=swap");
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;

	.sub-container {
		width: 100%;
		max-width: 670px;
		/* padding: 0 160px; */
		box-sizing: border-box;
	}

	.carousel-title {
		font-weight: 300;
		font-size: 14px;
		margin-bottom: 15px;
		margin-top: 0;

	@media (max-width: 450px) {
		font-size: 12px;
		margin-bottom: 0px;
	}
	}
`;

export const Divisor = styled.hr`
	margin: 30px 0;
	border: 1px solid #f5f5f5;
`;

export const Table = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	/* padding: 0 40px; */
	box-sizing: border-box;

	/* @media (max-width: 450px) {
		padding: 0;
	} */
`;

export const CelTitle = styled.p`
	font-weight: 500 !important;
	font-size: 14px !important;
	color: #616161 !important;
	margin: 0 0 12px 0 !important;
`;

export const CelValue = styled.p`
	font-weight: 700 !important;
	font-size: 16px !important;
	margin: 0 !important;
`;

export const Cel = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;

	small,
	p,
	b,
	em,
	mark {
		font-style: normal;
	}

	b,
	em,
	mark {
		height: 40px;
		margin: 0 0 20px 0;
		display: flex;
		align-items: center;
		@media (max-width: 450px) {
			margin: 5px 0;
			height: 24px;
		}
	}

	small {
		font-weight: 500;
		font-size: 8px;
		color: #616161;
		line-height: 7px;
	}

	p {
		font-weight: 700;
		font-size: 10px;
		display: flex;
		align-items: center;
		text-align: center;
		color: #070707;
		/* height: 24px; */
		margin: 5px 0 0 0;
	}

	b {
		font-size: 20px;
		font-weight: 700;
		line-height: 20px;
		color: #070707;
		@media (max-width: 450px) {
			font-size: 12px;
		}
	}

	em {
		font-weight: 400;
		font-size: 20px;
		line-height: 12px;
		color: #757575;
		@media (max-width: 450px) {
			font-size: 12px;
		}
	}

	mark {
		width: 170px;
		height: 40px;
		background-color: #f5f5f5;
		color: #757575;
		border-radius: 5px;
		font-weight: 400;
		font-size: 20px;
		line-height: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 0 20px 0;
		@media (max-width: 450px) {
			font-size: 12px;
			width: 100px;
			margin: 3px 0;
			height: 24px;
		}
	}

	mark.marked {
		background-color: #06e45f4d;
		color: #070707;
	}
`;

export const SliderContainer = styled.div`
	width: 100%;
	box-sizing: border-box;
	padding: 0;

	@media (min-width: ${BREAKPOINTS.DEFAULT}px) {
		padding: 0 40px;
		/* max-width: 592px; */
	}

	strong {
		font-style: normal;
		font-weight: 400;
		font-size: 50px;
		line-height: 48px;
		text-align: center;
		color: #070707;

		small {
			font-size: 12px;
		}
	}

	input[type="range"] {
		-webkit-appearance: none; /* Override default CSS styles */
		-webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
		appearance: none;
		/* background: #d3d3d3; /* Grey background */
		background: ${(props) =>
			`linear-gradient(to right, #06e45f, #06e45f ${props.rate}%, #d3d3d3 ${props.rate}%, #d3d3d3 100%)`};
		border-radius: 4px;
		height: 2px; /* Specified height */
		outline: none; /* Remove outline */
		transition: opacity 0.2s;
		width: 100%; /* Full-width */
		padding: 0;

		@media (min-width: ${BREAKPOINTS.DEFAULT}px) {
			margin-top: 46px;
		}
	}

	input::-webkit-slider-thumb {
		-webkit-appearance: none;
		appearance: none;
		background: url(${slider3});
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
		cursor: pointer;
		height: 24px;
		width: 24px;
		background-color: transparent;
		border-radius: 15px;
		padding: 0;
		margin: 0;
		/* border: 1px solid red;  */
	}
`;

// export const TokenWrapper = styled.div`
//   display: block;
//   width: 118px;
//   object-fit: cover;
// `

export const TokenButton = styled.div`
	margin-right: 20px;
	margin-top: 5px;
	width: 125px !important;
	display: flex !important;
	flex-direction: row;
	box-sizing: border-box;
	padding: 6px;
	align-items: center;
	border-radius: 5px;
	border: ${(props) => {
		if (props.selected) return "1px solid #F5F5F5";
		return "none";
	}};
	box-shadow: ${(props) => {
		if (props.selected) return "0px 2px 10px rgba(0, 0, 0, 0.25)";
		return "none";
	}};

	@media (max-width: 450px) {
		width: 95px !important;
		padding: 6px 8px;
		margin-top: 35px;
	}

	.touch-area {
		width: 90px;
		height: 90px;
		position: absolute;
		z-index: 11;
	}

	img {
		height: 32px;
		margin-right: 16px;
		@media (max-width: 450px) {
			height: 24px;
			margin-right: 9px;
		}
	}

	p {
		font-weight: 400;
		font-size: 20px;
		color: #070707;
		margin: 0;
		@media (max-width: 450px) {
			font-size: 14px;
		}
	}
	&:hover {
		background-color: #eeeeee;
		@media (max-width: 450px) {
			background-color: transparent;
		}
	}
`;

export const TokenNameTag = styled.p`
	padding: 6px 16px;
	background-color: ${(props) => `${props.color}60` || "#0f0f0f30"};
	border-radius: 5px;
	font-weight: 400;
	font-size: 20px;
	color: #070707;
	/* margin-top: 80px; */
	margin-bottom: 45px;
	margin-top: 0;
	@media (max-width: 450px) {
		font-size: 14px;
		padding: 12px;
		margin-bottom: 0;
	}
`;

// export const InfiniteCarousel = styled.div`
//   max-width: 700;
//   margin: 0 auto;
//   overflow: hidden;

//   .carousel-items {
//     display: flex;
//     animation: carousel 4s linear infinite;
//   }

//   @keyframes carousel {
//     100%
//     {
//       transform: translateX(-1400px);
//     }
//   }

// `
