import React from "react";
import configs from "../../../configs/configs";
import { Card, InfoTags } from "./style";
import icons from "../../../assets/icons";

export default function TokenCard({
	color,
	icon,
	profitability,
	expired,
	onClick = () => {},
	down = false,
	index,
	min,
	name,
	rate,
	recurrence,
	deadline,
	createdAt,
	fgc,
	category,
}) {
	return (
		<Card color={color} isGray={expired} onClick={onClick} down={down}>
			<div className="row">
				<img
					align="left"
					className="card-icon"
					alt="icon in card"
					src={`${configs.IMAGES.APP_URL}/${icon}.svg`}
				/>
				<p className="card-title">{name}</p>
			</div>

			<div >
				<div className="row" style={{ marginBottom: 5 }}>
					<InfoTags color={color} isGray={expired} complete={expired} noMargin>
						<span className="bold" style={{ marginRight: 3 }}>{`${
							expired ? "100" : rate.toFixed()
						}%`}</span>
						{`captados`}
					</InfoTags>
					{(fgc || category) && (
					<InfoTags color={color} isGray={expired}>
						<span className="bold">{category ? category : ""}</span>
						{fgc && (
							<img
								src={icons.smallCheck}
								style={{ marginLeft: 5, marginRight: 5 }}
								alt="small check"
							/>
						)}
						<p style={{ margin: 0, color: "black", fontWeight: "700" }}>
							{fgc ? "FGC" : ""}
						</p>
					</InfoTags>
					)}
				</div>

				<div
					className="row"
				>
					<InfoTags color={color} isGray={expired} noMargin>
						<span className="bold">{new Date(createdAt).getFullYear()}</span>
					</InfoTags>
					<InfoTags color={color} isGray={expired}>
						<span className="bold">
							{recurrence === "m" ? "Juros mensais" : "Liquidez diária"}
						</span>
					</InfoTags>
					<InfoTags color={color} isGray={expired}>
						<span className="bold" style={{ marginRight: 3 }}>
							{deadline}
						</span>
						meses
					</InfoTags>
				</div>
			</div>

			<div>
				<div
					className="row plusMargin"
					style={{ marginBottom: window.innerWidth > 450 ? 10 : 8 }}
				>
					<p className="tiny-text" style={{ marginRight: 10 }}>
						Invest. Mín.:
					</p>
					<p className="money-text">
						{`R$ ${min}`}
					</p>
				</div>

				<div className="row">
					<>
						<p className="tiny-text" style={{ marginRight: 5 }}>
							Rentabilidade:
						</p>
						<p className="money-text">{`${index ? `${index.name} + ` : ''}`} {profitability}</p>
					</>
				</div>
			</div>
		</Card>
	);
}
