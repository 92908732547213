import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import Context from '../../../context'
// import * as Style from './style'
import { Input, Button } from '../../../components'
import RegisterTemplate from '../RegisterTemplate'
import * as Template from '../style'

const Name = () => {
  const context = useContext(Context)
  const navigate = useNavigate()

  const onSubmit = () => {
    if (!context.data.name) return;
    navigate('/register/email')
  }

  return (
    <RegisterTemplate backTo="/register">
      <Template.PageTitle>Qual seu primeiro nome?</Template.PageTitle>
      <Template.Margin />
      <Input onChange={(value) => { context.data.name = value }} autofocus/>
      <Template.Margin />
      <Button
        label="Continuar"
        rounded
        style={{ fontSize: 14, fontWeight: 700, width: '100%', boxSizing: 'border-box' }}
        onClick={onSubmit}
      />
    </RegisterTemplate>
  )
};

export default Name
