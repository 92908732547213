import styled from "styled-components";

const animationSpeed = "30s";

export const Slider = styled.div`
	// Styling
	height: 50px;
	margin: auto;
	overflow:hidden;
	position: relative;
	width: 670px;
	
	&::before,
	&::after {
		@include white-gradient;
		content: "";
		height: 50px;
		position: absolute;
		width: 200px;
		z-index: 2;
	}
	
	&::after {
		right: 0;
		top: 0;
		transform: rotateZ(180deg);
	}

	&::before {
		left: 0;
		top: 0;
	}
	
	.slide-track {
		animation: scroll ${animationSpeed} linear infinite;
		display: flex;
		width: calc(118px * 14);
        /* &:hover {
            animation-play-state: paused;
        } */
	}

    	// Animation
	@keyframes scroll {
		0% {
			transform: translateX(0);
		}
		100% { transform: translateX(calc(-118px * 7))}
	}

`;
