import React, { useContext } from "react";
import { BigContainer, Text, Subtext } from "./style";
import { Button } from "../../../components";
import Context from "../../../context";
import { PALLETE } from "../../../configs/configs";
import justWait from "../../../assets/just-wait.svg";
import { useNavigate } from "react-router-dom";

import { Footer } from "../../../components";

export default function JustWait() {
	const context = useContext(Context);
	const navigate = useNavigate();
	return (
		<>
			<BigContainer>
				{/* TODO - Ajustar o tamanho da imagem #5591 */}
				<img src={justWait} alt="just wait img" />
				<Text>
					{context.data.name}, tudo certo!
					<br />
					agora é só baixar o app.
				</Text>
				<Subtext>
					Vamos fazer uma analise, quando <br />
					estiver pronta, a gente te avisa.
				</Subtext>
				<Button
					label="Ok, maravilha"
					style={{ backgroundColor: PALLETE.black, color: PALLETE.white }}
					onClick={() => {
						window.location = "http://localhost:3000";
					}}
				/>
			</BigContainer>
			<Footer />
		</>
	);
}
