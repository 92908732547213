module.exports = {
  CONNECTION: {
    // -- PRODUÇÃO --
    URL: ' https://api.sapiensbank.com.br/',
    // --------------
    // -- HOMOLOGAÇÃO --
    // URL: 'http://localhost:8085/',
    // --------------
    // -- SANDBOX --
    // URL: 'https://api.sapiensbank.com.br:9085/',
    // -------------

    ISSUER: 'sapiens-webserver',
    AUDIENCE: 'sapiens-website',
  },
  IMAGES: {
    URL: 'https://sapiensbank.com.br/assets/new-icons',
    APP_URL: 'https://sapiensbank.com.br/assets/icons/app',
    // APP_URL: 'https://sapiensbank.com.br/assets/test/icons/app',
  },
  STORAGE: {
    //   NAME: 'sapiens-aluguel-verde',
  },
  PALLETE: {
    disabled: '#CCCCCC',
    base: '#3E788A',
    white: '#F9F9F9',
    grey: '#757575',
    pureWhite: '#FFFFFF',
    black: '#070707',
    bluieshWhite: '#e2eff2',
    whitestGrey: '#EBEBEB',
    lightBlue: '#9AC1BC',
    //olds
    red: "#D61212",
    purple: "#a25ddc",
    blue: "#2A76E4",
    pink: "#F60665"
  },

  BREAKPOINTS: {
    DEFAULT: 450,
  },

  DEVICE: {
    USE: "IPHONE13_MINI",
    DEFAULT: {
      WIDTH: 375,
      HEIGHT: 812
    },
    IPHONE8: {
      WIDTH: 375,
      HEIGHT: 667
    },
    IPHONE11: {
      WIDTH: 414,
      HEIGHT: 896
    },
    IPHONE13_MINI: {
      WIDTH: 375,
      HEIGHT: 812
    }
  },
  MODAL: {
    SMALL: 350,
    BIG: 600,
    BIGGER: 730,
    MARGIN_TOP: 50
  }
}
