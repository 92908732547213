import styled from 'styled-components'
import { PALLETE } from '../../../configs/configs';

export const Button = styled.a`
  @import url('https://fonts.googleapis.com/css2?family=Inter&family=Roboto:wght@300;500;700&display=swap');
  background: ${(props) =>{
    if (props.black && !props.disabled) return PALLETE.black;
    if (props.arrow) return PALLETE.white;
    if (props.disabled) return PALLETE.disabled;
    return PALLETE.base; 
  }};
  border-radius: ${(props) => props.rounded ? 21 : 5 }px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.arrow ? 'space-between' : 'center' };
  padding: 0 20px;
  color: ${(props) =>{ 
    if (props.arrow) return PALLETE.grey;
    if (props.disabled) return PALLETE.black;
    return PALLETE.white;
  }};
  filter: ${(props) => {
    if (props.border) return `drop-shadow(1px 1px 1px ${PALLETE.main})`
    return 'none'
  }};
  margin-bottom: 7px;
  box-shadow: ${(props) => {
    if (props.header) return `1px 1px 1px 0px ${PALLETE.lightBlue}`
  }};

  ${(props) => props.arrow && 'box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);'}
  
  .arrow {
    transform: rotate(270deg);
  }

  &:hover {
    cursor: pointer;
  }
`