import { useState } from 'react'
import banner from '../../assets/banner.png';
import { useNavigate } from 'react-router-dom'
import { mobile as Style } from './style';
import utils from '../../helper/utils'
import { Solid } from '../../assets/newIcons';
import logoOutlinedBlack from './logoOutlinedBlack.svg';
import logoOutlinedWhite from './logoOutlinedWhite.svg';
const { responsive } = utils

export default function Header({ scroll, backgroundColor, data }) {
  const navigate = useNavigate()

  return (
    <Style.Header fixed={true} scroll={true && scroll} backgroundColor={backgroundColor}>
      {backgroundColor !== '#fdfdfd' ? (
        <div style={{ display: 'flex', alignItems: 'center'}}>
          <img src={Solid(data.iconLeafInverted)} style={{ marginRight: 10}}/>
          <img src={logoOutlinedWhite} />
        </div>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center'}}>
        <img src={Solid(data.iconLeaf)} style={{ marginRight: 10}} height={26} />
        <img src={logoOutlinedBlack} height={26}/>
      </div>
      )}
      {/* <Style.Banner src={require('../../assets/banner.png')} alt="Banner" /> */}
      <Style.Button onClick={() => { navigate('/register') }} backgroundColor={backgroundColor} color1={data.color1} color2={data.color2}>
        Criar conta
      </Style.Button>
    </Style.Header>
  );
}
