import { useState } from 'react';

import * as Style from './style';
import { ArrowButton } from '../../../components';
import utils from '../../../helper/utils';
import { DoughnutMenu } from '../../../components'

// Icons
import composition from '../../../assets/menu/composition.png';
import database from '../../../assets/menu/database.png';
import handShake from '../../../assets/menu/hand-shake.png';
import legalTerm from '../../../assets/menu/legal-term.png';
import moneyFlow from '../../../assets/menu/money-flow.png';
import moneyShield from '../../../assets/menu/money-shield.png';
import trendUp from '../../../assets/menu/trending-up.png';

const lorenIpsum = 'A decisão de emissão da nossa CCB de renda fixa passa por um processo de análise profunda. Ao verificarmos que o ativo possui indicativos de uma boa relação de risco-retorno, damos inicio ao processo de emissão do título e homologação junto ao Banco Central e todos os seus aspectos: jurídico, estatístico, riscos e monitoramento.';

const menuData = [
  { icon: trendUp, title: `Cenários\nprojetados`, content: lorenIpsum },
  { icon: moneyFlow, title: 'Fluxo da\noperação', content: lorenIpsum },
  { icon: database, title: 'Fluxo de\nrecebimento', content: lorenIpsum },
  { icon: composition, title: 'Estratégias\nde alocação', content: lorenIpsum },
  { icon: moneyShield, title: 'Garantia e\nmitigação', content: lorenIpsum },
  { icon: handShake, title: 'Liquidação', content: lorenIpsum },
  { icon: legalTerm, title: 'Tributação', content: lorenIpsum },
]

const Visao = () => {
  const [content, setContent] = useState();

  return (
    <Style.WhiteCover color="#F5F5F5">
      <div className="inline">
        <Style.NinethContet>
          {utils.responsive.choose(
            <h3 className='nineth-title'>
              Visão integral da<br />nossa renda fixa
            </h3>)()
          }

          <DoughnutMenu
            data={menuData}
            onSelect={(i) => setContent(menuData[i].content)}
            zoom={utils.responsive.isMobile ? 0.8 : 1}
            size={Math.min(window.innerWidth, 380)}
          />

          <div className='nine-text-content-column'>
            {
              utils.responsive.choose()(
                <h3 className='nineth-title'>
                  Visão integral da<br />nossa renda fixa
                </h3>
              )
            }
            <p className="nineth-text-content">{content}</p>
            <ArrowButton
              label="Fale com um especialista"
              style={{ alignSelf: window.innerWidth > 450 ? 'flex-end' : 'center', marginTop: 32 }}
            />
          </div>
        </Style.NinethContet>
      </div>
    </Style.WhiteCover>
  )
}

export default Visao;
