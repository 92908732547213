import styled from 'styled-components';

export const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  /* height: ${window.innerWidth * 0.425}; */
  height: ${(props) => (`${props.height}px`)};
  box-sizing: border-box;
  overflow: hidden;

  .background-image {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    object-fit: cover;
    @media (max-width: 450px) {
      width: auto;
      margin-left: -30px;
    }
  }

  .gradient {
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    top:0;
  }

  .inline-cover {
    display: inline-block;
    height: 100%;
    @media (max-width: 450px) {
			width: 100%;
		}
  }

  .content-container {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    top: 0;
    z-index: 2;
  }
`;
