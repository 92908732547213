import React, { useState, useRef, useEffect } from "react";
import utils from '../../helper/utils'
import { Wrapper, InfoNot, SliderContainer, MobileInfoWrapper } from "./style";
import { Solid } from "../../assets/newIcons";
const { dataTypes, responsive } = utils
const steps = [
	100, 1000, 5000, 10000, 15000, 20000, 30000, 40000, 50000, 60000, 70000,
	80000, 90000, 100000, 200000, 400000, 500000, 1000000,
];

const formatter = (noDigits) =>
  new Intl.NumberFormat("pr-BR", {
    style: "currency",
    currency: "BRL",
    maximumFractionDigits: noDigits ? 0 : 2,
  });

const DesktopInfos = ({ data, calculator }) => {
  return (
    <>
    <div className="desktop-grid" style={{ marginBottom:  window.innerWidth < 450 ? 0 : 50}}>
    <InfoNot>
					<p className="value">{formatter().format(calculator.rentability)}</p>
					<p className="legend">Rentabilidade</p>
				</InfoNot>
				<InfoNot>
					<p className="value">{`${data.IR}%`}</p>
					<p className="legend">IR</p>
				</InfoNot>
				<InfoNot>
					<p className="value">{formatter().format(calculator.rentabilityPure)}</p>
					<p className="legend">Rent. Líquida</p>
				</InfoNot>
				<InfoNot color={data.color1}>
					<p className="value">{dataTypes.number.withSeparator(calculator.cotas)}</p>
					<p className="legend">Cotas</p>
				</InfoNot>
    </div>
    </>
  )
}

const MobileInfos = ({ data, calculator }) => {
  return (
    <MobileInfoWrapper>
      <div>
      <InfoNot>
					<p className="value">{formatter().format(calculator.rentability)}</p>
					<p className="legend">Rentabilidade</p>
				</InfoNot>
				<InfoNot>
					<p className="value">{`${data.IR}%`}</p>
					<p className="legend">IR</p>
				</InfoNot>
      </div>
      <div>
      <InfoNot>
					<p className="value">{formatter().format(calculator.rentabilityPure)}</p>
					<p className="legend">Rent. Líquida</p>
				</InfoNot>
				<InfoNot color={data.color1}>
					<p className="value">{dataTypes.number.withSeparator(calculator.cotas)}</p>
					<p className="legend">Cotas</p>
				</InfoNot>
      </div>
    </MobileInfoWrapper>
  )
}

const InvestCalculator = ({ data }) => {
	const [amount, setAmount] = useState(100);
	const [calculator, setCalculator] = useState({})

	useEffect(() => {
		const ren = amount * (data.profitability / 100)
		setCalculator({
			rentability: ren,
			rentabilityPure: ren - ren * (data.IR / 100),
			cotas: amount * Number(data.price),
		})
	}, [amount])

	const inputRef = useRef();


	const toParts = (amount) => {
		const c = formatter(true).formatToParts(amount);
		const mult = ["reais", "mil", "milhão", "bilhão"][
			c.filter((cur) => cur.type === "group").length
		];
		const [score] = c.filter((cur) => cur.type === "integer");
		return (
			<strong>
				{score.value} <small>{mult}</small>
			</strong>
		);
	};

	return (
		<Wrapper>
			<h3 className="title">Simular investimento</h3>
			<div className="row" style={{ marginBottom: window.innerWidth < 450 ? 20 : 60 }}>
				<img src={data.icon} style={{ height: window.innerWidth < 450 ? 24 : 48 }} alt="Icon cap" />
				<p className="invest-name">
					{data.name} - {new Date(data.createdAt).getFullYear()}
				</p>
			</div>
			<SliderContainer
      slider={data.handleSlider}
      color={data.color1}
				rate={
					inputRef.current?.value
						? (inputRef.current?.value / Math.max(steps.length - 1, 1)) * 99
						: 0
				}
			>
				<strong>{toParts(amount)}</strong>
				<input
					ref={inputRef}
					type="range"
					min={0}
					max={Math.max(steps.length - 1, 0)}
					step={1}
          defaultValue={0}
					onChange={(ev) => {
						setAmount(steps[ev.target.value]);
					}}
				/>
			</SliderContainer>
          {responsive.choose(<MobileInfos data={data} calculator={calculator}/>)(<DesktopInfos data={data} calculator={calculator}/>)}
     
		</Wrapper>
	);
};

export default InvestCalculator;
