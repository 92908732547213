import React, { useState } from "react";
import RegisterTemplate from "../RegisterTemplate";
import { Input, Button, Modal } from "../../../components";
import { useContext } from "react";
import Context from "../../../context";
import { PageTitle, Margin, Error, ModalContainer } from "../style";
import { Small, Row } from "./style";
import validator from "../../../helper/validator";
import { useNavigate } from "react-router-dom";
import icons from "../../../assets/icons";

export default function CPF() {
	const context = useContext(Context);
	const [showModal, setShowModal] = useState(false);
	const [error, setError] = useState(false);

	const navigate = useNavigate();
	return (
		<>
			<RegisterTemplate backTo={"/register/name"}>
				<PageTitle>Digite seu email</PageTitle>
				<Margin />
				<Input
					onChange={(value) => {
						context.data.email = value;
					}}
					autofocus
				/>
				<Margin />
				<Button
					label="Continuar"
					rounded
					style={{
						fontSize: 14,
						fontWeight: 700,
						width: "100%",
						boxSizing: "border-box",
					}}
					onClick={() => {
						validator.email(context.data.email)
							? setShowModal(true)
							: setError(true);
					}}
				/>
				{error && <Error>Digite um email válido</Error>}
			</RegisterTemplate>
				<div
					style={{
						position: "fixed",
						height: 20,
						width: "100%",
						bottom: 0,
					}}
				>
			<Modal extended={showModal} onClickOut={setShowModal} size="big">
					<ModalContainer>
						<PageTitle big>
							{context.data.name},<br />
							seu email esta correto?
						</PageTitle>
						<Row>
							<img
								src={icons.mailOutlinedGrey}
								alt="mail icon"
								style={{ marginRight: 15 }}
							/>
							<Small>{context.data.email}</Small>
						</Row>
						<Button
							label="CONFIRMAR"
							rounded
							style={{
								color: "black",
								fontSize: 14,
								fontWeight: 700,
								width: "100%",
								boxSizing: "border-box",
							}}
							onClick={() => navigate("/register/phone")}
						/>
					</ModalContainer>
			</Modal>
				</div>
		</>
	);
}
