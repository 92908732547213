import styled from "styled-components";
import { PALLETE } from "../../../configs/configs";

export const Small = styled.p`
  font-weight: 700;
  font-size: 14px;
  color:${PALLETE.black};
  margin: 0;
`
export const Row = styled.div`
  display: flex;
  width: 100%;
  margin: 60px 0;
  align-items: center;
`